import {
    useTheme,
    Grid,
    Typography,
    styled,
    Badge,
    Checkbox,
    FormControlLabel,
    TextField,
    MenuItem,
    Button,
    Stack,
    Link,
    Chip,
    Card,
    Tab,
    Tabs,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AddMonitoringTab from '../addmonitoring/AddMonitoringTab';
import AddReferenceMaterialModalContent from '../../../plans/goals/referencematerial/AddReferenceMaterialModalContent';
import ReferenceMaterial from '../../../plans/goals/referencematerial/ReferenceMaterial';
import AddReferenceMaterial from '../../../plans/goals/referencematerial/AddReferenceMaterial';
import AddNewActivityContents from '../../common/addnewactivity/AddNewActivity';
import Activitypage from './Activitypage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomCareProgram = ({ handleActivityClick, setGoals }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [date, setDate] = useState(null);
    const [cdate, setCDate] = useState(null);
    const [addMonitoring, setAddMonitoring] = useState(false);
    const [referenceMaterial, setReferenceMaterial] = useState(false);
    const [addReferenceMaterial, setAddReferenceMaterial] = useState(false);
    const [Permission, setPermission] = useState([]);
    const [dietopen, setDietOpen] = useState(false);
    const [nexerciseopen, setNExerciseOpen] = useState(false);
    const [restopen, setRestOpen] = useState(false);
    const [attitudeopen, setAttitudeOpen] = useState(false);
    const [medicationopen, setMedicationOpen] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const collapseAll = () => {
        setAddMonitoring(false);
        setReferenceMaterial(false);
        setAddReferenceMaterial(false);
    };
    const [unreadCounts, setUnreadCounts] = useState('1');
    const cardStyle = {
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important'
    };
    useEffect(() => {
        console.log(Permission);
    }, [Permission]);

    const DreamsPlanners = () => {
        setGoals(Permission);
        handleActivityClick();
    };

    const Onchange = (event) => {
        let Permissionarray = [...Permission];
        if (event.target.checked) {
            Permissionarray = [...Permission, event.target.value];
            setPermission(Permissionarray);
        } else {
            Permissionarray.splice(Permission.indexOf(event.target.value), 1);
            setPermission(Permissionarray);
        }
    };
    const [expandedAccordion, setExpandedAccordion] = useState(null);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        // Check if any other accordion is currently expanded
        const isOtherAccordionExpanded = expandedAccordion !== panel && expandedAccordion !== null;
        if (!isOtherAccordionExpanded || isExpanded) {
            // If no other accordion is expanded or the selected accordion is being collapsed,
            // allow the selected accordion to expand
            setExpandedAccordion(isExpanded ? panel : null);
        }
    };
    const SuccessBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.success.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.success.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));
    return (
        <>
            <Grid container spacing={gridSpacing} alignItems="center">
                <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important', pt: '8px !important' }}>
                    <Grid container alignItems="center" spacing={1} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox defaultChecked name="maintainweight" value="Maintain Healthy Weight" onChange={Onchange} />
                                }
                                label="Maintain Healthy Weight"
                            />
                        </Grid>
                        <Grid item container xs={12} lg={12} md={12} spacing={2} alignItems="center">
                            <Grid item xs={4} lg={4} md={4}>
                                <Typography variant="subTitle1">
                                    <b>Current Weight: </b> 190 lbs
                                </Typography>
                            </Grid>

                            {/* <Grid item xs={12} lg={12} md={12}>
                            <Grid container alignItems="center" spacing={gridSpacing} sx={{ pb: 2 }}> */}
                            {/* <Grid item xs={4} lg={4} md={4}>
                                    Schedule
                                </Grid> */}
                            <Grid item xs={4} lg={4} md={4}>
                                <TextField id="outlined-select-experience1" fullWidth label="Set Weight Goal" size="small" />
                            </Grid>
                            <Grid item xs={4} lg={4} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="Acheive By"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {/* <Grid item xs={4} lg={4} md={4}>
                                <AddNewActivityContents />
                            </Grid> */}
                            <Grid container spacing={gridSpacing} alignItems="center">
                                <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important', pt: '8px !important' }}>
                                    <Grid container alignItems="center" spacing={1} sx={cardStyle}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={value} onChange={handleChange} aria-label="Add Activities">
                                                    <Tab label="Add Activities" />
                                                    <Tab label=" Add Monitoring" />
                                                    <Tab label="Referece Materials" />
                                                </Tabs>
                                            </Box>
                                            {value === 0 && (
                                                <div>
                                                    <Grid item xs={12} lg={12} md={12}>
                                                        <Typography variant="subTitle1">
                                                            <b>Add Activities:</b>
                                                        </Typography>
                                                        &nbsp;
                                                        <Checkbox
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                collapseAll();
                                                                setDietOpen(!dietopen);
                                                            }}
                                                        />
                                                        <Link variant="button" underline="none" sx={{ cursor: 'pointer' }}>
                                                            {/* <CustomizedBadge badgeContent={unreadCounts} color="error">
                                                            Diet
                                                        </CustomizedBadge> */}
                                                            Diet
                                                            {/* &nbsp;&nbsp;
                                <Chip color="error" size="small" label={unreadCounts} /> */}
                                                        </Link>
                                                        {/* <CustomizedBadge badgeContent={unreadCounts} color="error" /> */}
                                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                                        <Checkbox
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                collapseAll();
                                                                setRestOpen(!restopen);
                                                            }}
                                                        />
                                                        <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                            Rest
                                                        </Link>
                                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                                        <Checkbox
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                collapseAll();
                                                                setNExerciseOpen(!nexerciseopen);
                                                            }}
                                                        />
                                                        <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                            Exercise
                                                        </Link>
                                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                                        <Checkbox
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                collapseAll();
                                                                setAttitudeOpen(!attitudeopen);
                                                            }}
                                                        />
                                                        <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                            Attitude
                                                        </Link>
                                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                                        <Checkbox
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                collapseAll();
                                                                setMedicationOpen(!medicationopen);
                                                            }}
                                                        />
                                                        <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                            Medication
                                                        </Link>
                                                    </Grid>
                                                </div>
                                            )}
                                            {value === 1 && (
                                                <div>
                                                    <Grid item container xs={12} lg={12} md={12}>
                                                        <Grid item xs={8} lg={8} md={8}>
                                                            <Stack direction="row" spacing={1} alignItems="center">
                                                                <AnimateButton>
                                                                    <Button
                                                                        onClick={() => {
                                                                            collapseAll();
                                                                            setAddMonitoring(!addMonitoring);
                                                                        }}
                                                                        variant="text"
                                                                    >
                                                                        Add Monitoring
                                                                    </Button>
                                                                </AnimateButton>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            {value === 2 && (
                                                <div>
                                                    <Grid item container xs={12} lg={12} md={12}>
                                                        <Grid item xs={8} lg={8} md={8}>
                                                            <Stack direction="row" spacing={1} alignItems="center">
                                                                <AnimateButton>
                                                                    <Button
                                                                        onClick={() => {
                                                                            collapseAll();
                                                                            setReferenceMaterial(!referenceMaterial);
                                                                        }}
                                                                        variant="text"
                                                                    >
                                                                        Reference Materials&nbsp;&nbsp;
                                                                        <Chip color="error" size="small" label={2} />
                                                                    </Button>
                                                                </AnimateButton>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        </Grid>
                                        {dietopen && (
                                            <Grid item xs={12} lg={12} md={12}>
                                                {/* <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                    <Grid item container xs={12} lg={12} md={12}>
                                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                                            <Typography variant="h5">Diet & Nutrition</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={12} md={12}>
                                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="mealplanner" />}
                                                                        label="Meal Planner"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="waterplanner" />}
                                                                        label="Water Planner"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="monitorweight" />}
                                                                        label="Monitor Weight"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="barriers" />}
                                                                        label="Identify and Avoid Barriers"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Card> */}
                                                <Accordion
                                                    expanded={expandedAccordion === 'panel1'}
                                                    onChange={handleAccordionChange('panel1')}
                                                    sx={{ background: '#f5f5f5' }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel-content"
                                                        id="panel-header"
                                                    >
                                                        <Typography>Diet & Nutrition</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid item container xs={12} lg={12} md={12}>
                                                            <Grid item xs={12} lg={12} md={12}>
                                                                <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="mealplanner" />}
                                                                            label="Meal Planner"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="waterplanner" />}
                                                                            label="Water Planner"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="monitorweight" />}
                                                                            label="Monitor Weight"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="barriers" />}
                                                                            label="Identify and Avoid Barriers"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="custom" />}
                                                                            label="Custom"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                        {restopen && (
                                            <Grid item xs={12} lg={12} md={12}>
                                                {/* <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                    <Grid item container xs={12} lg={12} md={12}>
                                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                                            <Typography variant="h5">Rest & Sleep</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={12} md={12}>
                                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="mealplanner" />}
                                                                        label="Identify and avoid hindrance"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="waterplanner" />}
                                                                        label="Set activities to prepare for effective sleep"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="monitorweight" />}
                                                                        label="Monitor sleep rest"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Card> */}
                                                <Accordion
                                                    expanded={expandedAccordion === 'panel2'}
                                                    onChange={handleAccordionChange('panel2')}
                                                    sx={{ background: '#f5f5f5' }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel-content"
                                                        id="panel-header"
                                                    >
                                                        <Typography>Rest & Sleep</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                            <Grid item xs={12} lg={12} md={12}>
                                                                <FormControlLabel
                                                                    control={<Checkbox name="mealplanner" />}
                                                                    label="Identify and avoid hindrance"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12}>
                                                                <FormControlLabel
                                                                    control={<Checkbox name="waterplanner" />}
                                                                    label="Set activities to prepare for effective sleep"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12}>
                                                                <FormControlLabel
                                                                    control={<Checkbox name="monitorweight" />}
                                                                    label="Monitor sleep rest"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12}>
                                                                <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                        {nexerciseopen && (
                                            <Grid item xs={12} lg={12} md={12}>
                                                {/* <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                    <Grid item container xs={12} lg={12} md={12}>
                                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                                            <Typography variant="h5">Exercise & Activity</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={12} md={12}>
                                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="mealplanner" />}
                                                                        label="Single"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="waterplanner" />}
                                                                        label="Group"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="monitorweight" />}
                                                                        label="Community"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="barriers" />}
                                                                        label="Training under instructor"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="barriers" />}
                                                                        label="Identify and avoid barriers"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Card> */}
                                                <Accordion
                                                    expanded={expandedAccordion === 'panel3'}
                                                    onChange={handleAccordionChange('panel3')}
                                                    sx={{ background: '#f5f5f5' }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel-content"
                                                        id="panel-header"
                                                    >
                                                        <Typography>Exercise & Activity</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid item container xs={12} lg={12} md={12}>
                                                            <Grid item xs={12} lg={12} md={12}>
                                                                <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="mealplanner" />}
                                                                            label="Single"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="waterplanner" />}
                                                                            label="Group"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="monitorweight" />}
                                                                            label="Community"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="barriers" />}
                                                                            label="Training under instructor"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="barriers" />}
                                                                            label="Identify and avoid barriers"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="custom" />}
                                                                            label="Custom"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                        {attitudeopen && (
                                            <Grid item xs={12} lg={12} md={12}>
                                                {/* <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                    <Grid item container xs={12} lg={12} md={12}>
                                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                                            <Typography variant="h5">Attitude & Lifestyle</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={12} md={12}>
                                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="mealplanner" />}
                                                                        label="Attend community stop smoking group"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="waterplanner" />}
                                                                        label="Plan worry time: 10 minutes"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="monitorweight" />}
                                                                        label="Engage in community activities for age group"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="barriers" />}
                                                                        label="Read daily for atleast 30 minutes"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="barriers" />}
                                                                        label="Talk to one son or daughter every evening"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="barriers" />}
                                                                        label="Meet a friend in weekend"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="barriers" />}
                                                                        label="Invovle in family activities and get togethers"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="barriers" />}
                                                                        label="Read educational material received"
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    container
                                                                    xs={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    spacing={2}
                                                                    alignItems="center"
                                                                >
                                                                    <Grid item xs={4} lg={4} md={4}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="enjoy" />}
                                                                            label="Do something enjoyable for atleast"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3} lg={3} md={3} sx={{ pl: '0px !important' }}>
                                                                        <TextField
                                                                            fullWidth
                                                                            select
                                                                            size="small"
                                                                            id="achieveweight"
                                                                            label="Enter Hours"
                                                                        >
                                                                            <MenuItem value="1">1</MenuItem>
                                                                            <MenuItem value="2">2</MenuItem>
                                                                            <MenuItem value="3">3</MenuItem>
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={3} lg={3} md={3}>
                                                                        <Typography variant="subTitle1">hours each week</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Card> */}
                                                <Accordion
                                                    expanded={expandedAccordion === 'panel4'}
                                                    onChange={handleAccordionChange('panel4')}
                                                    sx={{ background: '#f5f5f5' }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel-content"
                                                        id="panel-header"
                                                    >
                                                        <Typography>Attitude & Lifestyle</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid item container xs={12} lg={12} md={12}>
                                                            <Grid item xs={12} lg={12} md={12}>
                                                                <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="mealplanner" />}
                                                                            label="Attend community stop smoking group"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="waterplanner" />}
                                                                            label="Plan worry time: 10 minutes"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="monitorweight" />}
                                                                            label="Engage in community activities for age group"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="barriers" />}
                                                                            label="Read daily for atleast 30 minutes"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="barriers" />}
                                                                            label="Talk to one son or daughter every evening"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="barriers" />}
                                                                            label="Meet a friend in weekend"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="barriers" />}
                                                                            label="Invovle in family activities and get togethers"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="barriers" />}
                                                                            label="Read educational material received"
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        container
                                                                        xs={12}
                                                                        lg={12}
                                                                        md={12}
                                                                        spacing={2}
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid item xs={4} lg={4} md={4}>
                                                                            <FormControlLabel
                                                                                control={<Checkbox name="enjoy" />}
                                                                                label="Do something enjoyable for atleast"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={3} lg={3} md={3} sx={{ pl: '0px !important' }}>
                                                                            <TextField
                                                                                fullWidth
                                                                                select
                                                                                size="small"
                                                                                id="achieveweight"
                                                                                label="Enter Hours"
                                                                            >
                                                                                <MenuItem value="1">1</MenuItem>
                                                                                <MenuItem value="2">2</MenuItem>
                                                                                <MenuItem value="3">3</MenuItem>
                                                                            </TextField>
                                                                        </Grid>
                                                                        <Grid item xs={3} lg={3} md={3}>
                                                                            <Typography variant="subTitle1">hours each week</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="custom" />}
                                                                            label="Custom"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                        {medicationopen && (
                                            <Grid item xs={12} lg={12} md={12}>
                                                {/* <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                    <Grid item container xs={12} lg={12} md={12}>
                                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                                            <Typography variant="h5">Medication</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={12} md={12}>
                                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="mealplanner" />}
                                                                        label="Schedule medication time and take medication"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="waterplanner" />}
                                                                        label="Document the medication"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="monitorweight" />}
                                                                        label="Attend therapy"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="monitorweight" />}
                                                                        label="Find out about community support for therapy"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="monitorweight" />}
                                                                        label="Read educational material received for therapy details"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="monitorweight" />}
                                                                        label="Medication refills on time"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={12} md={12}>
                                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Card> */}

                                                <Accordion
                                                    expanded={expandedAccordion === 'panel5'}
                                                    onChange={handleAccordionChange('panel5')}
                                                    sx={{ background: '#f5f5f5' }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel-content"
                                                        id="panel-header"
                                                    >
                                                        <Typography>Medication</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid item container xs={12} lg={12} md={12}>
                                                            <Grid item xs={12} lg={12} md={12}>
                                                                <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="mealplanner" />}
                                                                            label="Schedule medication time and take medication"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="waterplanner" />}
                                                                            label="Document the medication"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="monitorweight" />}
                                                                            label="Attend therapy"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="monitorweight" />}
                                                                            label="Find out about community support for therapy"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="monitorweight" />}
                                                                            label="Read educational material received for therapy details"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="monitorweight" />}
                                                                            label="Medication refills on time"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox name="custom" />}
                                                                            label="Custom"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                    </Grid>

                                    {referenceMaterial && (
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Card sx={{ p: 2, pt: 0.5, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                <Grid item container xs={12} lg={12} md={12} sx={{ p: 1 }} alignItems="center">
                                                    <Grid item xs={5} lg={5} md={5}>
                                                        <Typography variant="h5">Reference Materials</Typography>
                                                    </Grid>
                                                    <Grid item xs={7} lg={7} md={7} align="right">
                                                        <AddReferenceMaterial title="Weight Goal" />
                                                    </Grid>
                                                </Grid>
                                                <ReferenceMaterial />
                                            </Card>
                                        </Grid>
                                    )}
                                    {addMonitoring && (
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Card sx={{ p: 2, pt: 0.5, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                <Grid item container xs={12} lg={12} md={12}>
                                                    <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                                        <Typography variant="h5">Add Monitoring</Typography>
                                                    </Grid>
                                                </Grid>
                                                <AddMonitoringTab />
                                            </Card>
                                        </Grid>
                                    )}

                                    {/* <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                                        <Button size="small" variant="contained">
                                            Save
                                        </Button>
                                        <PreviewAndPublish />
                                    </Stack> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="Daily calorie intake" value="Daily calorie intake" onChange={Onchange} />}
                            label="Daily calorie intake"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="nutrientintake" value="Daily micro-nutrient intake" onChange={Onchange} />}
                            label="Daily micro-nutrient intake"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="waterintake" value="Daily water intake" onChange={Onchange} />}
                            label="Daily water intake"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle} alignItems="center">
                        <Grid item xs={0.5} lg={0.5} md={0.5}>
                            <Checkbox name="hoursofsleep" value="hoursofsleep" onChange={Onchange} sx={{ ml: '-11px' }} />
                        </Grid>
                        <Grid item xs={2.5} lg={2.5} md={2.5}>
                            <TextField fullWidth select size="small" label="Choose Hours" OnChange={Onchange}>
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4}>
                            <Typography variant="subTitle1">&nbsp;hours of undisturbed sleep</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="naptime" value="Predefined Nap Time" onChange={Onchange} />}
                            label="Predefined Nap Time"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="dailybedtime"
                                    value="Consistent daily schedule for bed time and wake time"
                                    onChange={Onchange}
                                />
                            }
                            label="Consistent daily schedule for bed time and wake time"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle} alignItems="center">
                        <Grid item xs={3} lg={3} md={3}>
                            <FormControlLabel
                                control={<Checkbox name="Reduce AHI below" value="Reduce AHI below" onChange={Onchange} />}
                                label="Reduce AHI below"
                            />
                        </Grid>
                        <Grid item xs={3} lg={3} md={3}>
                            <TextField fullWidth select size="small" label="Choose Value">
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="reducesugarlevels" value="Bring down sugar levels" onChange={Onchange} />}
                            label="Bring down sugar levels"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="increasemetabolic" value="Increase Metabolic Rate" onChange={Onchange} />}
                            label="Increase Metabolic Rate"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12}>
                            <FormControlLabel
                                control={<Checkbox name="deaddictionandrecovery" value="De-addiction and Recovery" onChange={Onchange} />}
                                label="De-addiction and Recovery"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container sx={{ pl: 5 }}>
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="smoking" value="Smoking" onChange={Onchange} />}
                                        label="Smoking"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="substanceabuse" value="Substance Abuse" onChange={Onchange} />}
                                        label="Substance Abuse"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="alcohol" value="Alcohol" onChange={Onchange} />}
                                        label="Alcohol"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="custom" value="Custom" onChange={Onchange} />}
                                        label="Custom"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="eatingdisorder" value="Recovering from Eating disorder" onChange={Onchange} />}
                            label="Recovering from Eating disorder"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="psychologicaldisorder" value="Heal Psychological disorder" onChange={Onchange} />}
                            label="Heal Psychological disorder"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="physicalmentaltrauma"
                                    value="Physical and Mental Trauma recovery or management"
                                    onChange={Onchange}
                                />
                            }
                            label="Physical and Mental Trauma recovery or management"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="lifestylechanges" value="PMake lifestyle changes" onChange={Onchange} />}
                            label="Make lifestyle changes"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="medicationrefills" value="Medication Refills" onChange={Onchange} />}
                            label="Medication Refills"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="regularmedication" value="Regular medication" onChange={Onchange} />}
                            label="Regular medication"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12}>
                            <FormControlLabel
                                control={<Checkbox name="attendtherapy" value="Attend Therapy" onChange={Onchange} />}
                                label="Attend Therapy"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container sx={{ pl: 5 }}>
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="physio" value="physio" onChange={Onchange} />}
                                        label="Physio"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="speech" value="Speech" onChange={Onchange} />}
                                        label="Speech"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="psychotherapy" value="Psychotherapy" onChange={Onchange} />}
                                        label="Psychotherapy"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="custom" value="Custom" onChange={Onchange} />}
                                        label="Custom"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                        <FormControlLabel
                            control={<Checkbox name="hindrances" value="Identify hindrances" onChange={Onchange} />}
                            label="Identify hindrances"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '10px !important' }}>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12}>
                            <FormControlLabel control={<Checkbox name="custom" value="Custom" onChange={Onchange} />} label="Custom" />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container sx={{ pl: 5 }}>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField id="goalname" label="Goal Name" size="small" />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField id="goaldetails" label="Goal Details" size="small" />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                            label="Acheive By"
                                            value={cdate}
                                            onChange={(newValue) => {
                                                setCDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }}>
                            <Button size="small" variant="outlined" startIcon={<AddCircleIcon />}>
                                Add Custom
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={{ pt: '15px !important' }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={1}>
                            <Button size="small" variant="contained">
                                Save
                            </Button>
                            <Button size="small" variant="contained">
                                Preview & Publish
                            </Button>
                            {/* <Button size="small" variant="contained" onClick={DreamsPlanners}>
                                Next
                            </Button> */}
                            <Button size="small" variant="outlined">
                                Cancel
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CustomCareProgram;
