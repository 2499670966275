import * as React from 'react';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// material-ui
import {
    Button,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    Chip,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// third party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { openSideDrawer, setDrawerData } from 'store/slices/dashboard';
import MainCard from 'ui-component/cards/MainCard';
import { ReactComponent as breakfastIcon } from 'assets/images/dashboard/breakfast.svg';
import TodaysPlanCard from './TodaysPlanCard';
import TodayPlanDrawerCard from './TodaysPlanDrawerCard';

// ==============================|| DATA WIDGET - TASKS CARD ||============================== //

const TodaysPlan = ({ title }) => {
    const theme = useTheme();
    const [value, setValue] = useState('Today');

    const dashboard = useSelector((state) => state.dashboard);
    const { sideDrawer, drawerData } = dashboard;
    const [open, setOpen] = useState(false);
    const [todayPlan, setTodayPlan] = useState({});
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));
    const [isPickedDateisCurrentDate, setIsPickedDateIsCurrentDate] = useState(true);
    const handleDrawerOpen = (todaysPlan) => {
        dispatch(setDrawerData(todaysPlan));
        setOpen((prevState) => !prevState);
        dispatch(openSideDrawer(true));
    };
    const handleDrawerClose = () => {
        dispatch(setDrawerData({}));
        dispatch(openSideDrawer(false));
        setOpen(false);
    };

    const [view, setView] = useState('today');
    const plans = [
        { type: 'Rest', title: 'Wake Up Time', schedule: '6:00AM', completed: false, bgColor: theme.palette.dreams.rest.primary },
        {
            type: 'task',
            bgColor: theme.palette.dreams.exercise.primary,
            title: 'Moderate Walking - 5000 Steps ',
            subTitile: 'With Alexander Hailey',
            completed: true
        },
        {
            type: 'Medication',
            bgColor: theme.palette.dreams.medication.primary,
            title: 'Take Medicine',
            subTitile: 'Metformin 500mg',
            schedule: '8:30AM',
            completed: false
        },
        {
            type: 'Diet',
            bgColor: theme.palette.dreams.diet.primary,
            title: 'Breakfast',
            subTitile: 'Yogurt, Berries, Granola',
            schedule: '9:00AM',
            completed: false
        },
        {
            type: 'Diet',
            bgColor: theme.palette.dreams.diet.primary,
            title: 'Lunch',
            subTitile: '4 oz Chicken ,Salad',
            schedule: '12:00PM',
            completed: false
        },
        {
            type: 'Medication',
            bgColor: theme.palette.dreams.medication.primary,
            title: 'Take Medicine',
            subTitile: 'Neurobion Forte',
            schedule: '12:30PM',
            completed: false
        },
        {
            type: 'Medication',
            bgColor: theme.palette.dreams.medication.primary,
            title: 'Take Medicine',
            subTitile: 'Metformin 500mg',
            schedule: '7:00PM',
            completed: false
        },
        {
            type: 'Diet',
            bgColor: theme.palette.dreams.diet.primary,
            title: 'Dinner',
            subTitile: 'Oats',
            schedule: '7:30PM',
            completed: false
        },
        { type: 'Rest', bgColor: theme.palette.dreams.rest.primary, title: 'Bed Time', schedule: '9:00PM', completed: false }
    ];
    const pending = [
        {
            type: 'task',
            bgColor: theme.palette.dreams.exercise.primary,
            title: 'Moderate Walking - 5000 Steps ',
            subTitile: 'With Alexander Hailey',
            pending: '07/28/2022',
            completed: false
        },
        {
            type: 'Medication',
            bgColor: theme.palette.dreams.medication.primary,
            title: 'Take Medicine',
            subTitile: 'Metformin 500mg',
            pending: '07/28/2022 - 8:30AM',
            completed: false
        }
    ];
    const navigate = useNavigate();
    const handleClick = (card) => {
        if (card.navigate !== undefined) {
            navigate(card.navigate);
        }
    };
    useEffect(() => {
        setTodayPlan(drawerData);
    }, [matchDownSM, open]);

    const todayClick = () => {
        setView('today');
    };

    const pendingClick = () => {
        setView('pending');
    };
    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === 'Pending') {
            setView('pending');
        } else {
            setView('today');
        }
    };
    return (
        <>
            {
                // <DashboardDrawer handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} valueToShow={todayPlan} />
                sideDrawer && (
                    <Drawer
                        sx={{
                            ml: open ? 3 : 0,
                            flexShrink: 0,
                            zIndex: 1200,
                            overflowX: 'hidden',
                            width: { xs: 320, md: 450, lg: 500 },
                            '& .MuiDrawer-paper': {
                                height: '100vh',
                                width: { xs: '100%', md: 450, lg: 500 },
                                position: 'fixed',
                                border: 'none',
                                borderRadius: '0px'
                            }
                        }}
                        variant="temporary"
                        anchor="right"
                        open={open}
                        ModalProps={{ keepMounted: true }}
                        onClose={handleDrawerOpen}
                    >
                        <Box sx={{ p: 2 }}>
                            <Grid container alignItems="center" spacing={0.5} justifyContent="space-between">
                                <Grid item sx={{ width: 'calc(100% - 50px)' }}>
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                display: 'inline-block',
                                                width: 'calc(100% - 34px)',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            {/* {todayPlan.type === 'Diet' &&
                                                todayPlan.schedule !== null &&
                                                todayPlan.schedule === '9:00AM' &&
                                                'BreakFast Plan'}
                                            {todayPlan.type === 'Diet' &&
                                                todayPlan.schedule !== null &&
                                                todayPlan.schedule === '12:00PM' &&
                                                'Lunch Plan'}
                                            {todayPlan.type === 'Diet' &&
                                                todayPlan.schedule !== null &&
                                                todayPlan.schedule === '7:30PM' &&
                                                'Dinner Plan'}
                                            {todayPlan.type === 'Appointment' && 'Appointment Plan'}
                                            {todayPlan.type === 'task' && 'Excercise  Plan'}
                                            {todayPlan.type === 'rest' && 'Rest  Plan'} */}
                                            {/* {todayPlan.schedule && (
                                                <>
                                                    <Chip
                                                        label={todayPlan.schedule}
                                                        size="small"
                                                        sx={{
                                                            color: theme.palette.secondary.main,
                                                            backgroundColor: theme.palette.primary.paper
                                                        }}
                                                    />
                                                    &nbsp;
                                                </>
                                            )} */}
                                            {todayPlan.title}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <IconButton sx={{ p: 0.5, minWidth: 32 }} onClick={() => handleDrawerClose()}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                        {todayPlan.schedule === '9:00AM' && (
                            <>
                                <Divider />
                                <PerfectScrollbar component="div">
                                    <Box sx={{ p: 3 }}>
                                        {' '}
                                        <Grid item xs={12}>
                                            <TodayPlanDrawerCard
                                                title={todayPlan.title}
                                                subtitle={todayPlan.subTitile}
                                                schedule={todayPlan.schedule}
                                                iconPrimary={breakfastIcon}
                                                color={theme.palette.secondary.main}
                                                handleDrawerClose={handleDrawerClose}
                                            />
                                        </Grid>
                                    </Box>
                                </PerfectScrollbar>
                            </>
                        )}
                    </Drawer>
                )
            }
            <MainCard
                contentSX={{ pr: '0px !important' }}
                title={
                    <>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={5} lg={5} md={5}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="h3" color="secondary">
                                            {title}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={7} lg={7} md={7} align="right">
                                    {/* <ToggleButtonGroup
                                        aria-label="text alignment"
                                        sx={{
                                            outlineColor: theme.palette.primary.main,
                                            outlineWidth: '1px',
                                            outlineStyle: 'solid',
                                            margin: '2px'
                                        }}
                                        color="primary"
                                        size="small"
                                        value={view}
                                        exclusive
                                    >
                                        <ToggleButton value="today" aria-label="left aligned" onClick={todayClick}>
                                            Today
                                        </ToggleButton>
                                        <ToggleButton value="pending" aria-label="right aligned" onClick={pendingClick}>
                                            Pending
                                        </ToggleButton>
                                    </ToggleButtonGroup> */}
                                    <div aria-label="text alignment" color="primary" size="small">
                                        <select
                                            style={{
                                                width: '100px',
                                                height: '40px',
                                                border: '1px solid #f1f1f1',
                                                background: '#F7F9F9',
                                                borderRadius: '8px'
                                            }}
                                            onChange={handleDropdownChange}
                                            defaultValue={value}
                                        >
                                            <option value="Today">Today</option>
                                            <option value="Pending">Pending</option>
                                        </select>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                content={false}
            >
                {view === 'today' && (
                    <TodaysPlanCard
                        plans={plans}
                        handleDrawerOpen={handleDrawerOpen}
                        isPickedDateisCurrentDate={isPickedDateisCurrentDate}
                    />
                )}
                {view === 'pending' && (
                    <TodaysPlanCard
                        plans={pending}
                        handleDrawerOpen={handleDrawerOpen}
                        isPickedDateisCurrentDate={isPickedDateisCurrentDate}
                    />
                )}
            </MainCard>
        </>
    );
};

TodaysPlan.propTypes = {
    title: PropTypes.string
};

export default TodaysPlan;
