import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Typography,
    Grid,
    Chip,
    styled,
    tableCellClasses,
    Button,
    Link
} from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { gridSpacing } from 'store/constant';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// table data
function createData(insuranceCompany, planName, holderName, effectiveDate, expirationDate, status, expand, tests) {
    return {
        insuranceCompany,
        planName,
        holderName,
        effectiveDate,
        expirationDate,
        status,
        expand,
        tests
    };
}

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const cardStyle = {
        pt: '15px',
        pl: '15px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.tableColor.tableExpandBg
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.insuranceCompany}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.planName}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.holderName}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.effectiveDate}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.expirationDate}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <Chip
                        label={row.status}
                        size="small"
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'success.light',
                            color: 'success.dark'
                        }}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                    </Stack>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>
                                        {row.expand?.map((items) => (
                                            <TableRow>
                                                <Grid container spacing={gridSpacing} sx={cardStyle}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h5">Insurance Company</Typography>
                                                                {row.insuranceCompany}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h5">Plan Name</Typography>
                                                                {row.planName}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h5">Policy Holder Name</Typography>
                                                                {row.holderName}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h5">Policy Number</Typography>
                                                                123123132
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h5">Group Number</Typography>
                                                                12312312313
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h5">Effective Date</Typography>
                                                                {row.effectiveDate}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h5">Expiration Date</Typography>
                                                                {row.expirationDate}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h5">Status</Typography>
                                                                {row.status}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Button variant="text">Coverage Information</Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button variant="text">Schedule of Benefits</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="outlined" onClick={() => setOpen(!open)}>
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData(
        'Blue Cross',
        'Blue Cross PA',
        'Robert Hailey',
        '01/01/2022',
        '12/31/2022',
        'Active',
        ['Lorem Ipsum'],
        [
            { testname: 'HEMOGLOBIN ', value: '176 G/L', referencerange: '135 - 180 G/L' },
            { testname: 'RED CELL COUNT ', value: '5.9 10*12/L', referencerange: '4.2 - 6  10*12/L' },
            { testname: 'HAEMATOCRIT  ', value: '55%', referencerange: '38 - 52 %' }
        ]
    )
];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function InsuranceTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Insurance Company</StyledTableCell>
                            <StyledTableCell>Plan Name</StyledTableCell>
                            <StyledTableCell>Policy Holder Name</StyledTableCell>
                            <StyledTableCell>Effective Date</StyledTableCell>
                            <StyledTableCell>Expiration Date</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
