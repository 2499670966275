import { useTheme, Grid, Typography, Switch, FormControlLabel, Button, CardContent, IconButton, Card } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import BillingCard from './BillingCard';

const BillingContents = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    const data = [
        {
            status: { type: 'success', content: 'Billed' },
            title: 'CPT Code: 99453',
            contents: [
                ['Completed On', '05/30/2022'],
                ['Devices Supplied', 'Fit Bit Versa2, Glucosmart, Dr Trust Oximeter'],
                ['Non Face to Face On', '05/30/2022'],
                ['First monitored Value Received On', '05/30/2022'],
                ['Billed On', '06/01/2022']
            ]
        },
        {
            // status: { type: 'success', content: 'Billed' },
            title: 'CPT Code: 99454',
            contents: [
                ['Completed On', '05/30/2022'],
                ['Blood Pressure Reading', '25 readings recieved', 'success', 'Billed'],
                ['Sugar Reading', '14 readings recieved', 'warning', 'Not Eligible'],
                ['Oxygen Reading', '25 readings recieved', 'error', 'Not Billed'],
                ['Billed On', '06/01/2022']
            ]
        },
        {
            status: { type: 'error', content: 'Not Billed' },
            title: 'CPT Code: 99091',
            contents: [['Notified to Care Navigator', '']]
        },
        {
            status: { type: 'warning', content: 'Not Eligible' },
            title: 'CPT Code: 99457',
            contents: [['No Virtual Visit', '']]
        },
        {
            status: { type: 'warning', content: 'Not Eligible' },
            title: 'CPT Code: 99458',
            contents: [['No Virtual Visit', '']]
        }
    ];

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };

    const subCardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
        px: '10px !important',
        pt: '15px !important',
        pb: '10px !important',
        mb: '10px !important',
        ml: '20px !important'
    };
    return (
        <>
            <Card sx={cardStyle}>
                <CardContent sx={{ p: '10px !important' }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={11} md={11} lg={11}>
                            <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                                    <b>RPM Cycle (05/15/2022 - 06/30/2022)</b>
                                </Typography>
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} align="right">
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                <ExpandCircleDownOutlinedIcon color="primary" />
                            </IconButton>
                        </Grid>
                        {open &&
                            data.map((item, index) => (
                                <Grid container item xs={12} lg={12} md={12} sx={subCardStyle} alignItems="center">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <BillingCard data={item} />
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default BillingContents;
