import { useTheme, Grid, Typography } from '@mui/material';
import AppointmentTypesTab from '../../../../healthrecords/appointments/appointmenttypestab';

const AppointmentsTab = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }}>
                    <Typography variant="dashboardCardContent">
                        <b>Appointments</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <AppointmentTypesTab />
                </Grid>
            </Grid>
        </>
    );
};

export default AppointmentsTab;
