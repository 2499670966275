import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

// project imports
import useConfig from 'hooks/useConfig';
import value from 'assets/scss/_themes-vars.module.scss';

// ==============================|| AREA CHART ||============================== //

const CalorieGoalGraph = ({ height }) => {
    const theme = useTheme();
    const { navType } = useConfig();

    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey700 = theme.palette.grey[700];

    const [series] = useState([
        {
            name: 'Calorie Goal',
            data: [2500, 2500, 2500, 2500, 2500, 2500]
        },
        {
            name: 'Calorie Intake',
            data: [2100, 2200, 2100, 2300, 2600, 2400]
        }
    ]);
    // chart options
    const areaChartOptions = {
        chart: {
            height: 100,
            type: 'line',
            toolbar: {
                show: false
            }
        },
        colors: [value.secondaryMain, value.primaryMain],
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'category',
            categories: ['January', 'February', 'March', 'April', 'May', 'June']
        },

        labels: ['Jan 2022', 'Feb 2022', 'Mar 2022', 'Apr 2022', 'May 2022', 'Jun 2022'],
        legend: {
            show: true,
            position: 'bottom',
            offsetX: 10,
            offsetY: 10,
            labels: {
                useSeriesColors: false
            },
            markers: {
                width: 16,
                height: 16,
                radius: 5
            },
            itemMargin: {
                horizontal: 15,
                vertical: 8
            }
        }
    };

    const [options, setOptions] = useState(areaChartOptions);
    React.useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            colors: [theme.palette.secondary.main, theme.palette.primary.main],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary, primary, primary, primary, primary, primary, primary]
                    }
                }
            },
            yaxis: {
                // labels: {
                //     style: {
                //         colors: [primary]
                //     }
                // }
                show: true
            },
            grid: {
                borderColor: navType === 'dark' ? darkLight + 20 : grey200
            },
            tooltip: {
                theme: navType === 'dark' ? 'dark' : 'light'
            },
            legend: {
                labels: {
                    colors: 'grey.500'
                }
            }
        }));
    }, [navType, primary, darkLight, grey200, theme]);

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height="400px" />
        </div>
    );
};

export default CalorieGoalGraph;
