import { useTheme, Grid, Typography, Checkbox, FormControlLabel, TextField, Button, MenuItem, Stack, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const MonitoredValues = () => {
    const theme = useTheme();
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '12px !important'
    };
    return (
        <>
            <Grid container alignItems="center" spacing={1}>
                <Grid item container xs={12} lg={12} md={12}>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        {/* <Grid item xs={12} lg={12} md={12} sx={{ pb: 2 }}>
                            <Typography variant="h5">Monitor Values</Typography>
                        </Grid> */}
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container>
                                <Grid container item xs={12} lg={12} md={12} alignItems="center">
                                    <Grid item xs={6} lg={6} md={6} sx={{ pt: 1 }}>
                                        {/* <TextField
                                            disabled
                                            id="outlined-select-experience1"
                                            select
                                            defaultValue="weight"
                                            fullWidth
                                            label="Choose Vitals"
                                            size="small"
                                        >
                                            <MenuItem value="bp">Blood Pressure</MenuItem>
                                            <MenuItem value="sugar">Sugar</MenuItem>
                                            <MenuItem value="weight">Weight</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} lg={6} md={6}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <TextField
                                                id="outlined-select-experience1"
                                                select
                                                defaultValue="monthly"
                                                fullWidth
                                                label="Choose Frequency"
                                                size="small"
                                            >
                                                <MenuItem value="daily">Daily</MenuItem>
                                                <MenuItem value="weekly">Weekly</MenuItem>
                                                <MenuItem value="periodic">Periodic</MenuItem>
                                                <MenuItem value="monthly">Monthly</MenuItem>
                                            </TextField>
                                        </Stack> */}
                                        <Typography variant="h5">
                                            <b>Weight - </b>Monthly
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="alertvalue" />} label="Alert if the value is above" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item container xs={12} lg={12} md={12} sx={{ pt: '15px !important' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Stack direction="row" spacing={1}>
                                <Button size="small" variant="contained">
                                    Preview & Publish
                                </Button>
                                <Button size="small" variant="outlined">
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid> */}
            </Grid>
        </>
    );
};

export default MonitoredValues;
