import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
    Divider,
    Stack,
    Link,
    IconButton
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PatientDetailsTable from './Patientdetails';
import { positions } from '@mui/system';

const validationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    dob: yup.date().required('Date of Birth is required'),
    gender: yup.string().required('Gender is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    mobile: yup.string().required('Mobile is required')
});

const PatientSearch = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [dob, setDOB] = useState(null);
    const [showdetails, setShowDetails] = useState(false);
    const [addPatients, setAddPatients] = useState(false);
    const [open, setOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            dob: '',
            gender: '',
            email: '',
            mobile: ''
        },
        validationSchema,
        onSubmit: (values) => {
            // Handle form submission
            console.log(values);
        }
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        // Handle confirmation logic here
        setOpen(false);
    };

    const handleAddPatientsClick = () => {
        setAddPatients(!addPatients);
    };

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} sx={{ pt: 2, marginTop: '30px' }}>
                <PatientDetailsTable />
            </Grid>
        </Grid>
    );
};

export default PatientSearch;
