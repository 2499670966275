import { Grid, IconButton, useTheme, Typography, Divider, Card, CardContent, TextField, MenuItem, Stack, Button } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

const PhysicalActivity = () => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    const subCardStyle = {
        ml: '20px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px solid',
        borderRadius: 2,
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card sx={cardStyle}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={11} md={11} lg={11}>
                                    <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                        <Typography variant="h5">Physical Activity</Typography>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1} align="right">
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        <ExpandCircleDownOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                                {!open && (
                                    <>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Exercise in last 7 days - Not at all</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Minutes of exercise per day - Not at all</Typography>
                                        </Grid>
                                    </>
                                )}
                                {open && (
                                    <>
                                        <Grid item xs={12} lg={12} md={12} sx={subCardStyle}>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        How many days of moderate to strenuous exercise , like a brisk walk, did you do in
                                                        the last 7 days?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <TextField fullWidth label="Description" defaultValue="1" size="small" />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        On those days that you engage in moderate to strenuous exercise, how many minutes,
                                                        on average, do you exercise?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <TextField fullWidth label="Description" defaultValue="1" size="small" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Stack direction="row" spacing={2}>
                                                <Button variant="contained" onClick={() => setOpen(!open)}>
                                                    Update
                                                </Button>

                                                <Button variant="outlined" onClick={() => setOpen(!open)}>
                                                    Close
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default PhysicalActivity;
