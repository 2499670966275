import { useTheme } from '@mui/material';
import BloodPressureTable from './BloodPressureTable';
import WeightTable from './WeightTable';

const ListContainer = ({ monitoredValue }) => {
    const theme = useTheme();
    const renderTable = () => {
        let obj = <></>;
        const val = monitoredValue.label;
        if (val === 'Blood Pressure') {
            obj = <BloodPressureTable />;
        } else if (val === 'Weight') {
            obj = <WeightTable />;
        }
        return <>{obj}</>;
    };
    return <>{renderTable()}</>;
};

export default ListContainer;
