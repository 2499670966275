export default function MyDropdown() {
    return (
        <form style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <select
                style={{
                    width: '100px',
                    border: 'none',
                    padding: '5px',
                    color: '#707070',
                    fontWeight: 600,
                    background: 'transperant !important'
                }}
            >
                <option>Weekly</option>
                <option>Monthly</option>
            </select>
        </form>
    );
}
