import { useTheme, Grid, Typography, Button, TextField, Stack, MenuItem } from '@mui/material';
import { gridSpacing } from 'store/constant';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useState } from 'react';

const Filter = ({ cardStyle }) => {
    const [show, setShow] = useState(false);
    const [date, setDate] = useState(null);
    const [dateRange, setDateRange] = useState('');

    const handleSelectDateRange = (value) => {
        setDateRange(value);
    };

    return (
        <Grid item xs={12} md={12} lg={12} sx={cardStyle}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} align="left">
                    <Typography variant="h4">Search Vitals </Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="outlined-select-category"
                        select
                        fullWidth
                        label="Filter Vitals by"
                        size="small"
                        onChange={(e) => handleSelectDateRange(e.target.value)}
                    >
                        <MenuItem value="thisweek">This week</MenuItem>
                        <MenuItem value="lastweek"> Last week</MenuItem>
                        <MenuItem value="lastmonth">Last month</MenuItem>
                        <MenuItem value="last3months">Last 3 months</MenuItem>
                        <MenuItem value="last6months">Last 6 months</MenuItem>
                        <MenuItem value="daterange">Choose a date range</MenuItem>
                    </TextField>
                </Grid>
                {dateRange === 'daterange' ? (
                    <>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                    label="From"
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3} sx={{ pr: 3 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                    label="To"
                                    value={date}
                                    sx={{ pr: 3 }}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </>
                ) : (
                    ''
                )}
                <Grid item>
                    <Stack direction="row" spacing={3}>
                        <Button variant="contained" onClick={() => setShow((prev) => !prev)}>
                            Search
                        </Button>

                        <Button variant="outlined" onClick={() => setShow((prev) => !prev)}>
                            Clear
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Filter;
