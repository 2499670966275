import { useTheme, Grid, Typography } from '@mui/material';
import FamilyHealthHistoryTable from '../../../../healthrecords/familyhealthhistory/FamilyHealthHistoryTable';

const FamilyHistoryTab = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }}>
                    <Typography variant="dashboardCardContent">
                        <b>Family Health History</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <FamilyHealthHistoryTable />
                </Grid>
            </Grid>
        </>
    );
};

export default FamilyHistoryTab;
