import { useTheme, Grid, Checkbox, FormControlLabel, Typography, TextField, MenuItem, Stack, Button } from '@mui/material';

const AddActivityTab = () => {
    const theme = useTheme();
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important'
    };
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                    <Typography variant="h5">
                        <b>Add Activity</b>
                    </Typography>
                </Grid>
                <Grid item container xs={12} lg={12} md={12}>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                            <Typography variant="h5">Diet & Nutrition</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="mealplanner" />} label="Meal Planner" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="waterplanner" />} label="Water Planner" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="monitorweight" />} label="Monitor Weight" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="barriers" />} label="Identify and Avoid Barriers" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                            <Typography variant="h5">Rest & Sleep</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="avoidhindrance" />}
                                            label="Identify and avoid hindrance"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="effectivesleep" />}
                                            label="Set activities to prepare for effective sleep"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="sleeprest" />} label="Monitor sleep rest" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                            <Typography variant="h5">Exercise & Activity</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="single" />} label="Single" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="group" />} label="Group" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="community" />} label="Community" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="instructor" />} label="Training under instructor" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="barriers" />} label="Identify and avoid barriers" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                            <Typography variant="h5">Attitude & Lifestyle</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="smoking" />}
                                            label="Attend community stop smoking group"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="worry" />} label="Plan worry time: 10 minutes" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="agegroup" />}
                                            label="Engage in community activities for age group"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="read" />} label="Read daily for atleast 30 minutes" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="talk" />}
                                            label="Talk to one son or daughter every evening"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="friend" />} label="Meet a friend in weekend" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="getogether" />}
                                            label="Involve in family activities and get togethers"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="education" />}
                                            label="Read educational material received"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12} alignItems="center" spacing={2}>
                                    <Grid item xs={4} lg={4} md={4}>
                                        <FormControlLabel control={<Checkbox name="enjoy" />} label="Do something enjoyable for atleast" />
                                    </Grid>
                                    <Grid item xs={3} lg={3} md={3} sx={{ pl: '0px !important' }}>
                                        <TextField fullWidth select size="small" id="achieveweight" label="Enter Hours">
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">3</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3} lg={3} md={3}>
                                        <Typography variant="subTitle1">hours each week</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                            <Typography variant="h5">Medication</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="schedule" />}
                                            label="Schedule Medication time and take medication"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="document" />} label="Document the medication" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="therapy" />} label="Attend therapy" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="communitysupport" />}
                                            label="Find out about community support for therapy"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="educ" />}
                                            label="Read educational material received for therapy details"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="refill" />} label="Medication refills on time" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} sx={{ pt: '15px !important' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Stack direction="row" spacing={1}>
                                <Button size="small" variant="contained">
                                    Preview & Publish
                                </Button>
                                <Button size="small" variant="outlined">
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AddActivityTab;
