import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Stack,
    Typography,
    Grid,
    Chip,
    MenuItem,
    TextField,
    styled,
    tableCellClasses
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets

import { gridSpacing } from 'store/constant';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

// table data
function createData(startDate, endDate, problem, provider, flag, expand) {
    return {
        startDate,
        endDate,
        problem,
        // badgeText,
        flag,
        provider,
        expand
    };
}

const allowCheckBox = (value, tag) => {
    let obj = <></>;
    if (value > 0) {
        obj = tag;
    } else {
        obj = <></>;
    }
    return <>{obj}</>;
};
const reviewState = [
    {
        value: '1',
        label: 'Active'
    },
    {
        value: '2',
        label: 'Inactive'
    }
];

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [currency, setCurrency] = useState('1');
    const [recordedDate, setRecordedDate] = useState('05/06/2021');
    const [startDate, setStartDate] = useState('05/02/2021');
    const [endDate, setEndDate] = useState('05/10/2021');
    const [problemname, setProblemName] = useState('Diabetes Mellitus');
    const [severity, setSeverity] = useState('Severe');
    const [source, setSource] = useState('Sutter Health');
    const [category, setCategory] = useState('-');
    const [bodysite, setBodySite] = useState('-');
    const [note, setNote] = useState('Neque porro quisquam est qui dolorem ipsum quia dolor sit ametconsectetur adipisci velit.');
    const handleClose = () => {
        setOpen(!open);
    };
    const handleSelectChange = (event) => {
        setCurrency(event.target.value);
    };
    const handleNameChange = (event) => {
        setProblemName(event.target.value);
    };
    const handleSeverityChange = (event) => {
        setSeverity(event.target.value);
    };
    const handleSourceChange = (event) => {
        setSource(event.target.value);
    };
    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };
    const handleBodySiteChange = (event) => {
        setBodySite(event.target.value);
    };
    const cardStyle = {
        pt: '20px',
        pl: '15px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.tableColor.tableExpandBg
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.startDate}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.endDate}</b>
                </StyledTableCell>

                <StyledTableCell>
                    <b>{row.problem}</b>
                </StyledTableCell>
                <StyledTableCell>
                    {/* <Chip chipcolor={row.badgeType} label={row.badgeText} /> */}
                    <Chip
                        label="Active"
                        size="small"
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'success.light',
                            color: 'success.dark'
                        }}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.provider}</b>
                </StyledTableCell>
                {/* <TableCell>
                    <b>{row.source}</b>
                </TableCell> */}

                <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                        {/* {row.flag !== '0' && (
                            <IconButton aria-label="expand row" size="small">
                                <DeleteIcon color="error" />
                            </IconButton>
                        )} */}
                    </Stack>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>
                                        {row.expand?.map((items) => (
                                            <TableRow>
                                                {row.flag === '0' && (
                                                    <Grid container spacing={gridSpacing} sx={cardStyle}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={gridSpacing}>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="h5">Start Date</Typography>
                                                                    05/02/2021
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="h5">End Date</Typography>
                                                                    05/10/2021
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="h5">Problem Name</Typography>
                                                                    Diabetes Mellitus
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="h5">Status</Typography>
                                                                    <Chip
                                                                        label="Active"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor:
                                                                                theme.palette.mode === 'dark'
                                                                                    ? theme.palette.dark.dark
                                                                                    : 'success.light',
                                                                            color: 'success.dark'
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={3}>
                                                                    <Typography variant="h5">Severity</Typography>
                                                                    Severe
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="h5">Recorded Date</Typography>
                                                                    05/06/2021
                                                                </Grid>
                                                                {/* <Grid item xs={3}>
                                                                    <Typography variant="h5">Category</Typography>-
                                                                </Grid> */}
                                                                <Grid item xs={3}>
                                                                    <Typography variant="h5">Body Site</Typography>-
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="h5">Source</Typography>
                                                                    Sutter Health
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5">Note</Typography>
                                                                    Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                                                                    consectetur, adipisci velit.
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Button variant="outlined" onClick={handleClose}>
                                                                Close
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {row.flag !== '0' && (
                                                    <Grid container spacing={gridSpacing} sx={cardStyle}>
                                                        <Grid item xs={3}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    renderInput={(props) => (
                                                                        <TextField size="small" fullWidth {...props} helperText="" />
                                                                    )}
                                                                    label="Start Date"
                                                                    value={startDate}
                                                                    onChange={(newValue) => {
                                                                        setStartDate(newValue);
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    renderInput={(props) => (
                                                                        <TextField size="small" fullWidth {...props} helperText="" />
                                                                    )}
                                                                    label="End Date"
                                                                    value={endDate}
                                                                    onChange={(newValue) => {
                                                                        setEndDate(newValue);
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="problemname"
                                                                label="Problem Name"
                                                                size="small"
                                                                value={problemname}
                                                                onChange={handleNameChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                id="status"
                                                                select
                                                                label="Status"
                                                                value={currency}
                                                                size="small"
                                                                fullWidth
                                                                onChange={handleSelectChange}
                                                            >
                                                                {reviewState.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="severity"
                                                                label="Severity"
                                                                size="small"
                                                                value={severity}
                                                                onChange={handleSeverityChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    renderInput={(props) => (
                                                                        <TextField size="small" fullWidth {...props} helperText="" />
                                                                    )}
                                                                    label="Recorded Date"
                                                                    value={recordedDate}
                                                                    onChange={(newValue) => {
                                                                        setRecordedDate(newValue);
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        {/* <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="category"
                                                                label="Category"
                                                                size="small"
                                                                value={category}
                                                                onChange={handleCategoryChange}
                                                            />
                                                        </Grid> */}

                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="bodysite"
                                                                label="Body Site"
                                                                size="small"
                                                                value={bodysite}
                                                                onChange={handleBodySiteChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="h5">Source</Typography>
                                                            Myself
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            {/* <TextareaAutosize aria-label="empty textarea" placeholder="Note" style={{ width: 400, height: 100 }} /> */}
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                label="Note"
                                                                value={note}
                                                                onChange={handleNoteChange}
                                                            />
                                                        </Grid>
                                                        {/* <Grid item xs={3}>
                                                            <Typography variant="h5">Source</Typography>
                                                            Myself
                                                        </Grid> */}
                                                        <Grid item xs={12}>
                                                            <Stack direction="row" spacing={2}>
                                                                <Button variant="contained" onClick={handleClose}>
                                                                    Update
                                                                </Button>

                                                                <Button variant="outlined" onClick={handleClose}>
                                                                    Close
                                                                </Button>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData('05/02/2021', '05/10/2021', 'Diabetes Mellitus', 'Robert Hailey', '0', ['Lorem Ipsum']),
    createData('05/02/2021', '05/10/2021', 'Diabetes Mellitus', 'Sutter Health', '1', ['Lorem Ipsum'])
    // createData('Primary Physician', 'Roger Miles', '03/03/2022', 'Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum', [
    //     'Lorem Ipsum',
    //     'Lorem Ipsum',
    //     'Lorem Ipsum'
    // ]),
    // createData('Primary Physician', 'Roger Miles', '03/03/2022', 'Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum', [
    //     'Lorem Ipsum',
    //     'Lorem Ipsum',
    //     'Lorem Ipsum'
    // ])
];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function ProblemListTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Start Date</StyledTableCell>
                            <StyledTableCell>End Date</StyledTableCell>
                            <StyledTableCell>Problem Name</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Source</StyledTableCell>
                            {/* <TableCell>Source</TableCell> */}
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
