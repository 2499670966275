import {
    Grid,
    IconButton,
    useTheme,
    Typography,
    Divider,
    Card,
    CardContent,
    Stack,
    Button,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

const ExposureToViolence = () => {
    const [open, setOpen] = useState(false);
    const [q1, setQ1] = useState('2');
    const [q2, setQ2] = useState('2');
    const [q3, setQ3] = useState('2');
    const [q4, setQ4] = useState('2');

    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    const subCardStyle = {
        ml: '20px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px solid',
        borderRadius: 2,
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card sx={cardStyle}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={11} md={11} lg={11}>
                                    <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                        <Typography variant="h5">Exposure To Violence (Intimate Partner Violence)</Typography>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1} align="right">
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        <ExpandCircleDownOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                                {!open && (
                                    <>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Humiliation/Emotional abuse - No</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Afraid - No</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Rape/Forced sexual activity - No</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Physical abuse - No</Typography>
                                        </Grid>
                                    </>
                                )}
                                {open && (
                                    <>
                                        <Grid item xs={12} lg={12} md={12} sx={subCardStyle}>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        Within the last year, have you been humiliated or emotionally abused in other ways
                                                        by your partner or ex-partner?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-label="q1"
                                                            value={q1}
                                                            onChange={(e) => setQ1(e.target.value)}
                                                            name="row-radio-buttons-group"
                                                        >
                                                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                            <FormControlLabel value="2" control={<Radio />} label="No" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        Within the last year, have you been afraid of your partner or ex-partner?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-label="q2"
                                                            value={q2}
                                                            onChange={(e) => setQ2(e.target.value)}
                                                            name="row-radio-buttons-group"
                                                        >
                                                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                            <FormControlLabel value="2" control={<Radio />} label="No" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        Within the last year, have you been raped or forced to have any kind of sexual
                                                        activity by your partner or ex-partner?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-label="q3"
                                                            value={q3}
                                                            onChange={(e) => setQ3(e.target.value)}
                                                            name="row-radio-buttons-group"
                                                        >
                                                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                            <FormControlLabel value="2" control={<Radio />} label="No" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        Within the last year, have you been kicked, hit, slapped, or otherwise physically
                                                        hurt by your partner or ex-partner?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-label="q4"
                                                            value={q4}
                                                            onChange={(e) => setQ4(e.target.value)}
                                                            name="row-radio-buttons-group"
                                                        >
                                                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                            <FormControlLabel value="2" control={<Radio />} label="No" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Stack direction="row" spacing={2}>
                                                <Button variant="contained" onClick={() => setOpen(!open)}>
                                                    Update
                                                </Button>

                                                <Button variant="outlined" onClick={() => setOpen(!open)}>
                                                    Close
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default ExposureToViolence;
