export const ontrack = {
    color: '#00C853',
    border: '1px solid #00C853',
    padding: '5px 10px',
    borderRadius: '100px',
    marginLeft: '20px'
};
export const offtrack = {
    color: '#C62828',
    border: '1px solid #C62828',
    padding: '5px 10px',
    borderRadius: '100px',
    marginLeft: '20px'
};

export const weightgoalicon = {
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    justifyContent: 'center',
    padding: '10px'
};

export const startweighticonbkg = {
    background: '#EB6F03',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center'
};
export const dateiconbkg = {
    background: '#0946BE',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center'
};
export const mygoalwrapper = {
    background: 'white',
    borderRadius: '10px',
    borderColor: '#000',
    marginTop: '20px',
    margin: '10px 0 10px 0'
};

export const contentalign = {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#F1FDE1',
    marginBottom: '15px',
    padding: '10px',
    border: '1px solid #EEEEEE',
    borderRadius: '8px',
    alignItems: 'center'
};
export const flex = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0'
};
export const noofgoals = {
    background: '#EEF2F9',
    color: '#1D5DDF',
    padding: '5px',
    border: '1px solid #1D5DDF',
    borderRadius: '100px',
    width: '130px',
    display: 'flex',
    justifyContent: 'center'
};
export const selectbox = {
    padding: '0px'
};
export const oftrack = {
    color: 'green'
};
