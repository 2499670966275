import { useTheme } from '@mui/material';
import BloodPressureAreaChart from '../../../../../../healthrecords/vitals/bloodpressure/BloodPressureAreaChart';

const BloodPressureChart = () => {
    const theme = useTheme();
    return (
        <>
            <BloodPressureAreaChart />
        </>
    );
};

export default BloodPressureChart;
