import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Grid, Stack } from '@mui/material';
import { mygoalwrapper } from '../../../assets/customstyle/Goalstyle';
import Dropdown from './Dropdown';
import { useTheme } from '@mui/material/styles';

const data = [
    { name: 'Sun', value: 220.5 },
    { name: 'Mon', value: 220 },
    { name: 'Tue', value: 219.5 },
    { name: 'Wed', value: 219 },
    { name: 'Thu', value: 218.5 },
    { name: 'Fri', value: 217.5 },
    { name: 'Sat', value: 216 }
];

export default function Weightgoalprogress() {
    const theme = useTheme();
    return (
        <Grid item xs={12} lg={12} md={12} sx={mygoalwrapper}>
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                    alignItems: 'center',
                    borderBottom: '1px solid #d7d3d3',
                    marginBottom: '20px'
                }}
            >
                <Grid item xs={7} lg={7} md={7}>
                    <Typography variant="h3">Weight Goal Progress</Typography>
                </Grid>
                <Grid item xs={5} lg={5} md={5}>
                    <Dropdown />
                </Grid>
            </Grid>
            <Grid
                xs={8}
                lg={8}
                md={8}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1, p: 2, margin: '0 auto' }}
            >
                <Stack>
                    <Typography variant="h3" sx={{ color: theme.palette.goaldreamsbkg.headingwrapper }}>
                        216 lbs
                    </Typography>
                    <Typography variant="p">Current Weight</Typography>
                </Stack>
                <Stack>
                    <Typography variant="h3" sx={{ color: theme.palette.goaldreamsbkg.headingwrapper }}>
                        4.4 lbs
                    </Typography>
                    <Typography variant="p">Change (-2%)</Typography>
                </Stack>
            </Grid>
            <Grid xs={12} lg={12} md={12}>
                <Stack sx={{ p: 2 }}>
                    <ResponsiveContainer width="100%" height={353}>
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="value" stroke="#C62F2F" strokeWidth={3} />
                        </LineChart>
                    </ResponsiveContainer>
                </Stack>
            </Grid>
        </Grid>
    );
}
