import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Typography,
    Grid,
    styled,
    tableCellClasses,
    TextField,
    Autocomplete,
    Button,
    MenuItem
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { gridSpacing } from 'store/constant';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

// table data
function createData(problem, relationship, ageofdiagnosis, dateofrecord, source, viewOnly, expand) {
    return {
        problem,
        relationship,
        ageofdiagnosis,
        dateofrecord,
        source,
        viewOnly,
        expand
    };
}

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const cardStyle = {
        pt: '20px',
        pl: '15px',
        pb: '15px',
        backgroundColor: theme.palette.tableColor.tableExpandBg
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const [problem, setProblem] = useState('Diabetes Mellitus');

    const problems = [
        { label: 'Diabetes Mellitus', id: 1 },
        { label: 'Diabetes insipidus', id: 2 },
        { label: 'Necrobiosis lipoidica diabeticorum', id: 3 }
    ];

    const handleChange = (e) => {
        setProblem(e.target.value);
    };

    const [endDate, setEndDate] = useState('05/10/2021');

    const expandContents = (row) => {
        let obj = <></>;
        const viewOnly = row.viewOnly;
        if (viewOnly) {
            obj = (
                <>
                    <Grid container spacing={gridSpacing} sx={cardStyle}>
                        <Grid item xs={3}>
                            <Typography variant="h5">Problem</Typography>
                            Diabetes Mellitus
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h5">Relationship</Typography>
                            Father
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h5">Age of Diagnosis</Typography>
                            52
                        </Grid>
                        {/* <Grid item xs={2}>
                            <Typography variant="h5">End Date</Typography>
                            05/10/2021
                        </Grid> */}
                        <Grid item xs={2}>
                            <Typography variant="h5">Source</Typography>
                            {row.source}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Note</Typography>
                            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" onClick={() => setOpen(!open)}>
                                    Close
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            );
        } else {
            obj = (
                <>
                    <Grid container spacing={gridSpacing} sx={cardStyle}>
                        <Grid item xs={3}>
                            <Autocomplete
                                disableClearable
                                options={problems}
                                defaultValue={problem}
                                onChange={handleChange}
                                size="small"
                                renderInput={(params) => <TextField size="small" {...params} label="Problem" />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                defaultValue="father"
                                id="outlined-select-experience1"
                                select
                                fullWidth
                                label="Relationship"
                                size="small"
                            >
                                <MenuItem value="son">Child</MenuItem>
                                <MenuItem value="grandchild">Grandchild</MenuItem>
                                <MenuItem value="spouse">Spouse</MenuItem>
                                <MenuItem value="father">Father</MenuItem>
                                <MenuItem value="mother">Mother</MenuItem>
                                <MenuItem value="sibling">Sibling</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                                <MenuItem value="self">Self</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={2}>
                            <TextField id="ageofdiagnosis" label="Age of Diagnosis" fullWidth defaultValue="52" size="small" />
                        </Grid>
                        {/* <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                    fullWidth
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid> */}
                        <Grid item xs={2}>
                            <Typography variant="h5">Source</Typography>
                            {row.source}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                defaultValue="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit"
                                fullWidth
                                label="Note"
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" onClick={() => setOpen(!open)}>
                                    Update
                                </Button>

                                <Button variant="outlined" onClick={() => setOpen(!open)}>
                                    Close
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            );
        }
        return <>{obj}</>;
    };

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.problem}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.relationship}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.ageofdiagnosis}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.source}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                        {/* {!row.viewOnly ? (
                            <IconButton aria-label="expand row" size="small">
                                <DeleteIcon color="error" />
                            </IconButton>
                        ) : (
                            <></>
                        )} */}
                    </Stack>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>
                                        {row.expand?.map((items) => (
                                            <TableRow>{expandContents(row)}</TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData('Diabetes Mellitus', 'Father', '52', '05/06/1998', 'Sutter Health', false, ['Lorem Ipsum']),
    createData('Diabetes Mellitus', 'Father', '52', '05/06/1998', 'Robert Hailey', true, ['Lorem Ipsum'])
];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function FamilyHealthHistoryTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Problem</StyledTableCell>
                            <StyledTableCell>Relationship</StyledTableCell>
                            <StyledTableCell>Age of Diagnosis</StyledTableCell>
                            <StyledTableCell>Source</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
