// material-ui
import { useTheme } from '@emotion/react';
import { Box, styled, Paper, CardContent, Typography, Card, Grid, Button, Divider } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import SubCard from 'ui-component/cards/SubCard';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import MuiTypography from '@mui/material/Typography';
import { gridSpacing } from 'store/constant';
import Filter from './Filter';

const VitalsCardView = () => {
    const cards = [
        {
            title: 'Blood Pressure',
            subcards: [{ title: 'Systolic/Diastolic (141/76)', subTitle: '05/05/2022 7:30 AM [PST]' }],
            viewAll: true,
            navigate: '/healthrecords/vitals/bloodpressure'
        },
        {
            title: 'Blood Oxygen',
            subcards: [{ subTitle: 'No data available' }]
        },
        {
            title: 'ECG',
            subcards: [{ subTitle: 'No data available' }]
        },
        {
            title: 'A1C',
            subcards: [{ subTitle: 'No data available' }]
        },
        {
            title: 'Heart Rate & HRV',
            subcards: [{ subTitle: 'No data available' }]
        },
        {
            title: 'Energy Level',
            subcards: [{ subTitle: 'No data available' }]
        },
        {
            title: 'Weight/Height',
            subcards: [{ subTitle: 'No data available' }]
        },
        {
            title: 'Body Temperature',
            subcards: [{ subTitle: 'No data available' }]
        },
        {
            title: 'Mood',
            subcards: [{ subTitle: 'No data available' }]
        }
    ];

    const theme = useTheme();
    const navigate = useNavigate();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const handleClick = (card) => {
        if (card.navigate !== undefined) {
            navigate(card.navigate);
        }
    };
    /*  const filterCardStyle = {
        pt: '15px',
        mt: '10px',
        ml: '25px',
        mr: '25px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.white,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    }; */

    return (
        <>
            <Grid container spacing={gridSpacing} pt="12px">
                {/* <Filter cardStyle={filterCardStyle} /> */}
                <Grid item xs={12} md={12} lg={12}>
                    <Box sx={{ minHeight: 393 }}>
                        <Masonry columns={3} spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
                            {cards.map((card, index) => (
                                <SubCard key={index} style={{ border: '0.1px solid #D3D3D3' }} sx={cardStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                                                {card.title}
                                            </MuiTypography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Card style={{ border: '1px solid #e9e8e8' }}>
                                                <CardContent sx={{ pt: 2, pb: 1 }}>
                                                    {card.subcards.map((content, index) => (
                                                        <>
                                                            {index > 0 && <Divider sx={{ pt: 1 }} />}
                                                            <Grid container>
                                                                <Grid item xs={12} sx={{ pt: 1, pb: 0 }}>
                                                                    <Typography variant="dashboardCardContent">{content.title}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sx={{ pt: 0, pb: 1 }}>
                                                                    <Typography variant="body1">{content.subTitle}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ))}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={8} lg={8} md={8} />
                                        {card.viewAll && (
                                            <Grid item xs={4} lg={4} md={4} align="right">
                                                <Button variant="text" size="small" onClick={() => handleClick(card)}>
                                                    <FormattedMessage id="View_Details" />
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </SubCard>
                            ))}
                        </Masonry>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default VitalsCardView;
