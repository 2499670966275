import React from 'react';
// import HorizontalTimeline from 'react-horizontal-timeline';

const EXAMPLE = [
    {
        data: '2019-12-05',
        status: 'status',
        // statusB: 'Admission Start',
        statusE: 'Admission Open',
        text: 'Medication List Reconcile'
    },
    {
        data: '2020-01-21',
        status: 'status',
        // statusB: 'Start 1st round',
        statusE: 'Open for Fillup',
        text: 'Interactive Contact'
    },
    {
        data: '2020-02-25',
        status: 'status',
        // statusB: 'Start 2nd round',
        statusE: 'process',
        text: 'Medication Refill'
    },
    {
        data: '2020-03-16',
        status: 'status',
        // statusB: 'Start 3rd round',
        statusE: 'Done',
        text: 'Face to Face'
    },
    {
        data: '2020-04-19',
        status: 'status',
        // statusB: 'Start 4th round',
        statusE: 'Done',
        text: 'TCM Report'
    }
    // {
    //     data: '2020-05-23',
    //     status: 'status',
    //     // statusB: 'Complete',
    //     statusE: 'Done',
    //     text: 'Lorem Ipsum'
    // }
];

export default class Timeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curIdxS: 0,
            prevIdx: -1
        };
    }

    //  state = { value: 0, previous: 0 };

    render() {
        const { curIdxS, prevIdx } = this.state;
        const curStatus = EXAMPLE[curIdxS].statusB;
        const prevStatus = prevIdx >= 0 ? EXAMPLE[prevIdx].statusB : '';

        return (
            <div>
                <div
                    className="horizontal-timeline-custom"
                    style={{
                        width: '100%',
                        height: '80px',
                        margin: '0 auto',
                        marginTop: '20px',
                        fontSize: '15px'
                    }}
                >
                    {/* <HorizontalTimeline
                        styles={{
                            background: '#f8f8f8',
                            foreground: '#1A79AD',
                            outline: '#dfdfdf'
                        }}
                        index={curIdxS}
                        indexClick={(index) => {
                            const curIdx = curIdxS;
                            // this.setState({ curIdxS: index, prevIdx: curIdx });
                        }}
                        values={EXAMPLE.map((x) => x.data)}
                        getLabel={(date) =>
                            EXAMPLE.filter((x) => x.data === date).map((q) => {
                                const x = q.text;
                                return x;
                            })
                        }
                    /> */}
                </div>
                <div className="text-center">
                    {/* Prevoius:-{prevStatus} - Current Select:-{curStatus} */}
                    {curStatus}
                </div>
            </div>
        );
    }
}
