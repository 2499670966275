import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Stack,
    Typography,
    Grid,
    Chip,
    MenuItem,
    TextField,
    styled,
    tableCellClasses,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets

import { gridSpacing } from 'store/constant';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

// table data
function createData(medication, format, dosage, prescribedDate, provider, flag, expand) {
    return {
        medication,
        format,
        dosage,
        prescribedDate,
        provider,
        flag,
        expand
    };
}

const allowCheckBox = (value, tag) => {
    let obj = <></>;
    if (value > 0) {
        obj = tag;
    } else {
        obj = <></>;
    }
    return <>{obj}</>;
};
const reviewState = [
    {
        value: '1',
        label: 'Active'
    },
    {
        value: '2',
        label: 'Inactive'
    }
];

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [currency, setCurrency] = useState('1');
    const [prescribedDate, setPrescribedDate] = useState('05/06/2022');
    const [medication, setMedication] = useState('Metformin');
    const [format, setFormat] = useState('Tablet');
    const [source, setSource] = useState('Sutter Health');
    const [dosage, setDosage] = useState('Take one tablet daily as prescribed');
    const [reason, setReason] = useState('Diabetes Mellitus');
    const [physician, setPhysician] = useState('Dr. Roger Miles');
    const [intent, setIntent] = useState('Order');
    const [manufacturer, setManufacturer] = useState('Obimet');
    const [note, setNote] = useState('Neque porro quisquam est qui dolorem ipsum quia dolor sit.');
    const [route, setRoute] = useState('Oral');
    const [quantity, setQuantity] = useState('1');
    const [frequency, setFrequency] = useState('Before meals');

    const handleRouteChange = (event) => {
        setRoute(event.target.value);
    };
    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };
    const handleFrequencyChange = (event) => {
        setFrequency(event.target.value);
    };
    const handleClose = () => {
        setOpen(!open);
    };
    const handleSelectChange = (event) => {
        setCurrency(event.target.value);
    };
    const handleMedicationChange = (event) => {
        setMedication(event.target.value);
    };
    const handleFormatChange = (event) => {
        setFormat(event.target.value);
    };
    const handleSourceChange = (event) => {
        setSource(event.target.value);
    };
    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };
    const handleDosageChange = (event) => {
        setDosage(event.target.value);
    };
    const handleManufacturerChange = (event) => {
        setManufacturer(event.target.value);
    };
    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };
    const handlePhysicianChange = (event) => {
        setPhysician(event.target.value);
    };
    const handleIntentChange = (event) => {
        setIntent(event.target.value);
    };

    const physicianState = ['Dr. Roger Miles', ' Dr. Frank Ellison'];
    const routeState = ['Oral'];
    const quantityState = ['1/4', '1/2', '1', '1 1/2', '2', '3', '4', '5', '6', '7', '8', '9'];
    const frequencyState = [
        '1 time',
        'After meals',
        'Before meals',
        'Every 8 hours',
        'Every day',
        'Every other day',
        'Four times/day',
        'Three times/day',
        'Twice/day'
    ];
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    const cardStyle = {
        pt: '15px',
        pl: '15px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.tableColor.tableExpandBg
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.medication}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.format}</b>
                </StyledTableCell>

                <StyledTableCell>
                    <b>{row.dosage}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.prescribedDate}</b>
                </StyledTableCell>
                <StyledTableCell>
                    {/* <Chip chipcolor={row.badgeType} label={row.badgeText} /> */}
                    <Chip
                        label="Active"
                        size="small"
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'success.light',
                            color: 'success.dark'
                        }}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.provider}</b>
                </StyledTableCell>
                {/* <TableCell>
                    <b>{row.source}</b>
                </TableCell> */}

                <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                        {/* {row.flag !== '0' && (
                            <IconButton aria-label="expand row" size="small">
                                <DeleteIcon color="error" />
                            </IconButton>
                        )} */}
                    </Stack>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>
                                        {row.expand?.map((items) => (
                                            <TableRow>
                                                {row.flag === '0' && (
                                                    <Grid container spacing={gridSpacing} sx={cardStyle}>
                                                        <Grid item xs={4}>
                                                            <Grid container spacing={gridSpacing}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5">Medication</Typography>
                                                                    Metformin
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5">Route</Typography>
                                                                    Oral
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Grid container spacing={gridSpacing}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5">Format</Typography>
                                                                    Tablet
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5">Quantity</Typography>1
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Grid container spacing={gridSpacing}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5">Dosage</Typography>
                                                                    Take one tablet daily as prescribed
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5">Frequency</Typography>
                                                                    Before meals
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={gridSpacing}>
                                                                <Grid item xs={4}>
                                                                    <Typography variant="h5">Prescribed Date</Typography>
                                                                    05/06/2022
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography variant="h5">Status</Typography>
                                                                    <Chip
                                                                        label="Active"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor:
                                                                                theme.palette.mode === 'dark'
                                                                                    ? theme.palette.dark.dark
                                                                                    : 'success.light',
                                                                            color: 'success.dark'
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography variant="h5">Manufacturer</Typography>
                                                                    Obimet
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={gridSpacing}>
                                                                <Grid item xs={4}>
                                                                    <Typography variant="h5">Reason</Typography>
                                                                    Diabetes Mellitus
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography variant="h5">Physician</Typography>
                                                                    Dr. Roger Miles
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography variant="h5">Intent</Typography>
                                                                    Order
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container spacing={gridSpacing}>
                                                                <Grid item xs={8}>
                                                                    <Typography variant="h5">Note</Typography>
                                                                    Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                                                                    consectetur, adipisci velit.
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography variant="h5">Source</Typography>
                                                                    Sutter Health
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button variant="outlined" onClick={handleClose}>
                                                                Close
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {row.flag !== '0' && (
                                                    <Grid container spacing={gridSpacing} sx={cardStyle}>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="medication"
                                                                label="Medication"
                                                                size="small"
                                                                value={medication}
                                                                onChange={handleMedicationChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="format"
                                                                label="Format"
                                                                size="small"
                                                                value={format}
                                                                onChange={handleFormatChange}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="dosage"
                                                                label="Dosage"
                                                                size="small"
                                                                value={dosage}
                                                                onChange={handleDosageChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="route">Route</InputLabel>
                                                                <Select
                                                                    id="route"
                                                                    MenuProps={MenuProps}
                                                                    value={route}
                                                                    size="small"
                                                                    label="Route"
                                                                    fullWidth
                                                                    onChange={handleRouteChange}
                                                                >
                                                                    {routeState.map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            {/* <TextField fullWidth id="severity" label="severity" size="small" /> */}
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="quantity">Quantity</InputLabel>
                                                                <Select
                                                                    id="quantity"
                                                                    MenuProps={MenuProps}
                                                                    value={quantity}
                                                                    size="small"
                                                                    label="Quantity"
                                                                    fullWidth
                                                                    onChange={handleQuantityChange}
                                                                >
                                                                    {quantityState.map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            {/* <TextField fullWidth id="severity" label="severity" size="small" /> */}
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="frequency">Frequency</InputLabel>
                                                                <Select
                                                                    id="frequency"
                                                                    MenuProps={MenuProps}
                                                                    value={frequency}
                                                                    size="small"
                                                                    label="Frequency"
                                                                    fullWidth
                                                                    onChange={handleFrequencyChange}
                                                                >
                                                                    {frequencyState.map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    renderInput={(props) => (
                                                                        <TextField size="small" fullWidth {...props} helperText="" />
                                                                    )}
                                                                    label="Prescribed Date"
                                                                    value={prescribedDate}
                                                                    onChange={(newValue) => {
                                                                        setPrescribedDate(newValue);
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                id="status"
                                                                select
                                                                label="Status"
                                                                value={currency}
                                                                size="small"
                                                                fullWidth
                                                                onChange={handleSelectChange}
                                                            >
                                                                {reviewState.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="manufacturer"
                                                                label="Manufacturer"
                                                                size="small"
                                                                value={manufacturer}
                                                                onChange={handleManufacturerChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="reason"
                                                                label="Reason"
                                                                size="small"
                                                                value={reason}
                                                                onChange={handleReasonChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="route">Physician</InputLabel>
                                                                <Select
                                                                    id="physician"
                                                                    MenuProps={MenuProps}
                                                                    value={physician}
                                                                    size="small"
                                                                    label="Physician"
                                                                    fullWidth
                                                                    onChange={handlePhysicianChange}
                                                                >
                                                                    {physicianState.map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        {/*  <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="source"
                                                                label="Source"
                                                                size="small"
                                                                value={source}
                                                                onChange={handleSourceChange}
                                                            />
                                                        </Grid> */}
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="intent"
                                                                label="Intent"
                                                                size="small"
                                                                value={intent}
                                                                onChange={handleIntentChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            {/* <TextareaAutosize aria-label="empty textarea" placeholder="Note" style={{ width: 400, height: 100 }} /> */}
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                label="Note"
                                                                value={note}
                                                                onChange={handleNoteChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="h5">Source</Typography>
                                                            Myself
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Stack direction="row" spacing={2}>
                                                                <Button variant="contained" onClick={handleClose}>
                                                                    Update
                                                                </Button>

                                                                <Button variant="outlined" onClick={handleClose}>
                                                                    Close
                                                                </Button>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData('Metformin 500mg', 'Tablet', ' Take one tablet daily as prescribed', '05/06/2022', 'Robert Hailey', '0', ['Lorem Ipsum']),
    createData('Metformin 500mg', 'Tablet', ' Take one tablet daily as prescribed', '05/06/2022', 'Sutter Health', '1', ['Lorem Ipsum'])
];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function MedicationTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Medication</StyledTableCell>
                            <StyledTableCell>Format</StyledTableCell>
                            <StyledTableCell>Dosage</StyledTableCell>
                            <StyledTableCell>Prescribed Date</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Source</StyledTableCell>
                            {/* <TableCell>Source</TableCell> */}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
