import { useTheme } from '@mui/material';
import Demographics from './components/demographics/Demographics';

const DemographicsTab = () => {
    const theme = useTheme();
    return (
        <>
            <Demographics />
        </>
    );
};

export default DemographicsTab;
