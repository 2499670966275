import { Button, Chip, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { Navigate, useNavigate } from 'react-router-dom';
// import AppointmentFilter from './AppointmentFilter';
import { gridSpacing } from 'store/constant';

export default function UpcomingAppointmentsCard() {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard
                        content={false}
                        sx={{
                            p: 2,
                            background:
                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? 'transparent' : '#e9e8e8',

                            '&:hover': {
                                border: `1px solid${theme.palette.primary.main}`
                            }
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Chip
                                    label="Virtual Visit"
                                    size="small"
                                    sx={{
                                        color: theme.palette.primary.paper,
                                        bgcolor: theme.palette.secondary.main
                                    }}
                                />
                                <Typography align="left" variant="h5" component="div" pt={1}>
                                    06/08/2022 07:30 AM [PST] &nbsp;
                                </Typography>
                                <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                                    <>
                                        <b>Dr. Roger Miles - Sutter Health,</b> San Antonio, Texas
                                    </>
                                </Typography>
                                <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                                    <>
                                        <b>Orthopedics</b>
                                    </>
                                </Typography>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
}
