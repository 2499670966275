import { useTheme, Grid, Typography } from '@mui/material';
import TCMReportsContents from './TCMReportsContents';

const TCMReports = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} alignItems="center" sx={{ mt: 1 }}>
                    <Typography variant="dashboardCardContent">
                        <b>TCM Reports</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12} md={12} alignItems="center">
                    <TCMReportsContents />
                </Grid>
            </Grid>
        </>
    );
};

export default TCMReports;
