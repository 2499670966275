// material-ui
import { Table, TableContainer, TableHead, TableRow, Card, useTheme } from '@mui/material';
// project imports
import SubCard from 'ui-component/cards/SubCard';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// ==============================|| TABLE - DENSE ||============================== //

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.black.black
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

export default function TCMNotes() {
    const theme = useTheme();
    /*   const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8',
        minWidth: 600
    }; */
    return (
        <SubCard contentSX={{ pt: '8px !important' }}>
            {/*  <Grid item sx={{ pl: 3 }}>
                <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                    Vital Signs
                </MuiTypography>
            </Grid> */}
            <TableContainer
                sx={{ minWidth: 500, border: '1px solid', borderBottom: '0px !important', borderColor: theme.palette.common.grey }}
            >
                <Table size="small" aria-label="a dense table">
                    <TableRow>
                        <StyledTableCell align="left">Education material send : Material on Diabetics</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left">
                            Video demonstration of &lsquo;how to take insulin injection &lsquo; send{' '}
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left">
                            Patient community health did not respond to the care ,hence referred to new community health
                        </StyledTableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        </SubCard>
    );
}
