import { Button, Chip, Grid, IconButton, Link, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Navigate, useNavigate } from 'react-router-dom';
import AppointmentFilter from './AppointmentFilter';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpansionCard from './appointmentdetails/ExpansionCard';

export default function AppointmentsCard() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [viewDetails, setViewDetails] = useState(false);
    const [option, setOption] = useState(0);

    const handleClick = (e) => {
        setOption(e);
        setViewDetails(!viewDetails);
    };
    return (
        <>
            {/*  <Grid container spacing={gridSpacing} pt="12px">
                <AppointmentFilter />
            </Grid> */}
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard
                        content={false}
                        sx={{
                            p: 2,
                            background:
                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? 'transparent' : '#e9e8e8',

                            '&:hover': {
                                border: `1px solid${theme.palette.primary.main}`
                            }
                        }}
                    >
                        <Grid container>
                            <Grid item xs={11}>
                                <Chip
                                    label="Office Visit"
                                    size="small"
                                    /*  sx={{
                                        color: theme.palette.primary.main,
                                        bgcolor: theme.palette.primary.paper
                                    }} */
                                    color="info"
                                />
                                <Typography align="left" variant="h5" component="div" pt={1}>
                                    05/06/2022 07:30 AM [PST] &nbsp;
                                    <Chip
                                        label="Completed"
                                        size="small"
                                        sx={{
                                            color: theme.palette.success.main,
                                            bgcolor: theme.palette.success.light
                                        }}
                                    />
                                </Typography>
                                <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                                    <>
                                        <b>Dr. Roger Miles - Sutter Health,</b> San Antonio, Texas
                                    </>
                                </Typography>
                                <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                                    <>
                                        <b>Orthopedics</b>
                                    </>
                                </Typography>
                                <Typography align="left" variant="body2" sx={{ pt: 1, pb: 1 }}>
                                    Illness
                                </Typography>
                                {!viewDetails && (
                                    <Grid item>
                                        <Stack direction="row" spacing={1}>
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                                target="_blank"
                                                underline="hover"
                                                gutterBottom
                                                sx={{ cursor: 'pointer', pr: 2 }}
                                                onClick={() => {
                                                    handleClick(0);
                                                }}
                                            >
                                                View Clinical Summary
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                                component={Link}
                                                target="_blank"
                                                underline="hover"
                                                gutterBottom
                                                href
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleClick(1);
                                                }}
                                            >
                                                View Notes
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={1} pt={7} pl={5}>
                                <IconButton onClick={() => handleClick(0)} size="small">
                                    {viewDetails && <KeyboardArrowUp size="large" sx={{ fontSize: '40px' }} />}
                                    {!viewDetails && <KeyboardArrowDownIcon size="large" sx={{ fontSize: '40px' }} />}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
                {viewDetails && (
                    <Grid item xs={12} md={12} lg={12}>
                        <ExpansionCard option={option} />
                    </Grid>
                )}
            </Grid>
        </>
    );
}
