import { Typography, Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { mygoalwrapper, contentalign, flex } from '../../../assets/customstyle/Goalstyle';
import { useNavigate } from 'react-router-dom';
import { activeItem } from 'store/slices/menu';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'store';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles((theme) => ({
    // Define your button styles
    diet: {
        '&:hover': {
            backgroundColor: '#00C853'
        }
    },
    rest: {
        '&:hover': {
            backgroundColor: '#C62828'
        }
    }
}));

export default function Goalsummary() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleClick = () => {
        navigate('/dreams');
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'dreams');
        if (currentIndex > -1) {
            dispatch(activeItem(['dreams']));
        }
    };
    return (
        <Grid item xs={12} lg={12} md={12} style={mygoalwrapper}>
            <Grid item xs={12} sx={{ height: 'auto' }}>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ borderBottom: '1px solid #d7d3d3', marginBottom: '20px' }}>
                    <Typography variant="h4" sx={{ padding: '12px 12px 14px 12px' }}>
                        Goals Summary
                    </Typography>
                </Stack>
                <Card sx={{ padding: '0 10px' }}>
                    <CardMedia />
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: theme.palette.goaldreamsbkg.diet,
                            marginBottom: '10px',
                            padding: '10px !important',
                            border: '1px solid #EEEEEE',
                            borderRadius: '8px',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            gutterBottom
                            variant="h4"
                            component="div"
                            sx={{ display: 'flex', alignItems: 'center', marginBottom: '0', color: theme.palette.dreams.diet.primary }}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: theme.palette.dreams.diet.primary,
                                    display: 'flex',
                                    color: theme.palette.background.paper,
                                    width: '30px',
                                    height: '30px',
                                    marginRight: '1px'
                                }}
                            >
                                D
                            </Avatar>
                            iet
                        </Typography>
                        <Typography gutterBottom variant="div" component="div" sx={{ mb: 0 }}>
                            <Chip
                                label={
                                    <>
                                        <b>Good</b>
                                    </>
                                }
                                sx={{
                                    color: theme.palette.primary.light,
                                    backgroundColor: theme.palette.success.dark,
                                    border: `1px solid ${theme.palette.success.dark}`
                                }}
                                color="success"
                                size="small"
                            />
                        </Typography>
                    </CardContent>
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: theme.palette.goaldreamsbkg.rest,
                            marginBottom: '10px',
                            height: '60px',
                            border: '1px solid #EEEEEE',
                            borderRadius: '8px',
                            alignItems: 'center',
                            padding: '0 10px'
                        }}
                    >
                        <Typography
                            gutterBottom
                            variant="h4"
                            component="div"
                            sx={{ display: 'flex', alignItems: 'center', marginBottom: '0', color: theme.palette.dreams.rest.primary }}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: theme.palette.dreams.rest.primary,
                                    display: 'flex',
                                    color: theme.palette.background.paper,
                                    width: '30px',
                                    height: '30px',
                                    marginRight: '1px'
                                }}
                            >
                                R
                            </Avatar>
                            est
                        </Typography>
                        <Typography gutterBottom variant="div" component="div" sx={{ mb: 0 }}>
                            <Chip
                                label={
                                    <>
                                        <b>Poor</b>
                                    </>
                                }
                                sx={{
                                    color: theme.palette.primary.light,
                                    backgroundColor: theme.palette.error.dark,
                                    border: `1px solid ${theme.palette.error.dark}`
                                }}
                                color="success"
                                size="small"
                            />
                        </Typography>
                    </CardContent>
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: theme.palette.goaldreamsbkg.excercise,
                            marginBottom: '10px',
                            padding: '0 10px !important',
                            border: '1px solid #EEEEEE',
                            borderRadius: '8px',
                            alignItems: 'center',
                            height: '60px'
                        }}
                    >
                        <Typography
                            gutterBottom
                            variant="h4"
                            component="div"
                            sx={{ display: 'flex', alignItems: 'center', marginBottom: '0', color: theme.palette.dreams.exercise.primary }}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: theme.palette.dreams.exercise.primary,
                                    display: 'flex',
                                    color: theme.palette.background.paper,
                                    width: '30px',
                                    height: '30px',
                                    marginRight: '1px'
                                }}
                            >
                                E
                            </Avatar>
                            xercise
                        </Typography>
                        <Typography gutterBottom variant="div" component="div" sx={{ position: 'relative', left: '-12px', mb: 0 }}>
                            NA
                        </Typography>
                    </CardContent>
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: theme.palette.goaldreamsbkg.attitude,
                            marginBottom: '10px',
                            padding: '0 10px !important',
                            border: '1px solid #EEEEEE',
                            borderRadius: '8px',
                            alignItems: 'center',
                            height: '60px'
                        }}
                    >
                        <Typography
                            gutterBottom
                            variant="h4"
                            component="div"
                            sx={{ display: 'flex', alignItems: 'center', marginBottom: '0', color: theme.palette.dreams.attitude.primary }}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: theme.palette.dreams.attitude.primary,
                                    display: 'flex',
                                    color: theme.palette.background.paper,
                                    width: '30px',
                                    height: '30px',
                                    marginRight: '1px'
                                }}
                            >
                                A
                            </Avatar>
                            ttitude
                        </Typography>
                        <Typography gutterBottom variant="div" component="div" sx={{ position: 'relative', left: '-12px', mb: 0 }}>
                            NA
                        </Typography>
                    </CardContent>
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: theme.palette.goaldreamsbkg.medication,
                            marginBottom: '10px',
                            padding: '0 10px !important',
                            border: '1px solid #EEEEEE',
                            borderRadius: '8px',
                            alignItems: 'center',
                            height: '60px'
                        }}
                    >
                        <Typography
                            gutterBottom
                            variant="h4"
                            component="div"
                            sx={{
                                color: theme.palette.dreams.medication.primary,
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '0'
                            }}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: theme.palette.dreams.medication.primary,
                                    display: 'flex',
                                    color: theme.palette.background.paper,
                                    width: '30px',
                                    height: '30px',
                                    marginRight: '1px'
                                }}
                            >
                                M
                            </Avatar>
                            edication
                        </Typography>
                        <Typography gutterBottom variant="div" component="div" sx={{ position: 'relative', left: '-12px', mb: 0 }}>
                            NA
                        </Typography>
                    </CardContent>
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: theme.palette.goaldreamsbkg.specialist,
                            marginBottom: '10px',
                            padding: '0 10px !important',
                            border: '1px solid #EEEEEE',
                            borderRadius: '8px',
                            alignItems: 'center',
                            height: '60px'
                        }}
                    >
                        <Typography
                            gutterBottom
                            variant="h4"
                            component="div"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '0',
                                color: theme.palette.dreams.specialist.primary
                            }}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: theme.palette.dreams.specialist.primary,
                                    display: 'flex',
                                    color: theme.palette.background.paper,
                                    width: '30px',
                                    height: '30px',
                                    marginRight: '1px'
                                }}
                            >
                                S
                            </Avatar>
                            pecialist
                        </Typography>
                        <Typography gutterBottom variant="div" component="div" sx={{ position: 'relative', left: '-12px', mb: 0 }}>
                            NA
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
                <CardActions
                    sx={{
                        p: 1,
                        justifyContent: 'flex-end',
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: '10px',
                        padding: '10px 8px'
                    }}
                >
                    <Button variant="text" size="small" onClick={handleClick}>
                        <b>View DREAMS Summary</b>
                    </Button>
                </CardActions>
            </Grid>
        </Grid>
    );
}
