import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import {
    Autocomplete,
    Avatar,
    Button,
    Collapse,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
    Stack
} from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { IconArrowsDiagonal2 } from '@tabler/icons';
import React, { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// third-party
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
// project imports
import { gridSpacing } from 'store/constant';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const avatarImage = require.context('assets/images/careteam', true);

// ==============================|| MAIL COMPOSE DIALOG ||============================== //

const ComposeDialog = () => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const mailState = useSelector((state) => state.mail);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const [ccBccValue, setCcBccValue] = useState(false);
    const handleCcBccChange = () => {
        setCcBccValue((prev) => !prev);
    };

    let composePosition = {};
    useEffect(() => {
        setData(mailState.mails);
    }, [mailState]);
    const [position, setPosition] = useState(true);
    if (!position) {
        composePosition = {
            '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                '& .MuiPaper-root': { mb: 0, borderRadius: '12px 12px 0px 0px', maxWidth: '100%' }
            }
        };
    }
    const intl = useIntl();

    const topics = [{ topic: 'Diet nutrition guidlines' }, { topic: 'Tips for better diet' }, { topic: 'Planning sleep and rest' }];
    const recipients = [
        { name: 'Dr.Roger Miles', designation: 'Primary Psysician', avatar: 'dr_roger_miles_primary_physician.png' },
        { name: 'Gloria Hailey', designation: 'Proxy', avatar: 'gloria_hailey_proxy_wife.png' },
        { name: 'Issac White', designation: 'Nurse,Sutter Health', avatar: 'issac_white_nurse.png' },
        { name: 'Gloria Hailey, Casey Hailey, Sara Hailey ', designation: 'Family Care Team', avatar: 'patient.png' },
        { name: 'Gloria Hailey, Casey Hailey, Sara Hailey ', designation: 'Family Care Team', avatar: 'doctor.png' },
        { name: 'Gloria Hailey, Casey Hailey, Sara Hailey ', designation: 'Family Care Team', avatar: 'patient2.png' }
    ];
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Button variant="contained" onClick={handleClickOpen} sx={{ width: '100%' }} size="large">
                <FormattedMessage id="Compose" />
            </Button>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleCloseDialog} sx={composePosition}>
                {open && (
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" spacing={0}>
                                    <Grid item xs={6} sm={9}>
                                        <Typography component="div" align="left" variant="h4">
                                            <FormattedMessage id="New_Message" />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Stack direction="row" justifyContent="flex-end">
                                            <IconButton onClick={() => setPosition(!position)} size="large">
                                                <IconArrowsDiagonal2 />
                                            </IconButton>
                                            <IconButton onClick={handleCloseDialog} size="large">
                                                <HighlightOffTwoToneIcon />
                                            </IconButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={topics}
                                    getOptionLabel={(option) => option.topic}
                                    renderInput={(params) => <TextField {...params} label={intl.formatMessage({ id: 'Topic' })} />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="assign"
                                    options={recipients}
                                    fullWidth
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>
                                            {option.name !== 'Family Care Team' ? (
                                                // <img
                                                //     loading="lazy"
                                                //     sx={{ borderRadius: '0' }}
                                                //     width="20"
                                                //     src={avatarImage(`./${option.avatar}`).default}
                                                //     alt=""
                                                // />
                                                <Avatar
                                                    alt={option.name}
                                                    src={option.avatar && avatarImage(`./${option.avatar}`).default}
                                                    size={matchDownSM ? 'xs' : 'sm'}
                                                />
                                            ) : (
                                                <Avatar
                                                    color="primary"
                                                    sx={{ width: 38, height: 38, fontSize: '1rem', bgcolor: theme.palette.primary.paper }}
                                                >
                                                    FC
                                                </Avatar>
                                            )}
                                            <Typography m={1} variant="h5">
                                                {option.name}
                                            </Typography>
                                            - {option.designation}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            // name="To"
                                            label={intl.formatMessage({ id: 'To' })}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password' // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: 'Subject' })} />
                            </Grid>
                            <Grid item xs={12} sx={{ display: ccBccValue ? 'block' : 'none' }}>
                                <Collapse in={ccBccValue}>
                                    {ccBccValue && (
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth label={intl.formatMessage({ id: 'CC' })} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField fullWidth label={intl.formatMessage({ id: 'BCC' })} />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Collapse>
                            </Grid>

                            {/* quill editor */}
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    '& .quill': {
                                        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
                                        borderRadius: '12px',
                                        '& .ql-toolbar': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'grey.400',
                                            borderTopLeftRadius: '12px',
                                            borderTopRightRadius: '12px'
                                        },
                                        '& .ql-container': {
                                            borderColor:
                                                theme.palette.mode === 'dark'
                                                    ? `${theme.palette.dark.light + 20} !important`
                                                    : `${theme.palette.grey[400]} !important`,
                                            borderBottomLeftRadius: '12px',
                                            borderBottomRightRadius: '12px',
                                            '& .ql-editor': {
                                                minHeight: 125
                                            }
                                        }
                                    }
                                }}
                            >
                                <ReactQuill theme="snow" />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <IconButton size="large">
                                            <AttachmentTwoToneIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item sx={{ flexGrow: 1 }} />
                                    <Grid item>
                                        <Button variant="contained">
                                            <FormattedMessage id="Send" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
};

export default ComposeDialog;
