import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Typography,
    Grid,
    styled,
    tableCellClasses,
    TextField,
    Button
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { gridSpacing } from 'store/constant';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// table data
function createData(date, systolic, diastolic, source, viewOnly) {
    return {
        date,
        systolic,
        diastolic,
        source,
        viewOnly
    };
}

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState('05/05/2022');

    const cardStyle = {
        pt: '15px',
        pl: '15px',
        pb: '15px',
        backgroundColor: theme.palette.tableColor.tableExpandBg
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const expandContents = (row) => {
        let obj = <></>;
        if (row.viewOnly) {
            obj = (
                <>
                    <TableRow>
                        <Grid container spacing={gridSpacing} sx={cardStyle}>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={3}>
                                        <Typography variant="h5">Date</Typography>
                                        {row.date}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="h5">Systolic Blood Pressure</Typography>
                                        {row.systolic}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="h5">Diastolic Blood Pressure</Typography>
                                        {row.diastolic}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h5">Source</Typography>
                                        {row.source}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TableRow>
                </>
            );
        } else {
            obj = (
                <>
                    <TableRow>
                        <Grid container spacing={gridSpacing} sx={cardStyle}>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                                fullWidth
                                                label="Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField fullWidth size="small" label="Systolic Blood Pressure" defaultValue={row.systolic} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField fullWidth size="small" label="Diastolic Blood Pressure" defaultValue={row.diastolic} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h5">Source</Typography>
                                        {row.source}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" onClick={() => setOpen(!open)}>
                                        Update
                                    </Button>

                                    <Button variant="outlined" onClick={() => setOpen(!open)}>
                                        Close
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </TableRow>
                </>
            );
        }

        return obj;
    };

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.date}</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <b>{row.systolic}</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <b>{row.diastolic}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.source}</b>
                </StyledTableCell>
                {/* <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                        {!row.viewOnly ? (
                            <IconButton aria-label="expand row" size="small">
                                <DeleteIcon color="error" />
                            </IconButton>
                        ) : (
                            <></>
                        )}
                    </Stack>
                </StyledTableCell> */}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>{expandContents(row)}</TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData('05/05/2022 7:30 AM [PST]', '141', '76', 'Sutter Health', false),
    createData('05/05/2022 7:30 AM [PST]', '141', '76', 'Robert Hailey', true),
    createData('05/05/2022 7:30 AM [PST]', '141', '76', 'Sutter Health', false),
    createData('05/05/2022 7:30 AM [PST]', '141', '76', 'Robert Hailey', true),
    createData('05/05/2022 7:30 AM [PST]', '141', '76', 'Sutter Health', false),
    createData('05/05/2022 7:30 AM [PST]', '141', '76', 'Robert Hailey', true),
    createData('05/05/2022 7:30 AM [PST]', '141', '76', 'Sutter Health', false)
];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function BloodPressureTable({ showAll, condensedCount }) {
    const theme = useTheme();
    const [showMore, setShowMore] = useState(false);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const renderRows = (rows, showAll, condensedCount) => {
        let obj = <></>;
        if (!showAll) {
            obj = (
                <>
                    {rows.map((row, index) => (index + 1 <= condensedCount ? <Row key={index} row={row} /> : <></>))}

                    {!showMore && (
                        <Button size="small" variant="text" onClick={() => setShowMore((prev) => !prev)}>
                            Show More
                        </Button>
                    )}
                    {showMore && rows.map((row, index) => (index + 1 > condensedCount ? <Row key={index} row={row} /> : <></>))}
                    {showMore && (
                        <Button size="small" variant="text" onClick={() => setShowMore((prev) => !prev)}>
                            Show Less
                        </Button>
                    )}
                </>
            );
        }
        if (showAll === undefined) {
            obj = (
                <>
                    {rows.map((row, index) => (
                        <Row key={index} row={row} />
                    ))}
                </>
            );
        }
        return obj;
    };

    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Systolic Blood Pressure</StyledTableCell>
                            <StyledTableCell>Diastolic Blood Pressure</StyledTableCell>
                            <StyledTableCell>Source</StyledTableCell>
                            {/* <StyledTableCell width="1" /> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderRows(rows, showAll, condensedCount)}</TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
