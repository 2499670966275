import { Grid, useTheme, FormControlLabel, Checkbox, Typography, TextField, MenuItem, Button, Link } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useState } from 'react';
import Chip from 'ui-component/extended/Chip';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CreateNewRPM from './CreateNewRPM';
import RPMCyclesInnerTab from './RPMCyclesInnerTab';
import ViewOnboardingActivities from './onboardingactivities/ViewOnboardingActivities';
import RPMCyclesInnerTabContainer from './RPMCyclesInnerTabContainer';

const RPMCyclesContent = () => {
    const theme = useTheme();
    const [onboardingActivitiesFlag, setOnboadingActivitiesFlag] = useState(false);
    const [viewRPMCyclesFlag, setViewRPMCyclesFlag] = useState(false);
    const onboardingClickHandle = () => {
        setOnboadingActivitiesFlag(!onboardingActivitiesFlag);
        setViewRPMCyclesFlag(false);
    };
    const viewRPMCyclesClickHandle = () => {
        setViewRPMCyclesFlag(!viewRPMCyclesFlag);
        setOnboadingActivitiesFlag(false);
    };
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important',
        ml: '15px !important'
    };
    return (
        <>
            <Grid container>
                <Grid container item xs={12} lg={12} md={12} sx={cardStyle} spacing={1}>
                    <Grid container item xs={12} lg={12} md={12}>
                        <Grid item xs={5} lg={5} md={5}>
                            <Typography variant="h4">RPM Cycle started on 05/15/2022</Typography>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4}>
                            <AnimateButton>
                                <Link to sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={onboardingClickHandle}>
                                    View Onboarding Activities
                                </Link>
                            </AnimateButton>
                        </Grid>
                        <Grid item xs={3} lg={3} md={3}>
                            <AnimateButton>
                                <Link to sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={viewRPMCyclesClickHandle}>
                                    View RPM Cycles
                                </Link>
                            </AnimateButton>
                        </Grid>
                        <Grid item xs={3} lg={3} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{
                                            color: theme.palette.error.main,
                                            '&.Mui-checked': {
                                                color: theme.palette.error.main
                                            }
                                        }}
                                    />
                                }
                                label="Discontinue"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {onboardingActivitiesFlag && (
                    <Grid container item xs={12} lg={12} md={12}>
                        <Grid item xs={12} lg={12} md={12} sx={{ pl: '8px !important', pt: '8px !important', ml: '15px !important' }}>
                            <Typography variant="h4">View Onboarding Activities</Typography>
                        </Grid>
                        <ViewOnboardingActivities />
                    </Grid>
                )}
                {viewRPMCyclesFlag && (
                    <Grid container item xs={12} lg={12} md={12}>
                        <Grid item xs={12} lg={12} md={12} sx={{ pl: '8px !important', pt: '8px !important', ml: '15px !important' }}>
                            <Typography variant="h4">View RPM Cycles</Typography>
                        </Grid>
                        <Grid container item xs={12} lg={12} md={12} sx={{ pl: '0px !important', ml: '15px !important' }}>
                            <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }}>
                                <RPMCyclesInnerTabContainer />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default RPMCyclesContent;
