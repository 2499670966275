import { useTheme, Grid, Typography, Switch, FormControlLabel, Button, CardContent, IconButton, Card, Chip, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import RPMCyclesInnerTab from './RPMCyclesInnerTab';

const RPMCyclesInnerTabContainer = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    const data = [
        {
            status: { type: 'success', content: 'Billed' },
            title: 'CPT Code: 99453',
            contents: [
                ['Completed On', '05/30/2022'],
                ['Devices Supplied', 'Fit Bit Versa2, Glucosmart, Dr Trust Oximeter'],
                ['Non Face to Face On', '05/30/2022'],
                ['First monitored Value Received On', '05/30/2022'],
                ['Billed On', '06/01/2022']
            ]
        },
        {
            // status: { type: 'success', content: 'Billed' },
            title: 'CPT Code: 99454',
            contents: [
                ['Completed On', '05/30/2022'],
                ['Blood Pressure Reading', '25 readings recieved', 'success', 'Billed'],
                ['Sugar Reading', '14 readings recieved', 'warning', 'Not Eligible'],
                ['Oxygen Reading', '25 readings recieved', 'error', 'Not Billed'],
                ['Billed On', '06/01/2022']
            ]
        },
        {
            status: { type: 'error', content: 'Not Billed' },
            title: 'CPT Code: 99091',
            contents: [['Notified to Care Navigator', '']]
        },
        {
            status: { type: 'warning', content: 'Not Eligible' },
            title: 'CPT Code: 99457',
            contents: [['No Virtual Visit', '']]
        },
        {
            status: { type: 'warning', content: 'Not Eligible' },
            title: 'CPT Code: 99458',
            contents: [['No Virtual Visit', '']]
        }
    ];

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };

    const subCardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
        px: '10px !important',
        pt: '15px !important',
        pb: '10px !important',
        mb: '10px !important',
        ml: '20px !important'
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12}>
                    <Card sx={cardStyle}>
                        <CardContent sx={{ p: '10px !important' }}>
                            <Grid container spacing={gridSpacing} alignItems="center">
                                <Grid item xs={11} md={11} lg={11}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                                            <b>RPM Cycle 2 - 06/30/2022</b>
                                        </Typography>
                                        <Chip
                                            size="small"
                                            label="In Progress"
                                            sx={{ color: theme.palette.primary.light }}
                                            color="primary"
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1} align="right">
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        <ExpandCircleDownOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                                {open && (
                                    <Grid container item xs={12} lg={12} md={12} sx={subCardStyle} alignItems="center">
                                        <Grid item xs={12} md={12} lg={12}>
                                            <RPMCyclesInnerTab />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Card sx={cardStyle}>
                        <CardContent sx={{ p: '10px !important' }}>
                            <Grid container spacing={gridSpacing} alignItems="center">
                                <Grid item xs={9} md={9} lg={9}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                                            <b>RPM Cycle 1 - 05/15/2022</b>
                                        </Typography>
                                        <Chip
                                            size="small"
                                            label="Completed - Billed"
                                            sx={{ color: theme.palette.primary.light }}
                                            color="success"
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3} align="right">
                                    <IconButton aria-label="expand row" size="small">
                                        <ExpandCircleDownOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                                {/* {open && (
                                    <Grid container item xs={12} lg={12} md={12} sx={subCardStyle} alignItems="center">
                                        <Grid item xs={12} md={12} lg={12}>
                                            <RPMCyclesInnerTab />
                                        </Grid>
                                    </Grid>
                                )} */}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default RPMCyclesInnerTabContainer;
