// assets
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ComposeDialog from './ComposeDialog';
import {
    Box,
    ButtonBase,
    Checkbox,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Typography,
    useMediaQuery,
    TextField,
    InputAdornment,
    Stack
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
// material-ui
import { useTheme } from '@mui/material/styles';
// third-party
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { isChecked } from 'store/slices/mail';
import MainCard from 'ui-component/cards/MainCard';
// project imports
import Chip from 'ui-component/extended/Chip';
import MessageEmpty from './MessageEmpty';
import MessageListHeader from './MessageListHeader';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FilterTable from './filtertable/FilterTable';
import InboxIcon from '@mui/icons-material/Inbox';
import SendIcon from '@mui/icons-material/Send';
import DraftsIcon from '@mui/icons-material/Drafts';
import { useSelector, useDispatch } from 'react-redux';
import Badge from '@mui/material/Badge';

const avatarImage = require.context('assets/images/careteam', true);
// ==============================|| TABLE HEADER ||============================== //

function EnhancedTableHead({ selected }) {
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="none" colSpan={2}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    selected: PropTypes.array
};

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 && (
            <Typography color="inherit" variant="h4" component="div">
                {numSelected} Mail Selected
            </Typography>
        )}
    </Toolbar>
);

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

// ==============================|| CUSTOMER LIST ||============================== //

const MessageList = ({
    data,
    search,
    handleSearch,
    handleDrawerOpen,
    openMailSidebar,
    handleUserDetails,
    handleStarredChange,
    handleImportantChange,
    length
}) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const mailCheckedState = useSelector((state) => state.mail.isChecked);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const dispatch = useDispatch();

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
            dispatch(isChecked(true));
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            dispatch(isChecked(true));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            dispatch(isChecked(true));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            dispatch(isChecked(true));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const [denseTable, setDenseTable] = React.useState(false);
    const handleDenseTable = () => {
        setDenseTable(!denseTable);
    };

    const darkBG = theme.palette.mode === 'dark' ? 'dark.main' : 'grey.100';
    const handleSelectAllClick = (event) => {
        dispatch(isChecked(false));
        if (event.target.checked) {
            const newSelectedId = data.map((n) => n.sender.name);
            setSelected(newSelectedId);
            dispatch(isChecked(true));
            return;
        }
        setSelected([]);
    };

    React.useEffect(() => {
        if (selected.length === 0) {
            dispatch(isChecked(false));
        }
    }, [selected]);

    return (
        <>
            <Grid container spacing={matchDownSM ? 3 : 1}>
                {/* <Grid item xs={12}>
                    <MessageListHeader
                        search={search}
                        handleSearch={handleSearch}
                        length={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDenseTable={handleDenseTable}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <TabPanel value="1" sx={{ padding: 0 }}>
                                {data.length ? (
                                    <MainCard content={false} sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50' }}>
                                        {/* table */}
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                {/* <Tab label="Inbox" value="1" /> */}
                                                <Tab
                                                    label={
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <InboxIcon style={{ marginRight: '4px' }} />
                                                            Inbox
                                                            <Badge badgeContent={1} color="error" sx={{ marginLeft: '12px' }} />
                                                        </span>
                                                    }
                                                    value="1"
                                                />

                                                <Tab
                                                    icon={<SendIcon sx={{ paddingRight: '2px', marginBottom: '0 !important' }} />}
                                                    label="Send"
                                                    value="2"
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                />
                                                <Tab
                                                    icon={<DraftsIcon sx={{ paddingRight: '2px', marginBottom: '0 !important' }} />}
                                                    label="Draft"
                                                    value="3"
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                />
                                            </TabList>
                                            <Stack direction="row" alignItems="center" spacing={1.5} sx={{ padding: '0 10px' }}>
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon fontSize="small" />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={handleSearch}
                                                    value={search}
                                                    size="small"
                                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                                />
                                                <div style={{ width: '100%', maxWidth: '130px' }}>
                                                    <ComposeDialog />
                                                </div>
                                            </Stack>
                                        </div>

                                        <TableContainer sx={{ borderRadius: '0' }}>
                                            <Table
                                                size={denseTable ? 'small' : undefined}
                                                aria-labelledby="tableTitle"
                                                sx={{
                                                    minWidth: 320,
                                                    '& td': { whiteSpace: 'nowrap', px: 0.75, py: denseTable ? 0.5 : 1.25 }
                                                }}
                                            >
                                                <TableHead sx={{ backgroundColor: theme.palette.tableColor.tableHeaderBg }}>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                indeterminate={selected.length > 0 && selected.length < data.length}
                                                                checked={data.length > 0 && selected.length === data.length}
                                                                onChange={handleSelectAllClick}
                                                                inputProps={{
                                                                    'aria-label': 'select all'
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>Name</TableCell>

                                                        <TableCell>Subject</TableCell>
                                                        <TableCell>{}</TableCell>
                                                        <TableCell align="center">Date & Time</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {selected.length > 0 && <EnhancedTableHead selected={selected} />}
                                                <TableBody>
                                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                        const isItemSelected = isSelected(row.sender.name);
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                sx={{
                                                                    bgcolor: !row.isRead ? darkBG : '',
                                                                    '& td:last-of-type>div': {
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        right: 5,
                                                                        transform: 'translateY(-50%)',
                                                                        display: 'none',
                                                                        background:
                                                                            theme.palette.mode === 'dark'
                                                                                ? theme.palette.dark[800]
                                                                                : theme.palette.tableColor.tableRowBg,
                                                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                                                        borderRadius: '12px',
                                                                        py: 1,
                                                                        px: 1.75,
                                                                        '& button + button': {
                                                                            ml: 0.625
                                                                        }
                                                                    },
                                                                    '&:hover': {
                                                                        '& td:last-of-type>div': {
                                                                            display: 'block'
                                                                        }
                                                                    }
                                                                }}
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={index}
                                                                selected={isItemSelected}
                                                            >
                                                                <TableCell>
                                                                    <Checkbox
                                                                        checked={
                                                                            isItemSelected ||
                                                                            (data.length > 0 && selected.length === data.length)
                                                                        }
                                                                        // checked={isItemSelected}
                                                                        color="primary"
                                                                        onChange={(event) => handleClick(event, row.sender.name)}
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell
                                                                    id={labelId}
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={(e) => handleUserDetails(e, row)}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        alignItems="center"
                                                                        sx={{ flexWrap: 'nowrap' }}
                                                                    >
                                                                        <Grid item xs zeroMinWidth>
                                                                            <ButtonBase disableRipple>
                                                                                <Typography
                                                                                    align="left"
                                                                                    variant={row.isRead ? 'body1' : 'subtitle1'}
                                                                                    component="div"
                                                                                >
                                                                                    {row.sender.name}{' '}
                                                                                    {row.sender.role === 'Specialist' && (
                                                                                        <Chip
                                                                                            label={row.sender.role}
                                                                                            size="small"
                                                                                            // sx={{
                                                                                            //     color: theme.palette.primary.main,
                                                                                            //     bgcolor: theme.palette.primary.paper
                                                                                            // }}
                                                                                            chipcolor="secondary"
                                                                                        />
                                                                                    )}
                                                                                    {row.sender.role === 'Clinical Care Team' && (
                                                                                        <Chip
                                                                                            label={row.sender.role}
                                                                                            size="small"
                                                                                            sx={{
                                                                                                color: theme.palette.primary.main,
                                                                                                bgcolor: theme.palette.primary.paper
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </Typography>
                                                                            </ButtonBase>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={(e) => handleUserDetails(e, row)}
                                                                >
                                                                    <Box
                                                                        component="span"
                                                                        sx={{
                                                                            display: 'flex',
                                                                            width: {
                                                                                xs: 220,
                                                                                md: 350,
                                                                                lg: 600,
                                                                                xl: openMailSidebar ? 480 : 300
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant={row.isRead ? 'body1' : 'subtitle1'}
                                                                            sx={{
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                display: 'block'
                                                                            }}
                                                                        >
                                                                            {row.subject} - {row.message}
                                                                        </Typography>
                                                                        &nbsp;&nbsp;
                                                                        {/* <Chip
                                                                label={row.topic}
                                                                size="small"
                                                                sx={{
                                                                    color: theme.palette.primary.main,
                                                                    bgcolor: theme.palette.primary.paper
                                                                }}
                                                            /> */}
                                                                        {/* <Chip label={row.topic} disabled size="small" variant="outlined" /> */}
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.attach && (
                                                                        <IconButton size="small">
                                                                            <AttachmentTwoToneIcon fontSize="small" />
                                                                        </IconButton>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="center" sx={{ position: 'relative' }}>
                                                                    {format(new Date(row.time), 'd MMM yy HH:mm a')}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height: 53 * emptyRows
                                                            }}
                                                        >
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                            {/* <TablePagination
                                                sx={{ '& .MuiToolbar-root': { pl: 1 } }}
                                                rowsPerPageOptions={[]}
                                                component="div"
                                                count={length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            /> */}
                                        </TableContainer>
                                    </MainCard>
                                ) : (
                                    <MessageEmpty />
                                )}
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: 0 }}>
                                {data.length ? (
                                    <MainCard content={false} sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50' }}>
                                        {/* table */}
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                {/* <Tab label="Inbox" value="1" /> */}
                                                <Tab
                                                    label={
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <InboxIcon style={{ marginRight: '4px' }} />
                                                            Inbox
                                                            <Badge badgeContent={1} color="error" sx={{ marginLeft: '12px' }} />
                                                        </span>
                                                    }
                                                    value="1"
                                                />

                                                <Tab
                                                    icon={<SendIcon sx={{ paddingRight: '2px', marginBottom: '0 !important' }} />}
                                                    label="Send"
                                                    value="2"
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                />
                                                <Tab
                                                    icon={<DraftsIcon sx={{ paddingRight: '2px', marginBottom: '0 !important' }} />}
                                                    label="Draft"
                                                    value="3"
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                />
                                            </TabList>
                                            <Stack direction="row" alignItems="center" spacing={1.5} sx={{ padding: '0 10px' }}>
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon fontSize="small" />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={handleSearch}
                                                    value={search}
                                                    size="small"
                                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                                />
                                                <div style={{ width: '100%', maxWidth: '130px' }}>
                                                    <ComposeDialog />
                                                </div>
                                            </Stack>
                                        </div>
                                    </MainCard>
                                ) : (
                                    <MessageEmpty />
                                )}
                            </TabPanel>
                            <TabPanel value="3" sx={{ padding: 0 }}>
                                {data.length ? (
                                    <MainCard content={false} sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50' }}>
                                        {/* table */}
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                {/* <Tab label="Inbox" value="1" /> */}
                                                <Tab
                                                    label={
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <InboxIcon style={{ marginRight: '4px' }} />
                                                            Inbox
                                                            <Badge badgeContent={1} color="error" sx={{ marginLeft: '12px' }} />
                                                        </span>
                                                    }
                                                    value="1"
                                                />

                                                <Tab
                                                    icon={<SendIcon sx={{ paddingRight: '2px', marginBottom: '0 !important' }} />}
                                                    label="Send"
                                                    value="2"
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                />
                                                <Tab
                                                    icon={<DraftsIcon sx={{ paddingRight: '2px', marginBottom: '0 !important' }} />}
                                                    label="Draft"
                                                    value="3"
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                />
                                            </TabList>
                                            <Stack direction="row" alignItems="center" spacing={1.5} sx={{ padding: '0 10px' }}>
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon fontSize="small" />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={handleSearch}
                                                    value={search}
                                                    size="small"
                                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                                />
                                                <div style={{ width: '100%', maxWidth: '130px' }}>
                                                    <ComposeDialog />
                                                </div>
                                            </Stack>
                                        </div>

                                        <TableContainer sx={{ borderRadius: '0' }}>
                                            <Table
                                                size={denseTable ? 'small' : undefined}
                                                aria-labelledby="tableTitle"
                                                sx={{
                                                    minWidth: 320,
                                                    '& td': { whiteSpace: 'nowrap', px: 0.75, py: denseTable ? 0.5 : 1.25 }
                                                }}
                                            >
                                                <TableHead sx={{ backgroundColor: theme.palette.tableColor.tableHeaderBg }}>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                indeterminate={selected.length > 0 && selected.length < data.length}
                                                                checked={data.length > 0 && selected.length === data.length}
                                                                onChange={handleSelectAllClick}
                                                                inputProps={{
                                                                    'aria-label': 'select all'
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>Name</TableCell>

                                                        <TableCell>Subject</TableCell>
                                                        <TableCell>{}</TableCell>
                                                        <TableCell align="center">Date & Time</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {selected.length > 0 && <EnhancedTableHead selected={selected} />}
                                                <TableBody>
                                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                        const isItemSelected = isSelected(row.sender.name);
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                sx={{
                                                                    bgcolor: !row.isRead ? darkBG : '',
                                                                    '& td:last-of-type>div': {
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        right: 5,
                                                                        transform: 'translateY(-50%)',
                                                                        display: 'none',
                                                                        background:
                                                                            theme.palette.mode === 'dark'
                                                                                ? theme.palette.dark[800]
                                                                                : theme.palette.tableColor.tableRowBg,
                                                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                                                        borderRadius: '12px',
                                                                        py: 1,
                                                                        px: 1.75,
                                                                        '& button + button': {
                                                                            ml: 0.625
                                                                        }
                                                                    },
                                                                    '&:hover': {
                                                                        '& td:last-of-type>div': {
                                                                            display: 'block'
                                                                        }
                                                                    }
                                                                }}
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={index}
                                                                selected={isItemSelected}
                                                            >
                                                                <TableCell>
                                                                    <Checkbox
                                                                        checked={
                                                                            isItemSelected ||
                                                                            (data.length > 0 && selected.length === data.length)
                                                                        }
                                                                        // checked={isItemSelected}
                                                                        color="primary"
                                                                        onChange={(event) => handleClick(event, row.sender.name)}
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell
                                                                    id={labelId}
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={(e) => handleUserDetails(e, row)}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        alignItems="center"
                                                                        sx={{ flexWrap: 'nowrap' }}
                                                                    >
                                                                        <Grid item xs zeroMinWidth>
                                                                            <ButtonBase disableRipple>
                                                                                <Typography
                                                                                    align="left"
                                                                                    variant={row.isRead ? 'body1' : 'subtitle1'}
                                                                                    component="div"
                                                                                >
                                                                                    {row.sender.name}{' '}
                                                                                    {row.sender.role === 'Specialist' && (
                                                                                        <Chip
                                                                                            label={row.sender.role}
                                                                                            size="small"
                                                                                            // sx={{
                                                                                            //     color: theme.palette.primary.main,
                                                                                            //     bgcolor: theme.palette.primary.paper
                                                                                            // }}
                                                                                            chipcolor="secondary"
                                                                                        />
                                                                                    )}
                                                                                    {row.sender.role === 'Clinical Care Team' && (
                                                                                        <Chip
                                                                                            label={row.sender.role}
                                                                                            size="small"
                                                                                            sx={{
                                                                                                color: theme.palette.primary.main,
                                                                                                bgcolor: theme.palette.primary.paper
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </Typography>
                                                                            </ButtonBase>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={(e) => handleUserDetails(e, row)}
                                                                >
                                                                    <Box
                                                                        component="span"
                                                                        sx={{
                                                                            display: 'flex',
                                                                            width: {
                                                                                xs: 220,
                                                                                md: 350,
                                                                                lg: 600,
                                                                                xl: openMailSidebar ? 480 : 300
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant={row.isRead ? 'body1' : 'subtitle1'}
                                                                            sx={{
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                display: 'block'
                                                                            }}
                                                                        >
                                                                            {row.subject} - {row.message}
                                                                        </Typography>
                                                                        &nbsp;&nbsp;
                                                                        {/* <Chip
                                                             label={row.topic}
                                                             size="small"
                                                             sx={{
                                                                 color: theme.palette.primary.main,
                                                                 bgcolor: theme.palette.primary.paper
                                                             }}
                                                         /> */}
                                                                        {/* <Chip label={row.topic} disabled size="small" variant="outlined" /> */}
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.attach && (
                                                                        <IconButton size="small">
                                                                            <AttachmentTwoToneIcon fontSize="small" />
                                                                        </IconButton>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="center" sx={{ position: 'relative' }}>
                                                                    {format(new Date(row.time), 'd MMM yy HH:mm a')}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height: 53 * emptyRows
                                                            }}
                                                        >
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                            {/* <TablePagination
                                             sx={{ '& .MuiToolbar-root': { pl: 1 } }}
                                             rowsPerPageOptions={[]}
                                             component="div"
                                             count={length}
                                             rowsPerPage={rowsPerPage}
                                             page={page}
                                             onPageChange={handleChangePage}
                                             onRowsPerPageChange={handleChangeRowsPerPage}
                                         /> */}
                                        </TableContainer>
                                    </MainCard>
                                ) : (
                                    <MessageEmpty />
                                )}
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ pt: '0 !important', display: { xs: 'block', sm: 'none' } }}>
                    {/* table pagination */}
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <Grid xs={12}>
                    <Stack spacing={2} sx={{ display: 'flex', padding: '20px 0', width: '100%', alignItems: 'flex-end' }}>
                        <Pagination
                            count={10}
                            prevText="Previous"
                            nextIcon={<ArrowForwardIcon sx={{ color: 'blue' }} />}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor: '#0A76F1',
                                            color: 'white',
                                            boxShadow: '1px 6px 6px #d1d1d1'
                                        }
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

MessageList.propTypes = {
    data: PropTypes.array,
    handleDrawerOpen: PropTypes.func,
    handleUserDetails: PropTypes.func,
    handleStarredChange: PropTypes.func,
    handleImportantChange: PropTypes.func,
    handleSearch: PropTypes.func,
    search: PropTypes.string,
    openMailSidebar: PropTypes.bool
};

export default MessageList;
