import {
    useTheme,
    Grid,
    Typography,
    styled,
    Badge,
    Checkbox,
    FormControlLabel,
    TextField,
    MenuItem,
    Button,
    Stack,
    Link,
    Chip,
    Card,
    Tab,
    Tabs,
    Box
} from '@mui/material';
import { gridSpacing } from 'store/constant';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AddReferenceMaterialModalContent from '../../../plans/goals/referencematerial/AddReferenceMaterialModalContent';
import ReferenceMaterial from '../../../plans/goals/referencematerial/ReferenceMaterial';
import AddReferenceMaterial from '../../../plans/goals/referencematerial/AddReferenceMaterial';
import PreviewAndPublish from '../../common/previewandpublish/PreviewAndPublish';
import { display, flexbox } from '@mui/system';
import AddMonitoringTab from '../addmonitoring/AddMonitoringTab';

const Activitypage = ({ goals }) => {
    const theme = useTheme();
    const [date, setDate] = useState(null);
    const [cdate, setCDate] = useState(null);
    const [dietopen, setDietOpen] = useState(false);
    const [nexerciseopen, setNExerciseOpen] = useState(false);
    const [restopen, setRestOpen] = useState(false);
    const [attitudeopen, setAttitudeOpen] = useState(false);
    const [medicationopen, setMedicationOpen] = useState(false);
    const [addMonitoring, setAddMonitoring] = useState(false);
    const [referenceMaterial, setReferenceMaterial] = useState(false);
    const [addReferenceMaterial, setAddReferenceMaterial] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const collapseAll = () => {
        setDietOpen(false);
        setNExerciseOpen(false);
        setRestOpen(false);
        setAttitudeOpen(false);
        setMedicationOpen(false);
        setAddMonitoring(false);
        setReferenceMaterial(false);
        setAddReferenceMaterial(false);
    };
    const [unreadCounts, setUnreadCounts] = useState('1');
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important'
    };
    const SuccessBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.success.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.success.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));
    return (
        <>
            <Grid container spacing={gridSpacing} alignItems="center">
                <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important', pt: '8px !important', mb: '20px', mt: '10px' }}>
                    <Grid container alignItems="start" spacing={1} sx={cardStyle} display={flexbox} flexDirection="column">
                        <Typography variant="subTitle1">
                            <b>Goals to be Achieved: </b>
                        </Typography>
                        <Typography sx={{ width: '100%' }}>
                            <ul>
                                {goals.map((activity, idx) => (
                                    <li key={idx}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>{activity}</p>
                                            <Typography>
                                                <p>
                                                    <strong>Protein - 15%, Sodium - 20% </strong>{' '}
                                                </p>
                                            </Typography>
                                            <Typography>
                                                <p>
                                                    <strong>End Date</strong>: 05/22/2022{' '}
                                                </p>
                                            </Typography>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={gridSpacing} alignItems="center">
                <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important', pt: '8px !important' }}>
                    <Grid container alignItems="center" spacing={1} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="Add Activities">
                                    <Tab label="Add Activities" />
                                    <Tab label=" Add Monitoring" />
                                    <Tab label="Referece Materials" />
                                </Tabs>
                            </Box>
                            {value === 0 && (
                                <div>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Typography variant="subTitle1">
                                            <b>Add Activities:</b>
                                        </Typography>
                                        &nbsp;
                                        <Checkbox
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                collapseAll();
                                                setDietOpen(!dietopen);
                                            }}
                                        />
                                        <Link variant="button" underline="none" sx={{ cursor: 'pointer' }}>
                                            {/* <CustomizedBadge badgeContent={unreadCounts} color="error">
                                                            Diet
                                                        </CustomizedBadge> */}
                                            Diet
                                            {/* &nbsp;&nbsp;
                                <Chip color="error" size="small" label={unreadCounts} /> */}
                                        </Link>
                                        {/* <CustomizedBadge badgeContent={unreadCounts} color="error" /> */}
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <Checkbox
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                collapseAll();
                                                setRestOpen(!restopen);
                                            }}
                                        />
                                        <Link underline="none" sx={{ cursor: 'pointer' }}>
                                            Rest
                                        </Link>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <Checkbox
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                collapseAll();
                                                setNExerciseOpen(!nexerciseopen);
                                            }}
                                        />
                                        <Link underline="none" sx={{ cursor: 'pointer' }}>
                                            Exercise
                                        </Link>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <Checkbox
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                collapseAll();
                                                setAttitudeOpen(!attitudeopen);
                                            }}
                                        />
                                        <Link underline="none" sx={{ cursor: 'pointer' }}>
                                            Attitude
                                        </Link>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <Checkbox
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                collapseAll();
                                                setMedicationOpen(!medicationopen);
                                            }}
                                        />
                                        <Link underline="none" sx={{ cursor: 'pointer' }}>
                                            Medication
                                        </Link>
                                    </Grid>
                                </div>
                            )}
                            {value === 1 && (
                                <div>
                                    <Grid item container xs={12} lg={12} md={12}>
                                        <Grid item xs={8} lg={8} md={8}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <AnimateButton>
                                                    <Button
                                                        onClick={() => {
                                                            collapseAll();
                                                            setAddMonitoring(!addMonitoring);
                                                        }}
                                                        variant="text"
                                                    >
                                                        Add Monitoring
                                                    </Button>
                                                </AnimateButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            {value === 2 && (
                                <div>
                                    <Grid item container xs={12} lg={12} md={12}>
                                        <Grid item xs={8} lg={8} md={8}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <AnimateButton>
                                                    <Button
                                                        onClick={() => {
                                                            collapseAll();
                                                            setReferenceMaterial(!referenceMaterial);
                                                        }}
                                                        variant="text"
                                                    >
                                                        Reference Materials&nbsp;&nbsp;
                                                        <Chip color="error" size="small" label={2} />
                                                    </Button>
                                                </AnimateButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Grid>
                        {dietopen && (
                            <Grid item xs={12} lg={12} md={12}>
                                <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                    <Grid item container xs={12} lg={12} md={12}>
                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                            <Typography variant="h5">Diet & Nutrition</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="mealplanner" />} label="Meal Planner" />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="waterplanner" />} label="Water Planner" />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="monitorweight" />} label="Monitor Weight" />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="barriers" />}
                                                        label="Identify and Avoid Barriers"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}
                        {restopen && (
                            <Grid item xs={12} lg={12} md={12}>
                                <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                    <Grid item container xs={12} lg={12} md={12}>
                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                            <Typography variant="h5">Rest & Sleep</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="mealplanner" />}
                                                        label="Identify and avoid hindrance"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="waterplanner" />}
                                                        label="Set activities to prepare for effective sleep"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="monitorweight" />}
                                                        label="Monitor sleep rest"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}
                        {nexerciseopen && (
                            <Grid item xs={12} lg={12} md={12}>
                                <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                    <Grid item container xs={12} lg={12} md={12}>
                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                            <Typography variant="h5">Exercise & Activity</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="mealplanner" />} label="Single" />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="waterplanner" />} label="Group" />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="monitorweight" />} label="Community" />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="barriers" />}
                                                        label="Training under instructor"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="barriers" />}
                                                        label="Identify and avoid barriers"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}
                        {attitudeopen && (
                            <Grid item xs={12} lg={12} md={12}>
                                <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                    <Grid item container xs={12} lg={12} md={12}>
                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                            <Typography variant="h5">Attitude & Lifestyle</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="mealplanner" />}
                                                        label="Attend community stop smoking group"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="waterplanner" />}
                                                        label="Plan worry time: 10 minutes"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="monitorweight" />}
                                                        label="Engage in community activities for age group"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="barriers" />}
                                                        label="Read daily for atleast 30 minutes"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="barriers" />}
                                                        label="Talk to one son or daughter every evening"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="barriers" />}
                                                        label="Meet a friend in weekend"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="barriers" />}
                                                        label="Invovle in family activities and get togethers"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="barriers" />}
                                                        label="Read educational material received"
                                                    />
                                                </Grid>
                                                <Grid item container xs={12} lg={12} md={12} spacing={2} alignItems="center">
                                                    <Grid item xs={4} lg={4} md={4}>
                                                        <FormControlLabel
                                                            control={<Checkbox name="enjoy" />}
                                                            label="Do something enjoyable for atleast"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} lg={3} md={3} sx={{ pl: '0px !important' }}>
                                                        <TextField fullWidth select size="small" id="achieveweight" label="Enter Hours">
                                                            <MenuItem value="1">1</MenuItem>
                                                            <MenuItem value="2">2</MenuItem>
                                                            <MenuItem value="3">3</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={3} lg={3} md={3}>
                                                        <Typography variant="subTitle1">hours each week</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}
                        {medicationopen && (
                            <Grid item xs={12} lg={12} md={12}>
                                <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                    <Grid item container xs={12} lg={12} md={12}>
                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                            <Typography variant="h5">Medication</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Grid container sx={{ pl: 5 }} alignItems="center" spacing={1}>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="mealplanner" />}
                                                        label="Schedule medication time and take medication"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="waterplanner" />}
                                                        label="Document the medication"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="monitorweight" />} label="Attend therapy" />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="monitorweight" />}
                                                        label="Find out about community support for therapy"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="monitorweight" />}
                                                        label="Read educational material received for therapy details"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox name="monitorweight" />}
                                                        label="Medication refills on time"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel control={<Checkbox name="custom" />} label="Custom" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}
                    </Grid>

                    {referenceMaterial && (
                        <Grid item xs={12} lg={12} md={12}>
                            <Card sx={{ p: 2, pt: 0.5, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                <Grid item container xs={12} lg={12} md={12} sx={{ p: 1 }} alignItems="center">
                                    <Grid item xs={5} lg={5} md={5}>
                                        <Typography variant="h5">Reference Materials</Typography>
                                    </Grid>
                                    <Grid item xs={7} lg={7} md={7} align="right">
                                        <AddReferenceMaterial title="Weight Goal" />
                                    </Grid>
                                </Grid>
                                <ReferenceMaterial />
                            </Card>
                        </Grid>
                    )}
                    {addMonitoring && (
                        <Grid item xs={12} lg={12} md={12}>
                            <Card sx={{ p: 2, pt: 0.5, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                <Grid item container xs={12} lg={12} md={12}>
                                    <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                        <Typography variant="h5">Add Monitoring</Typography>
                                    </Grid>
                                </Grid>
                                <AddMonitoringTab />
                            </Card>
                        </Grid>
                    )}

                    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                        <Button size="small" variant="contained">
                            Save
                        </Button>
                        <PreviewAndPublish />
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

export default Activitypage;
