import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import CalorieGoalGraph from './charts/CalorieGoalGraph';
import WeightChart from '../../rpm/monitoredvalues/charts/WeightChart';

const ProgressTab = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const dropData = {
        title: 'By Month',
        options: [
            {
                value: 1,
                label: 'Weekly'
            }
        ]
    };
    let dropHtml;
    if (dropData) {
        const handleClick = (event) => {
            setAnchorEl(event?.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        dropHtml = (
            <>
                <Button
                    variant="text"
                    disableElevation
                    size="medium"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    endIcon={<ExpandCircleDownOutlinedIcon />}
                >
                    {dropData.title}
                </Button>
                <Menu
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {dropData?.options.map((option, index) => (
                        <MenuItem key={index} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }
    return (
        <Grid container spacing={gridSpacing} sx={{ mt: -2 }}>
            <Grid container item xs={12} lg={12} md={12} spacing={gridSpacing}>
                <Grid item xs={12} lg={12} md={12}>
                    <Typography variant="dashboardCardContent">
                        <b>Progress</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <MainCard
                        title={
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="h5">
                                        <b>Weight Progress</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="right">
                                    {dropHtml}
                                </Grid>
                            </Grid>
                        }
                        content={false}
                    >
                        {/* <Grid container justifyContent="flex-end" alignItems="right" sx={{ pl: 2, pr: 2, pt: 2 }}>
                {dropHtml}
            </Grid> */}
                        <WeightChart />
                    </MainCard>
                </Grid>
            </Grid>
            <Grid container item xs={12} lg={12} md={12} spacing={gridSpacing}>
                <Grid item xs={12} lg={12} md={12}>
                    <MainCard
                        title={
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="h5">
                                        <b>Calorie Progress</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="right">
                                    {dropHtml}
                                </Grid>
                            </Grid>
                        }
                        content={false}
                    >
                        {/* <Grid container justifyContent="flex-end" alignItems="right" sx={{ pl: 2, pr: 2, pt: 2 }}>
                {dropHtml}
            </Grid> */}
                        <CalorieGoalGraph />
                    </MainCard>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProgressTab;
