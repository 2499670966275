import { useTheme, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import SearchPatientsCard from './SearchPatientsCard';

const Patients = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container>
                        <Grid item xs={12} lg={12} md={12}>
                            {/* <MainContents /> */}
                            <SearchPatientsCard />
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} lg={3} md={3}>
                    <Grid container>
                        <Grid item xs={12} lg={12} md={12}>
                            <SidePanel />
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </>
    );
};

export default Patients;
