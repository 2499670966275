// material-ui
import { useTheme } from '@emotion/react';
import { Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import Chip from 'ui-component/extended/Chip';
import PropTypes from 'prop-types';

// table data
function createData(deviceName, ownedBy, lastSyned, badgeText, badgeType, isActive) {
    return {
        deviceName,
        ownedBy,
        lastSyned,
        badgeText,
        badgeType,
        isActive
    };
}

function Row({ row }) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    const renderStatusAction = (isActive) => {
        let obj = <></>;
        if (isActive) {
            obj = (
                <>
                    <Button
                        size="medium"
                        variant="outlined"
                        style={{
                            backgroundColor: 'white',
                            border: '2px solid',
                            color: '#2196F3',
                            borderColor: '##2196F3'
                        }}
                    >
                        Sync
                    </Button>
                </>
            );
        }
        return <>{obj}</>;
    };
    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.deviceName}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.ownedBy}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.lastSyned}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>
                        <Chip chipcolor={row.badgeType} label={row.badgeText} />
                    </b>
                </StyledTableCell>
                {/* <StyledTableCell>
                    <b>
                        {' '}
                        {row.isActive ? (
                            <Button
                                style={{
                                    backgroundColor: 'white',
                                    border: '2px solid black',
                                    color: '#FF0000',
                                    borderColor: '#FF0000'
                                }}
                                variant="contained"
                            >
                                <FormattedMessage id="Disconnect" />
                            </Button>
                        ) : (
                            <Button
                                style={{
                                    backgroundColor: 'White',
                                    color: '#04AA6D',
                                    border: '2px solid black',
                                    borderColor: '#04AA6D'
                                }}
                                variant="contained"
                            >
                                <FormattedMessage id="Reconnect" />
                            </Button>
                        )}
                    </b>
                </StyledTableCell>
                <StyledTableCell>{renderStatusAction(row.isActive)}</StyledTableCell> */}
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData('FitBit', 'Sutter Health', '05/01/2022, 10:30 AM', 'Connected', 'success', true),
    createData('Runkeeper', 'Robert Hailey', '05/01/2022, 10:00 AM', 'Disconnected', 'error', false)
];

export default function DevicesTable() {
    const theme = useTheme();
    const rowGrey = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        backgroundColor: theme.palette.common.grey,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    return (
        <Grid container spacing={gridSpacing}>
            {/* <Grid item xs={12} md={12} lg={12}>
                <Button size="small" variant="contained">
                    <FormattedMessage id="Add_New_Device" />
                </Button>
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
                <MainCard content={false}>
                    {/* table */}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        {' '}
                                        <FormattedMessage id="Device_Name" />
                                    </StyledTableCell>
                                    <StyledTableCell>Owned by</StyledTableCell>
                                    <StyledTableCell>
                                        <FormattedMessage id="Last_Synced" />
                                    </StyledTableCell>
                                    <StyledTableCell />
                                    {/* <StyledTableCell />
                                    <StyledTableCell /> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <Row key={index} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard>
            </Grid>
        </Grid>
    );
}
