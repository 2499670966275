import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled,
    tableCellClasses,
    Button,
    Typography,
    Grid,
    Badge,
    CardActions,
    Chip,
    useMediaQuery
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import { activeItem } from 'store/slices/menu';
import { useDispatch } from 'store';

// table data
function createData(status, plan, onTrack, offTrack) {
    return {
        status,
        plan,
        onTrack,
        offTrack
    };
}

function Row({ row, key }) {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableHeadBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: '0px transparent' } }}>
                <StyledTableCell component="th" scope="row">
                    <Chip
                        label={
                            <>
                                <b>Good</b>
                            </>
                        }
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.primary.light,
                            border: `1px solid ${theme.palette.success.dark}`
                        }}
                        color="success"
                        size="small"
                    />
                    {/* <FiberManualRecordIcon sx={{ color: 'success.main' }} /> */}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    <Chip
                        label={
                            <>
                                <b>Good</b>
                            </>
                        }
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.primary.light,
                            border: `1px solid ${theme.palette.success.dark}`
                        }}
                        color="success"
                        size="small"
                    />
                    {/* <FiberManualRecordIcon sx={{ color: 'success.main' }} /> */}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    <Chip
                        label={
                            <>
                                <b>Poor</b>
                            </>
                        }
                        sx={{
                            color: theme.palette.error.dark,
                            backgroundColor: theme.palette.primary.light,
                            border: `1px solid ${theme.palette.error.dark}`
                        }}
                        color="error"
                        size="small"
                    />
                    {/* <FiberManualRecordIcon sx={{ color: 'error.main' }} /> */}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    <Chip
                        label={
                            <>
                                <b>Good</b>
                            </>
                        }
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.primary.light,
                            border: `1px solid ${theme.palette.success.dark}`
                        }}
                        color="success"
                        size="small"
                    />
                    {/* <FiberManualRecordIcon sx={{ color: theme.palette.grey[500] }} /> */}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    <Chip
                        label={
                            <>
                                <b>Good</b>
                            </>
                        }
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.primary.light,
                            border: `1px solid ${theme.palette.success.dark}`
                        }}
                        color="success"
                        size="small"
                    />
                    {/* <FiberManualRecordIcon sx={{ color: 'success.main' }} /> */}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    <Chip
                        label={
                            <>
                                <b>Pending</b>
                            </>
                        }
                        sx={{
                            color: theme.palette.warning.pendingstatus,
                            backgroundColor: theme.palette.primary.light,
                            border: `1px solid ${theme.palette.warning.pendingstatus}`
                        }}
                        color="warning"
                        size="small"
                    />
                    {/* <FiberManualRecordIcon sx={{ color: theme.palette.grey[500] }} /> */}
                </StyledTableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData(
        '3',
        'Diet',
        'Meals Plan',
        <>
            Weight Goal: <br />
            Planned: 133 pounds, Current: 180 pounds
            <br />
            Duration Left: 4 Months
        </>
    )
];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function DreamsSummaryShort({ tabChange }) {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const gridStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2
    };

    const commonStyle = {
        fontSize: '16px',
        color: '#ffffff',
        width: '24px',
        height: '24px',
        textAlign: 'center',
        display: 'inline-block',
        boxShadow: 'rgb(0 0 0 / 18%) 0px 1rem 4rem',
        lineHeight: '24px',
        borderRadius: '50%',
        fontWeight: 'bold',
        marginRight: '1px'
    };
    const dietindicatorstyle = {
        ...commonStyle,
        backgroundColor: theme.palette.dreams.diet.primary
    };

    const restindicatorstyle = {
        ...commonStyle,
        backgroundColor: theme.palette.dreams.rest.primary
    };

    const exerciseindicatorstyle = {
        ...commonStyle,
        backgroundColor: theme.palette.dreams.exercise.primary
    };

    const medicationindicatorstyle = {
        ...commonStyle,
        backgroundColor: theme.palette.dreams.medication.primary
    };

    const specialistindicatorstyle = {
        ...commonStyle,
        backgroundColor: theme.palette.dreams.specialist.primary
    };

    const attitudeindicatorstyle = {
        ...commonStyle,
        backgroundColor: theme.palette.dreams.attitude.primary
    };

    const displayFlex = {
        display: 'flex'
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const ErrorBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.error.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.error.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    const handleClick = () => {
        navigate('/dreams');
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'dreams');
        if (currentIndex > -1) {
            dispatch(activeItem(['dreams']));
        }
    };

    return (
        <MainCard
            title={
                <Typography variant="h3" color="secondary">
                    <b>My DREAMS Summary</b>
                </Typography>
            }
            content={false}
        >
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <>
                                    <div style={displayFlex}>
                                        <span style={dietindicatorstyle}>D</span>
                                        <Typography variant="tabText" sx={{ color: theme.palette.dreams.diet.primary }}>
                                            iet
                                        </Typography>
                                    </div>
                                </>
                            </StyledTableCell>
                            <StyledTableCell>
                                <>
                                    <div style={displayFlex}>
                                        <span style={restindicatorstyle}>R</span>
                                        <Typography variant="tabText" sx={{ color: theme.palette.dreams.rest.primary }}>
                                            est
                                        </Typography>
                                    </div>
                                </>
                            </StyledTableCell>
                            <StyledTableCell>
                                <>
                                    <div style={displayFlex}>
                                        <span style={exerciseindicatorstyle}>E</span>
                                        <Typography variant="tabText" sx={{ color: theme.palette.dreams.exercise.primary }}>
                                            xercise
                                        </Typography>
                                    </div>
                                </>
                            </StyledTableCell>
                            <StyledTableCell>
                                <>
                                    <div style={displayFlex}>
                                        <span style={attitudeindicatorstyle}>A</span>
                                        <Typography variant="tabText" sx={{ color: theme.palette.dreams.attitude.primary }}>
                                            ttitude
                                        </Typography>
                                    </div>
                                </>
                            </StyledTableCell>
                            <StyledTableCell>
                                <>
                                    <div style={displayFlex}>
                                        <span style={medicationindicatorstyle}>M</span>
                                        <Typography variant="tabText" sx={{ color: theme.palette.dreams.medication.primary }}>
                                            edication
                                        </Typography>
                                    </div>
                                </>
                            </StyledTableCell>
                            <StyledTableCell>
                                <>
                                    <div style={displayFlex}>
                                        <span style={specialistindicatorstyle}>S</span>
                                        <Typography variant="tabText" sx={{ color: theme.palette.dreams.specialist.primary }}>
                                            pecialist
                                        </Typography>
                                    </div>
                                </>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={12} lg={12} md={12}>
                <CardActions sx={{ p: 1, justifyContent: 'flex-end', backgroundColor: theme.palette.primary.light }}>
                    <Button variant="text" size="small" onClick={handleClick}>
                        <b>View More</b>
                    </Button>
                </CardActions>
            </Grid>
        </MainCard>
    );
}
