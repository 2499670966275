import {
    useTheme,
    Grid,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Autocomplete,
    TextField,
    Button,
    IconButton,
    Stack
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import CareProgram from './tabcontent/careprogram/CareProgram';
import CustomCareProgram from './tabcontent/customcareprogram/CustomCareProgram';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Activitypage from './tabcontent/customcareprogram/Activitypage';

const DreamsPlanner = () => {
    const theme = useTheme();
    const [mode, setMode] = useState('2');
    const providers = [{ label: 'Diabetic Care', id: 1 }];
    const [provider, setProvider] = useState(providers[0]);
    const [goals, setGoals] = useState([]);
    const handleChange = (e) => {
        setProvider(e.target.value);
    };
    const [Activity, setActivity] = useState(false);
    const handleActivityClick = () => {
        setActivity(!Activity);
    };
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                    <Typography variant="dashboardCardContent">
                        <b>DREAMS Planner</b>
                    </Typography>
                </Grid>
                {/* <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="dashboardCardContent">Choose from the options below</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ pt: '16px !important' }}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-label="invite"
                            value={mode}
                            onChange={(e) => setMode(e.target.value)}
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Select a Care Program" />
                            <FormControlLabel value="2" control={<Radio />} label="Create a Custom Care Program" />
                        </RadioGroup>
                    </FormControl>
                </Grid> */}
                {mode === '1' && (
                    <Grid item xs={12} md={12} lg={12}>
                        <CareProgram />
                    </Grid>
                )}
                {mode === '2' && (
                    <>
                        {/* <Grid item xs={8} lg={8} md={8}>
                            <Autocomplete
                                disableClearable
                                options={providers}
                                defaultValue={provider}
                                onChange={handleChange}
                                size="small"
                                renderInput={(params) => <TextField {...params} label="Choose the Care Program" />}
                            />
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container spacing={0}>
                                {!Activity && (
                                    <>
                                        <Grid item xs={8} lg={8} md={8} sx={{ mb: '20px' }}>
                                            <Autocomplete
                                                disableClearable
                                                options={providers}
                                                defaultValue={provider}
                                                onChange={handleChange}
                                                size="small"
                                                renderInput={(params) => <TextField {...params} label="Choose the Care Program" />}
                                            />
                                        </Grid>
                                        <CustomCareProgram handleActivityClick={handleActivityClick} setGoals={setGoals} />
                                    </>
                                )}
                                {Activity && (
                                    <>
                                        <Grid container item xs={12} lg={12} md={12} spacing={2} alignItems="center">
                                            <Grid item xs={12} md={12} lg={12}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <IconButton onClick={handleActivityClick}>
                                                        <NavigateBeforeIcon />
                                                    </IconButton>
                                                    <Typography variant="dashboardCardContent">
                                                        <b>Add Activity</b>
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Activitypage goals={goals} />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

export default DreamsPlanner;
