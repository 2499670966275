import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';

// third-party
import { FormattedMessage } from 'react-intl';
import UpcomingAppointmentsCard from '../UpcomingAppointmentsCard ';
import AppointmentsCard from '../AppointmentsCard';
import AppointmentFilter from '../AppointmentFilter';

// tabs panel
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: (
            <>
                <Typography variant="tabText">
                    <FormattedMessage id="Upcoming_Appointments" />
                </Typography>
            </>
        )
    },
    {
        label: (
            <>
                <Typography variant="tabText">
                    <FormattedMessage id="Past_Appointments" />
                </Typography>
            </>
        )
    }
];

const AppointmentTypesTab = () => {
    const theme = useTheme();
    const filterCardStyle = {
        pt: '15px',
        mt: '10px',
        ml: '0px',
        mr: '0px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };
    // console.log(value1);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /* useEffect(() => {
        // value1 === null ? setValue(0) : setValue(value1);
        if (value1 === null) setValue(0);
        else setValue(value1);
    }, [value1]);
 */
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    sx={{
                        mb: 3,
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            mr: 0,
                            color: theme.palette.grey[600],
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& a.Mui-selected': {
                            color: theme.palette.primary.main
                        },
                        '& .MuiTabs-indicator': {
                            bottom: 2
                        },
                        '& a > svg': {
                            marginBottom: '0px !important',
                            mr: 1.25
                        }
                    }}
                >
                    {tabsOption.map((tab, index) => (
                        <Tab key={index} component={Link} to="#" label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>

                <TabPanel value={value} index={0}>
                    <Grid container spacing={gridSpacing}>
                        {/*  <Grid item xs={12}>
                            <AppointmentFilter cardStyle={filterCardStyle} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <UpcomingAppointmentsCard />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid container spacing={gridSpacing}>
                        {/*    <Grid item xs={12}>
                            <AppointmentFilter cardStyle={filterCardStyle} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <AppointmentsCard />
                        </Grid>
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default AppointmentTypesTab;
