import {
    useTheme,
    Grid,
    Typography,
    styled,
    Badge,
    Checkbox,
    FormControlLabel,
    TextField,
    MenuItem,
    Button,
    Stack,
    IconButton
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { flexbox } from '@mui/system';
import Tcmactivityview from './TCMActivityView';
import Createnewtcm from './Createnewtcm';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const TCMActivityContent = () => {
    const theme = useTheme();
    const [date, setDate] = useState(null);
    const [cdate, setCDate] = useState(null);

    const [showCreateNewTCM, setShowCreateNewTCM] = useState(false);
    const handleCreateNewTCMClick = () => {
        setShowCreateNewTCM(!showCreateNewTCM);
    };
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important'
    };
    const SuccessBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.success.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.success.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));
    return (
        <>
            <Grid container spacing={gridSpacing} alignItems="center">
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} lg={12} md={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="dashboardCardContent">
                                <b>TCM Activity</b>
                            </Typography>
                            <Button size="small" variant="contained" onClick={handleCreateNewTCMClick}>
                                Create New TCM
                            </Button>
                        </Grid>

                        {/* <Grid item xs={1} lg={1} md={1} align="left">
                            <SuccessBadge badgeContent="In Progress" />
                        </Grid> */}
                        {/* <Grid item xs={8} lg={8} md={8}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{
                                                color: theme.palette.error.main,
                                                '&.Mui-checked': {
                                                    color: theme.palette.error.main
                                                }
                                            }}
                                        />
                                    }
                                    label="TCM Discontinued"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{
                                                color: theme.palette.success.dark,
                                                '&.Mui-checked': {
                                                    color: theme.palette.success.main
                                                }
                                            }}
                                        />
                                    }
                                    label="TCM Completed"
                                />
                            </Stack>
                        </Grid> */}
                        {/* <Grid item xs={4} lg={4} md={4} align="left">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{
                                            color: theme.palette.success.dark,
                                            '&.Mui-checked': {
                                                color: theme.palette.success.main
                                            }
                                        }}
                                    />
                                }
                                label="TCM Completed"
                            />
                        </Grid> */}
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} lg={12} md={12}>
                    <Typography variant="h5">
                        Services provided by clinical staff, under the direction of the physician or other qualified health care
                        professionals
                    </Typography>
                </Grid> */}

                {!showCreateNewTCM && <Tcmactivityview />}

                {showCreateNewTCM && (
                    <>
                        <Grid container item xs={12} lg={12} md={12} spacing={2} alignItems="center">
                            <Grid item xs={12} md={12} lg={12}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <IconButton onClick={handleCreateNewTCMClick}>
                                        <NavigateBeforeIcon />
                                    </IconButton>
                                    <Typography variant="dashboardCardContent">
                                        <b>Create New TCM</b>
                                    </Typography>
                                </Stack>
                                <Createnewtcm />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

export default TCMActivityContent;
