import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    styled,
    tableCellClasses,
    Button,
    MenuItem,
    Menu,
    Typography,
    Grid,
    Badge
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { useState } from 'react';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EmailIcon from '@mui/icons-material/Email';
import Chip from 'ui-component/extended/Chip';
import { border, borderRadius, Box, fontWeight, padding } from '@mui/system';

// table data
// function createData(status, plan, onTrack, offTrack) {
//     return {
//         status,
//         plan,
//         onTrack,
//         offTrack
//     };
// }
// table data
function createData(diet, rest, exercise, attitude, medication, specialist) {
    return {
        diet,
        rest,
        exercise,
        attitude,
        medication,
        specialist
    };
}
// percentage
const CustomizedBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        border: `1px solid ${theme.palette.background.paper}`,
        // padding: '0 4px',
        minHeight: '35px',
        minWidth: '35px',
        borderRadius: '50px',
        color: theme.palette.background.paper
    }
}));

const CustomizedGreyBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        border: `1px solid ${theme.palette.background.paper}`,
        // padding: '0 4px',
        minHeight: '35px',
        minWidth: '35px',
        borderRadius: '50px',
        color: theme.palette.background.paper
    }
}));

const scoreBadge = (score) => {
    let obj = <></>;
    if (score < 0) {
        obj = <CustomizedGreyBadge badgeContent=" " />;
    } else if (score < 50) {
        obj = <CustomizedBadge badgeContent={<>{score}%</>} color="error" />;
    } else if (score >= 50) {
        obj = <CustomizedBadge badgeContent={<>{score}%</>} color="success" />;
    }
    return obj;
};
function Row({ row, key }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const cardStyle = {
        pt: '20px',
        pl: '15px',
        pb: '15px',
        backgroundColor: theme.palette.tableColor.tableExpandBg
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const [problem, setProblem] = useState('Diabetes Mellitus');

    const problems = [
        { label: 'Diabetes Mellitus', id: 1 },
        { label: 'Diabetes insipidus', id: 2 },
        { label: 'Necrobiosis lipoidica diabeticorum', id: 3 }
    ];

    const handleChange = (e) => {
        setProblem(e.target.value);
    };

    let status = <></>;
    if (row.status === '1') {
        status = 'success.main';
    } else if (row.status === '2') {
        status = 'error.main';
    } else if (row.status === '3') {
        status = 'warning.main';
    } else if (row.status === '4') {
        status = theme.palette.grey[500];
    }

    let cellStyle = <></>;
    if (row.status === '1') {
        cellStyle = theme.palette.success.main;
    } else if (row.status === '3') {
        cellStyle = theme.palette.success.main;
    } else if (row.status === '4') {
        cellStyle = theme.palette.grey[500];
    }

    const [endDate, setEndDate] = useState('05/10/2021');

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: '0px transparent' } }}>
                <StyledTableCell component="th" scope="row" align="center">
                    {scoreBadge(row.diet)}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                    {scoreBadge(row.rest)}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                    {scoreBadge(row.exercise)}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                    {scoreBadge(row.attitude)}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                    {scoreBadge(row.medication)}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                    {scoreBadge(row.specialist)}
                </StyledTableCell>
            </TableRow>
        </>
    );
}

// Row.propTypes = {
//     row: PropTypes.object
// };

// const rows = [
//     createData(
//         '3',
//         'Diet',
//         'Meals Plan',
//         <>
//             Weight Goal: <br />
//             Planned: 133 pounds, Current: 180 pounds
//             <br />
//             Duration Left: 4 Months
//         </>
//     )
// ];

Row.propTypes = {
    row: PropTypes.object
};

const rows = [createData(15, 15, 15, 15, 55, -1)];
// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function DreamsSummaryShort({ tabChange }) {
    const theme = useTheme();
    const gridStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2
    };

    const dreamsindicatorstyle = {
        fontSize: '16px',
        color: '#ffffff',
        width: '24px',
        height: '24px',
        textAlign: 'center',
        display: 'inline-block',
        boxShadow: 'rgb(0 0 0 / 18%) 0px 1rem 4rem',
        lineHeight: '24px',
        borderRadius: '50%',
        fontWeight: 'bold',
        backgroundColor: '#545353',
        marginRight: '1px'
    };

    const patients = {
        fontSize: '12px',
        color: 'rgb(85 6 0)',
        borderRadius: '100px',
        fontWeight: 'bold',
        background: 'rgb(243 200 200)',
        padding: '10px',
        border: '2px solid rgb(244, 67, 54)',
        marginBottom: '4px',
        display: 'inline-block'
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const ErrorBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.error.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.error.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={8} lg={8} md={8}>
                <Stack direction="row" alignItems="center" spacing={8}>
                    <Typography variant="dashboardCardContent">
                        <b>DREAMS Activity Progress</b>
                    </Typography>
                    <ErrorBadge badgeContent="Off Track" />
                    {/* </Grid>
                <Grid item xs={3} lg={3} md={3} align="left"> */}
                    <Button
                        variant="text"
                        onClick={() => {
                            tabChange(0, 1);
                        }}
                    >
                        View Details
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sx={{ p: '8px !important', pr: '0px !important' }}>
                <MainCard content={false}>
                    {/* table */}
                    <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">
                                        <>
                                            <span className="dreamsIndicator" style={dreamsindicatorstyle}>
                                                D
                                            </span>
                                            <Typography variant="tabText">iet</Typography>
                                        </>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <>
                                            <span className="dreamsIndicator" style={dreamsindicatorstyle}>
                                                R
                                            </span>
                                            <Typography variant="tabText">est</Typography>
                                        </>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <>
                                            <span className="dreamsIndicator" style={dreamsindicatorstyle}>
                                                E
                                            </span>
                                            <Typography variant="tabText">xercise</Typography>
                                        </>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <>
                                            <span className="dreamsIndicator" style={dreamsindicatorstyle}>
                                                A
                                            </span>
                                            <Typography variant="tabText">ttitude</Typography>
                                        </>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <>
                                            <span className="dreamsIndicator" style={dreamsindicatorstyle}>
                                                M
                                            </span>
                                            <Typography variant="tabText">edication</Typography>
                                        </>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <>
                                            <span className="dreamsIndicator" style={dreamsindicatorstyle}>
                                                S
                                            </span>
                                            <Typography variant="tabText">pecialist</Typography>
                                        </>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <Row key={index} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard>
            </Grid>
        </Grid>
    );
}
