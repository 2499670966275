// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconDashboard,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers
} from '@tabler/icons';

import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import TaskIcon from '@mui/icons-material/Task';
import BeenhereIcon from '@mui/icons-material/Beenhere';

// constant
const icons = {
    IconBrandChrome,
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers,
    SpeedOutlinedIcon,
    ChatIcon,
    SettingsIcon,
    HelpIcon,
    TaskIcon,
    FamilyRestroomIcon,
    BeenhereIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const ProxyMenuItems = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/proxydashboard',
            icon: icons.SpeedOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'proxytasks',
            title: 'Tasks',
            type: 'item',
            url: '/proxytasks',
            icon: icons.TaskIcon,
            breadcrumbs: false
        },
        {
            id: 'messages',
            title: <FormattedMessage id="Messages" />,
            type: 'item',
            url: '/proxymessages',
            icon: icons.ChatIcon,
            breadcrumbs: false
        },
        {
            id: 'Family',
            title: <FormattedMessage id="Family" />,
            type: 'item',
            url: '/proxyfamily',
            icon: icons.FamilyRestroomIcon,
            breadcrumbs: false
        },
        {
            id: 'download-health-records',
            title: <FormattedMessage id="Download_Health_Records" />,
            type: 'item',
            url: '/proxydownloadhealthrecords',
            icon: icons.BeenhereIcon,
            breadcrumbs: false
        },
        {
            id: 'account-settings',
            title: <FormattedMessage id="Account_Settings" />,
            type: 'item',
            url: '/proxyaccountsettings',
            icon: icons.SettingsIcon,
            breadcrumbs: false
        },
        {
            id: 'how-it-works',
            title: <FormattedMessage id="how_it_works" />,
            type: 'item',
            url: '/proxyhowitworks',
            icon: icons.HelpIcon,
            breadcrumbs: false
        }
    ]
};

export default ProxyMenuItems;
