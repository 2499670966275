import { useTheme, Card, CardContent, Typography, Grid, IconButton, styled, Badge, Stack, Button, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { gridSpacing } from 'store/constant';

const RPMSummaryCardShort = ({ tabChange }) => {
    const theme = useTheme();
    const gridStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2
    };
    const cardStyle = {
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        borderRadius: 2,
        border: `1px solid ${theme.palette.common.grey}`
    };
    const ErrorBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.error.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.error.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));
    return (
        <>
            <Grid container spacing={1} alignItems="center">
                {/* <Grid item xs={4} lg={4} md={4}>
                    <Typography variant="dashboardCardContent">RPM Summary</Typography>
                </Grid> */}
                <Grid item xs={7} lg={7} md={7}>
                    <Stack direction="row" alignItems="center" spacing={6}>
                        <Typography variant="dashboardCardContent">
                            <b>RPM Progress</b>
                        </Typography>
                        <ErrorBadge badgeContent="Off Track" />
                        {/* </Grid>
                    <Grid item xs={3} lg={3} md={3} align="left"> */}
                        <Button
                            variant="text"
                            onClick={() => {
                                tabChange(0, 3);
                            }}
                        >
                            View Details
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={{ p: '8px !important', pr: '0px !important' }}>
                    <Card sx={cardStyle}>
                        <CardContent>
                            <Grid container alignItems="center">
                                <Grid item container xs={12} lg={12} md={12} spacing={2}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Typography variant="h5">
                                            Patient has not read the BP for 06/12/2022, 06/10/2022 and the last reading on 06/09/2022 is
                                            155/84, above marked value.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default RPMSummaryCardShort;
