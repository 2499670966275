import { useTheme, Grid, Typography, Button, TextareaAutosize, TextField, Autocomplete, Stack, MenuItem } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { FormattedMessage } from 'react-intl';
import Goalshistorytable from './GoalhistoryTable';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { activeItem } from 'store/slices/menu';
import { useDispatch } from 'store';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Goalhistory = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClickactivity = () => {
        navigate('/healthrecords');
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'healthrecords');
        if (currentIndex > -1) {
            dispatch(activeItem(['healthrecords']));
        }
    };

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <Stack sx={{ background: '#FAFAFA', p: 2, borderRadius: '8px', mb: 2, border: '1px solid #EEEEEE' }}>
                        <div role="presentation">
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<NavigateNextIcon fontSize="small" />}
                                sx={{ fontWeight: 'normal' }}
                            >
                                <Link
                                    underline="none"
                                    onClick={handleClickactivity}
                                    sx={{ color: theme.palette.goaldreamsbkg.goalhistorybreadcrumb, cursor: 'pointer' }}
                                >
                                    Health Records
                                </Link>
                                <Link underline="none" sx={{ color: theme.palette.goaldreamsbkg.headingwrapper }}>
                                    Goal History
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h3" sx={{ color: theme.palette.goaldreamsbkg.headingwrapper }}>
                            <FormattedMessage id="Goal History" />
                        </Typography>
                        {/* </Grid>
                    <Grid item xs={3} md={3} lg={3} align="left"> */}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Goalshistorytable />
                </Grid>
            </Grid>
        </>
    );
};

export default Goalhistory;
