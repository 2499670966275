import { Grid, Typography, CardActions, CardContent, Button, Link, Card, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Avatar from 'ui-component/extended/Avatar';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';

// assets
import ClockIcon from '@mui/icons-material/AccessTime';
import { useState } from 'react';
import ActivitySearch from './ActivitySearch';

const RecentActivityTab = () => {
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const paper = {
        p: 2,
        boxShadow: 'none',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px dashed',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.dark
    };
    const [activityToggle, setActivityToggle] = useState('recent');
    const handleActivityToggle = (e) => {
        setActivityToggle(e.target.value);
    };
    const recentActivities = [
        { task: 'Follow Up visits arranged', date: ' 05/25/2022', time: '11:01 AM' },
        { task: 'Patient has viewed education material', date: ' 05/25/2022', time: '6:26 AM' },
        { task: 'Education materials sent', date: ' 05/24/2022', time: '6:26 AM' },
        { task: 'Interactive Contact with patient done', date: ' 05/22/2022', time: '11:01 AM' },
        { task: 'Updated Meal Plan', date: ' 05/18/2022', time: '6:26 AM' }
    ];

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={9} lg={9} md={9} sx={{ mt: 2 }}>
                            <Typography variant="dashboardCardContent">
                                <b> Recent Activity</b>
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={3} lg={3} md={3} sx={{ mt: 2 }} align="right">
                            <ToggleButtonGroup
                                aria-label="text alignment"
                                sx={{ outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px' }}
                                color="primary"
                                size="small"
                                value={activityToggle}
                                exclusive
                                onChange={handleActivityToggle}
                            >
                                <ToggleButton value="recent" aria-label="left aligned">
                                    Recent
                                </ToggleButton>
                                <ToggleButton value="upcoming" aria-label="right aligned">
                                    Upcoming
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid> */}
                    </Grid>
                </Grid>

                <>
                    <Grid item xs={12} lg={12} md={12}>
                        <ActivitySearch title="Search Recent Activities" />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <Card sx={cardStyle}>
                            <CardContent sx={{ p: '16px !important' }}>
                                <div style={{ padding: 35 }}>
                                    <Grid
                                        container
                                        spacing={gridSpacing}
                                        alignItems="center"
                                        sx={{
                                            position: 'relative',
                                            '&>*': {
                                                position: 'relative',
                                                zIndex: '5'
                                            },
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                top: '0',
                                                left: 130,
                                                width: 2,
                                                height: '100%',
                                                background: '#ebebeb',
                                                zIndex: '1'
                                            }
                                        }}
                                    >
                                        {recentActivities.map((item, index) => (
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs zeroMinWidth>
                                                                <Typography align="left" variant="body1">
                                                                    {item.date}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Avatar color="info">
                                                                    <ClockIcon />
                                                                </Avatar>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs zeroMinWidth>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    component="div"
                                                                    align="left"
                                                                    variant="dashboardCardContent"
                                                                    sx={{ color: theme.palette.black.black }}
                                                                >
                                                                    {item.task}
                                                                </Typography>
                                                                <Typography component="div" align="left" variant="body1">
                                                                    {item.time}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container item xs={12} lg={12} md={12} sx={{ mt: 1 }}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Button variant="text" size="small">
                                                View more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                            {/* <CardActions sx={{ justifyContent: 'flex-end' }}>
                                    <Button variant="text" size="small">
                                        View more
                                    </Button>
                                </CardActions> */}
                        </Card>
                    </Grid>
                </>
            </Grid>
        </>
    );
};

export default RecentActivityTab;
