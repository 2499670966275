import {
    Grid,
    styled,
    useTheme,
    FormControlLabel,
    Checkbox,
    Typography,
    TextField,
    MenuItem,
    Button,
    Link,
    Stack,
    Badge,
    IconButton
} from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useState } from 'react';
import Chip from 'ui-component/extended/Chip';
import AnimateButton from 'ui-component/extended/AnimateButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Viewtcm from './Viewtcm';

const Tcmactivityview = () => {
    const theme = useTheme();
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important',
        ml: '24px !important'
    };
    const SuccessBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.success.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            right: -50,
            border: `1px solid ${theme.palette.success.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));
    const [viewTCMFlag, setviewTCMFlag] = useState(false);
    const [showActivityview, setActivityview] = useState(false);
    const handleShowactivityclick = () => {
        setActivityview(!showActivityview);
    };
    const onboardingClickHandle = () => {
        setviewTCMFlag(!viewTCMFlag);
    };
    return (
        <>
            <Grid container>
                <Grid container item xs={12} lg={12} md={12} sx={cardStyle} spacing={1}>
                    <Grid container item xs={12} lg={12} md={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={7} lg={7} md={7}>
                            <Typography variant="h4">
                                TCM Started on <b>22/05/2023</b>
                                <SuccessBadge badgeContent="In Progress" />
                            </Typography>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <AnimateButton>
                                <Link to onClick={onboardingClickHandle} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                    View TCM Activities
                                </Link>
                            </AnimateButton>
                        </Grid>
                        <Grid
                            Container
                            item
                            xs={12}
                            lg={12}
                            md={12}
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                        >
                            <Grid item xs={12} lg={12} md={12}>
                                <Stack direction="row" justifyContent="flex-start" alignItems="right">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{
                                                    color: theme.palette.error.main,
                                                    '&.Mui-checked': {
                                                        color: theme.palette.error.main
                                                    }
                                                }}
                                            />
                                        }
                                        label="TCM Discontinued"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{
                                                    color: theme.palette.success.dark,
                                                    '&.Mui-checked': {
                                                        color: theme.palette.success.main
                                                    }
                                                }}
                                            />
                                        }
                                        label="TCM Completed"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {viewTCMFlag && (
                <Grid container item xs={12} lg={12} md={12}>
                    <Grid item xs={12} lg={12} md={12} sx={{ pt: '8px !important', ml: '10px !important' }}>
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            View TCM Activities
                        </Typography>
                    </Grid>
                    <Viewtcm />
                </Grid>
            )}
        </>
    );
};

export default Tcmactivityview;
