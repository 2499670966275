import { Grid, IconButton, useTheme, Typography, Divider, Card, CardContent, TextField, MenuItem, Stack, Button } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

const Education = () => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    const subCardStyle = {
        ml: '20px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px solid',
        borderRadius: 2,
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card sx={cardStyle}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={11} md={11} lg={11}>
                                    <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                        <Typography variant="h5">Education</Typography>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1} align="right">
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        <ExpandCircleDownOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                                {!open && (
                                    <>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Education level - Never attended/kindergarten only</Typography>
                                        </Grid>
                                    </>
                                )}
                                {open && (
                                    <>
                                        <Grid item xs={12} lg={12} md={12} sx={subCardStyle}>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        What is the highest grade or level of school you have completed or the highest
                                                        degree you have received?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <TextField fullWidth label="Qualification" defaultValue="1" select size="small">
                                                        <MenuItem value="1">Never attended/kindergarten only</MenuItem>
                                                        <MenuItem value="2">1st grade</MenuItem>
                                                        <MenuItem value="3">Under Gradguate</MenuItem>
                                                        <MenuItem value="4">Higher Education</MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Stack direction="row" spacing={2}>
                                                <Button variant="contained" onClick={() => setOpen(!open)}>
                                                    Update
                                                </Button>

                                                <Button variant="outlined" onClick={() => setOpen(!open)}>
                                                    Close
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default Education;
