import { Grid, useTheme } from '@mui/material';

const CycleMonitoring = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container>
                <Grid item xs={12} lg={12} md={12}>
                    Monitoring
                </Grid>
            </Grid>
        </>
    );
};

export default CycleMonitoring;
