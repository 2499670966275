import { useTheme, Grid, Typography, styled, Badge, Stack, Button, IconButton, Tooltip, Divider } from '@mui/material';
import RPMSummaryCardShort from './components/RPMSummaryCardShort';
import { gridSpacing } from 'store/constant';
import Timeline from '../../../timeline';
import DreamsSummaryShort from './components/dreamssummary/DreamsSummaryShort';
import EmailIcon from '@mui/icons-material/Email';

const SummaryTab = ({ tabChange }) => {
    const theme = useTheme();
    const timelineCardStyle = {
        // backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        // ml: '25px',
        mt: '10px',
        px: '5px !important',
        pt: '0px !important',
        pb: '16px'
    };
    const ErrorBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.error.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.error.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));
    return (
        <>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} lg={12} md={12}>
                    <DreamsSummaryShort tabChange={tabChange} />
                </Grid>
                <Grid item container xs={12} lg={12} md={12} alignItems="center">
                    <Stack direction="row" alignItems="center" spacing={6}>
                        <Typography variant="dashboardCardContent">
                            <b>TCM Progress</b>
                        </Typography>
                        <ErrorBadge badgeContent="Off Track" />
                        <Button
                            variant="text"
                            onClick={() => {
                                tabChange(0, 2);
                            }}
                        >
                            View Details
                        </Button>
                    </Stack>
                    <Grid item xs={12} lg={12} md={12} sx={timelineCardStyle}>
                        <Timeline />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    {/* <Divider sx={{ borderBottomWidth: 3 }} /> */}
                </Grid>
                {/* <Grid item xs={12} lg={12} md={12}>
                    <MonitoredValues />
                </Grid> */}
                {/* <Grid item xs={12} lg={12} md={12}>
                    <DreamsSummaryTable />
                </Grid> */}
                <Grid item xs={12} lg={12} md={12}>
                    <RPMSummaryCardShort tabChange={tabChange} />
                </Grid>
                {/* <Grid item xs={12} lg={12} md={12}>
                    <Divider />
                </Grid> */}
            </Grid>
        </>
    );
};

export default SummaryTab;
