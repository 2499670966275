import {
    useTheme,
    Grid,
    Typography,
    Switch,
    FormControlLabel,
    Button,
    Alert,
    ToggleButtonGroup,
    ToggleButton,
    Stack,
    Menu,
    MenuItem
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ChartContainer from './charts/ChartContainer';
import ListContainer from './lists/ListContainer';
import Filter from './Filter';
import MonitoredValuesTable from '../../summary/components/monitoredvalues/MonitoredValuesTable';
import BloodPressureChart from './charts/BloodPressureChart';
import WeightChart from './charts/WeightChart';

const MonitoredValuesTab = () => {
    const theme = useTheme();
    const [view, setView] = useState('list');
    const [monitoredValue, setMonitoredValue] = useState({
        value: 1,
        label: 'Blood Pressure'
    });

    const filterCardStyle = {
        pt: '15px',
        mt: '10px',
        pb: '15px',
        pr: '15px',
        pl: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const weight = {
        title: ['Date', 'Weight (lbs)', 'Source'],
        contents: [
            ['06/01/2022', '240', 'Robert Hailey'],
            ['06/02/2022', '210', 'Robert Hailey'],
            ['06/03/2022', '189', 'Robert Hailey'],
            ['06/04/2022', '188', 'Robert Hailey'],
            ['06/05/2022', '192', 'Robert Hailey'],
            ['06/06/2022', '190', 'Robert Hailey'],
            ['06/07/2022', '184', 'Robert Hailey']
        ]
    };
    const bloodPressure = {
        title: ['Date', 'Systolic', 'Diastolic', 'Source'],
        contents: [
            ['06/01/2022', '141', '76', 'Sutter Health'],
            ['06/01/2022', '141', '76', 'Sutter Health'],
            ['06/01/2022', '141', '76', 'Sutter Health'],
            ['06/01/2022', '141', '76', 'Sutter Health'],
            ['06/01/2022', '141', '76', 'Sutter Health'],
            ['06/01/2022', '141', '76', 'Sutter Health'],
            ['06/01/2022', '141', '76', 'Sutter Health']
        ]
    };

    // const [anchorEl, setAnchorEl] = useState(null);
    // const dropData = {
    //     title: 'Blood Pressure',
    //     options: [
    //         {
    //             value: 1,
    //             label: 'Blood Pressure'
    //         },
    //         {
    //             value: 2,
    //             label: 'Weight'
    //         }
    //     ]
    // };
    // let dropHtml;
    // if (dropData) {
    //     const handleClick = (event) => {
    //         setAnchorEl(event?.currentTarget);
    //     };

    //     const handleClose = (e) => {
    //         setAnchorEl(null);
    //         setMonitoredValue(e);
    //     };

    //     dropHtml = (
    //         <>
    //             <Button
    //                 variant="text"
    //                 disableElevation
    //                 size="medium"
    //                 aria-controls="simple-menu"
    //                 aria-haspopup="true"
    //                 onClick={handleClick}
    //                 endIcon={<ExpandCircleDownOutlinedIcon />}
    //             >
    //                 {monitoredValue.label}
    //             </Button>
    //             <Menu
    //                 anchorOrigin={{
    //                     vertical: 'bottom',
    //                     horizontal: 'right'
    //                 }}
    //                 transformOrigin={{
    //                     vertical: 'top',
    //                     horizontal: 'right'
    //                 }}
    //                 id="simple-menu"
    //                 anchorEl={anchorEl}
    //                 keepMounted
    //                 open={Boolean(anchorEl)}
    //                 // onClose={handleClose}
    //             >
    //                 {dropData?.options.map((option, index) => (
    //                     <MenuItem
    //                         key={index}
    //                         onClick={() => {
    //                             handleClose(option);
    //                         }}
    //                     >
    //                         {option.label}
    //                     </MenuItem>
    //                 ))}
    //             </Menu>
    //         </>
    //     );
    // }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item container xs={12} lg={12} md={12} alignItems="center" sx={{ mt: 2 }}>
                    <Grid item xs={8} lg={8} md={8}>
                        <Alert variant="filled" severity="error">
                            Patient has not read the BP for 06/12/2022, 06/10/2022 and the last reading on 06/09/2022 is 155/84, above
                            marked value.
                        </Alert>
                    </Grid>
                    <Grid item xs={4} lg={4} md={4} align="right">
                        <FormControlLabel
                            value="end"
                            control={<Switch defaultChecked color="primary" />}
                            label="RPM In Progress"
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12}>
                    <Filter cardStyle={filterCardStyle} />
                </Grid>
                <Grid container item xs={12} lg={12} md={12} spacing={gridSpacing} alignItems="center">
                    <Grid item xs={5} lg={5} md={5}>
                        <Typography variant="dashboardCardContent">
                            <b>RPM Monitored Values</b>
                        </Typography>
                    </Grid>
                    <Grid item sx={7} lg={7} md={7} align="right">
                        <ToggleButtonGroup
                            aria-label="text alignment"
                            sx={{
                                outlineColor: theme.palette.primary.main,
                                outlineWidth: '1px',
                                outlineStyle: 'solid',
                                margin: '2px'
                            }}
                            color="primary"
                            size="small"
                            value={view}
                            exclusive
                            // onChange={handleChange}
                        >
                            <ToggleButton
                                onClick={() => {
                                    setView('list');
                                }}
                                value="list"
                                aria-label="left aligned"
                            >
                                List View
                            </ToggleButton>
                            <ToggleButton
                                onClick={() => {
                                    setView('graph');
                                }}
                                value="graph"
                                aria-label="right aligned"
                            >
                                Graph View
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    {view === 'list' && (
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid item container xs={12} lg={12} md={12} spacing={2}>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Typography variant="h5">Blood Pressure</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <MonitoredValuesTable data={bloodPressure} />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Typography variant="h5">Weight</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <MonitoredValuesTable data={weight} />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {view === 'graph' && (
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid item container xs={12} lg={12} md={12} spacing={2}>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Typography variant="h5">Blood Pressure</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <BloodPressureChart />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Typography variant="h5">Weight</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <WeightChart />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {/*
                    HEALTH RECORDS TABULAR STRUCTURE
                <Grid item container xs={12} lg={12} md={12} alignItems="center">
                    <Grid item xs={3.5} lg={3.5} md={3.5}>
                        <Typography variant="h3">Monitored Values for</Typography>
                    </Grid>
                    <Grid item xs={3} lg={3} md={3}>
                        {dropHtml}
                    </Grid>
                    <Grid item sx={5.5} lg={5.5} md={5.5} align="right">
                        <ToggleButtonGroup
                            aria-label="text alignment"
                            sx={{
                                outlineColor: theme.palette.primary.main,
                                outlineWidth: '1px',
                                outlineStyle: 'solid',
                                margin: '2px'
                            }}
                            color="primary"
                            size="small"
                            value={view}
                            exclusive
                            // onChange={handleChange}
                        >
                            <ToggleButton
                                onClick={() => {
                                    setView('list');
                                }}
                                value="list"
                                aria-label="left aligned"
                            >
                                List View
                            </ToggleButton>
                            <ToggleButton
                                onClick={() => {
                                    setView('graph');
                                }}
                                value="graph"
                                aria-label="right aligned"
                            >
                                Graph View
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid> */}
                {/* {view === 'list' && (
                    <>
                        <Grid item xs={12} lg={12} md={12}>
                            <ListContainer monitoredValue={monitoredValue} />
                        </Grid>
                    </>
                )}
                {view === 'graph' && (
                    <>
                        <Grid item xs={12} lg={12} md={12}>
                            <ChartContainer monitoredValue={monitoredValue} />
                        </Grid>
                    </>
                )} */}
                {/* <Grid item sx={5} lg={5} md={5} align="right">
                        <ToggleButtonGroup
                            aria-label="text alignment"
                            sx={{
                                outlineColor: theme.palette.primary.main,
                                outlineWidth: '1px',
                                outlineStyle: 'solid',
                                margin: '2px'
                            }}
                            color="primary"
                            size="small"
                            value={view}
                            exclusive
                            // onChange={handleChange}
                        >
                            <ToggleButton
                                onClick={() => {
                                    setView('today');
                                }}
                                value="today"
                                aria-label="left aligned"
                            >
                                Today
                            </ToggleButton>
                            <ToggleButton
                                onClick={() => {
                                    setView('week');
                                }}
                                value="week"
                                aria-label="right aligned"
                            >
                                Week
                            </ToggleButton>
                            <ToggleButton
                                onClick={() => {
                                    setView('month');
                                }}
                                value="month"
                                aria-label="right aligned"
                            >
                                Month
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    {view === 'week' && (
                        <>
                            <Grid item xs={7} lg={7} md={7} />
                            <Grid item xs={5} lg={5} md={5} algin="right">
                                <Stack direction="row" justifyContent="flex-end">
                                    <Button variant="text" startIcon={<NavigateBeforeIcon />}>
                                        Previous Week
                                    </Button>
                                    <Button variant="text" endIcon={<NavigateNextIcon />}>
                                        Next Week
                                    </Button>
                                </Stack>
                            </Grid>
                        </>
                    )} */}
            </Grid>
        </>
    );
};

export default MonitoredValuesTab;
