import { Button, Dialog, IconButton, Divider, useTheme, DialogContent, Typography, Stack, DialogActions } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import AddNewActivityContents from './AddNewActivityContents';

const AddNewActivity = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
    return (
        <>
            <Button size="small" variant="outlined" onClick={handleOpen}>
                Add New Activity
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                // mWidth={mWidth}
                open={open}
                sx={{
                    '& .MuiDialog-paper': {
                        maxWidth: 'none',
                        width: '50%'
                        // height: 'calc(100% - 64px)'
                    },
                    '& .MuDialogContent-root': {
                        padding: theme.spacing(2)
                    },
                    '& .MuDialogActions-root': {
                        padding: theme.spacing(1)
                    }
                }}
            >
                <BootstrapDialogTitle onClose={handleClose} id="customized-dialog-title">
                    <Typography variant="h3">Add New Activity</Typography>
                </BootstrapDialogTitle>
                <Divider />
                <DialogContent>
                    <AddNewActivityContents />
                </DialogContent>
                <Divider />
                <DialogActions align="left" sx={{ justifyContent: 'space-between', pl: 3, mt: 1 }}>
                    <Stack direction="row" spacing={1}>
                        <Button size="small" variant="contained" onClick={handleClose}>
                            Publish
                        </Button>
                        <Button size="small" variant="outlined" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddNewActivity;
