import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Grid, Stack } from '@mui/material';
import Currentstatus from './Currentstatus';
import Goalsummary from './Goalsummary';
import Button from '@mui/material/Button';
import Weightprogress from './Weightgoalprogress';
import { ontrack, noofgoals, offtrack } from '../../../assets/customstyle/Goalstyle';
import { display } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { activeItem } from 'store/slices/menu';
import { useDispatch } from 'store';

export default function BasicAccordion() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = () => {
        navigate('/dreams');
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'dreams');
        if (currentIndex > -1) {
            dispatch(activeItem(['dreams']));
        }
    };
    return (
        <div>
            <Grid
                item
                xs={12}
                lg={12}
                md={12}
                style={{
                    background: 'white',
                    borderRadius: '10px',
                    borderColor: 'transperant',
                    padding: '0px',
                    border: '1px solid #A6A1A175'
                }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #d7d3d3',
                        width: '100%',
                        padding: '20px 20px'
                    }}
                >
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="h3">My Goals Summary</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" sx={noofgoals}>
                        <Typography variant="p">No of Goals : 4</Typography>
                    </Stack>
                </Grid>
                <div style={{ padding: '10px 10px 10px 10px' }}>
                    <Accordion
                        sx={{
                            backgroundColor: theme.palette.goalsummary.goalbackground,
                            marginTop: '20px',
                            verticalAlign: 'center',
                            borderRadius: '10px !important',
                            padding: '10px 0'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ alignItems: 'center' }}
                        >
                            <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
                                Weight Goal
                            </Typography>
                            <Typography variant="span" sx={ontrack}>
                                Ontrack
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Currentstatus />
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                md={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    [theme.breakpoints.down('lg')]: {
                                        flexDirection: 'column'
                                    }
                                }}
                            >
                                <Grid xs={12} lg={8} md={12}>
                                    <Weightprogress />
                                </Grid>
                                <Grid xs={12} lg={4} md={12}>
                                    <Goalsummary />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: theme.palette.goalsummary.goalbackground,
                            marginTop: '20px',
                            verticalAlign: 'center',
                            borderRadius: '10px !important',
                            padding: '10px 0'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ alignItems: 'center' }}
                        >
                            <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
                                Nutrition Goal
                            </Typography>
                            <Typography variant="span" sx={ontrack}>
                                Ontrack
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: theme.palette.goalsummary.goalbackground,
                            marginTop: '20px',
                            verticalAlign: 'center',
                            borderRadius: '10px !important',
                            padding: '10px 0'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ alignItems: 'center' }}
                        >
                            <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
                                Water Intake Goal
                            </Typography>
                            <Typography variant="span" sx={ontrack}>
                                Ontrack
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                </div>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{ borderTop: '1px solid #eee', width: '100%', justifyContent: 'flex-end' }}
                    >
                        <Button variant="text" size="small" onClick={handleClick} sx={{ pt: 2 }}>
                            <b>View More Goals</b>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    );
}
