import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import useConfig from 'hooks/useConfig';
import MonitoredValuesTab from './tabcontents/rpm/monitoredvalues/MonitoredValuesTab';
import StatusAndAlertsTab from './tabcontents/rpm/statusalerts/StatusAndAlertsTab';
import HistoryTab from './tabcontents/rpm/history/HistoryTab';
import ReportsTab from './tabcontents/rpm/reports/ReportsTab';
import DevicesTab from './tabcontents/rpm/devices/DevicesTab';
import RPMCyclesTab from './tabcontents/rpm/rpmcycles/RPMCyclesTab';
import BillingTab from './tabcontents/rpm/billing/BillingTab';
import SummaryTab from './tabcontents/rpm/summary/SummaryTab';

// tabs
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: 'Summary'
    },
    {
        label: 'Monitored Values'
    },
    {
        label: 'Status & Alerts'
    },
    {
        label: 'Reports'
    },
    {
        label: 'Devices'
    },
    {
        label: 'RPM Cycles'
    },
    {
        label: 'Billing'
    }
];

// ==============================|| PROFILE 2 ||============================== //

const RPMTabs = ({ tabChange }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={3} sx={{ p: '10px !important' }}>
                        <CardContent sx={{ p: '8px !important' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                orientation="vertical"
                                variant="scrollable"
                                sx={{
                                    '& .MuiTabs-flexContainer': {
                                        borderBottom: 'none'
                                    },
                                    '& button': {
                                        color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                                        minHeight: 'auto',
                                        minWidth: '100%',
                                        py: 1.5,
                                        px: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        textAlign: 'left',
                                        justifyContent: 'flex-start',
                                        borderRadius: `${borderRadius}px`
                                    },
                                    '& button.Mui-selected': {
                                        color: theme.palette.primary.main,
                                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
                                    },
                                    '& button > svg': {
                                        marginBottom: '0px !important',
                                        marginRight: 1.25,
                                        marginTop: 1.25,
                                        height: 20,
                                        width: 20
                                    },
                                    '& button > div > span': {
                                        display: 'block'
                                    },
                                    '& > div > span': {
                                        display: 'none'
                                    }
                                }}
                            >
                                {tabsOption.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        icon={tab.icon}
                                        label={
                                            <Grid container direction="column">
                                                <Typography variant="subtitle1" color="inherit">
                                                    {tab.label}
                                                </Typography>
                                                <Typography component="div" variant="caption" sx={{ textTransform: 'capitalize' }}>
                                                    {tab.caption}
                                                </Typography>
                                            </Grid>
                                        }
                                        {...a11yProps(index)}
                                    />
                                ))}
                            </Tabs>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12} lg={9} sx={{ p: '0px !important' }}>
                        <CardContent
                            sx={{
                                borderLeft: '1px solid',
                                p: '10px !important',
                                pr: '0px !important',
                                mt: '12px',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
                                height: '100%'
                            }}
                        >
                            <TabPanel value={value} index={0}>
                                <SummaryTab tabChange={tabChange} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <MonitoredValuesTab />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <StatusAndAlertsTab />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <ReportsTab />
                            </TabPanel>
                            {/* <TabPanel value={value} index={4}>
                                <HistoryTab />
                            </TabPanel> */}
                            <TabPanel value={value} index={4}>
                                <DevicesTab />
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <RPMCyclesTab />
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <BillingTab />
                            </TabPanel>
                        </CardContent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RPMTabs;
