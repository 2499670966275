import {
    Grid,
    IconButton,
    useTheme,
    Typography,
    Divider,
    Card,
    CardContent,
    TextField,
    MenuItem,
    Stack,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

const SocialConnectionAndIsolation = () => {
    const [open, setOpen] = useState(false);
    const [q1, setQ1] = useState('2');
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    const subCardStyle = {
        ml: '20px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px solid',
        borderRadius: 2,
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card sx={cardStyle}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={11} md={11} lg={11}>
                                    <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                        <Typography variant="h5">Social Connection and Isolation</Typography>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1} align="right">
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        <ExpandCircleDownOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                                {!open && (
                                    <>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Marital status - Married</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Communication with family, friends, or neighbors per week - 1</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Get together with friends or relatives per week - 1</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Attend religious activities per week - 1</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ pt: '5px !important' }}>
                                            <Typography>Belong to clubs/groups - No</Typography>
                                        </Grid>
                                    </>
                                )}
                                {open && (
                                    <>
                                        <Grid item xs={12} lg={12} md={12} sx={subCardStyle}>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        Are you now married, widowed, divorced, separated, never married or living with a
                                                        partner?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <TextField fullWidth label="Married Status" defaultValue="1" select size="small">
                                                        <MenuItem value="1">Married</MenuItem>
                                                        <MenuItem value="2">Widowed</MenuItem>
                                                        <MenuItem value="3">Divorced</MenuItem>
                                                        <MenuItem value="4">Separated</MenuItem>
                                                        <MenuItem value="5">Never married</MenuItem>
                                                        <MenuItem value="6">Living with partner</MenuItem>
                                                        <MenuItem value="7">Refused</MenuItem>
                                                        <MenuItem value="8">Don&apos;t know</MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        In a typical week, how many times do you talk on the telephone with family, friends,
                                                        or neighbors?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <TextField fullWidth label="Number of times" defaultValue="1" size="small" />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>How often do you get together with friends or relatives?</Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <TextField fullWidth label="Number of times" defaultValue="1" size="small" />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>How often do you attend church or religious services?</Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <TextField fullWidth label="Number of times" defaultValue="1" size="small" />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={gridSpacing} sx={{ mb: '20px' }}>
                                                <Grid item xs={12} lg={4} md={4}>
                                                    <Typography>
                                                        Do you belong to any clubs or organizations such as church groups unions, fraternal
                                                        or athletic groups, or school groups?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={4} md={4} sx={{ pt: '15px !important' }}>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-label="q1"
                                                            value={q1}
                                                            onChange={(e) => setQ1(e.target.value)}
                                                            name="row-radio-buttons-group"
                                                        >
                                                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                            <FormControlLabel value="2" control={<Radio />} label="No" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Stack direction="row" spacing={2}>
                                                <Button variant="contained" onClick={() => setOpen(!open)}>
                                                    Update
                                                </Button>

                                                <Button variant="outlined" onClick={() => setOpen(!open)}>
                                                    Close
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default SocialConnectionAndIsolation;
