import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    styled,
    tableCellClasses,
    Button,
    MenuItem,
    Menu,
    Typography,
    Grid,
    Badge
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { useState } from 'react';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EmailIcon from '@mui/icons-material/Email';
import Chip from 'ui-component/extended/Chip';

// table data
function createData(status, plan, onTrack, offTrack) {
    return {
        status,
        plan,
        onTrack,
        offTrack
    };
}

function Row({ row, key }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const cardStyle = {
        pt: '20px',
        pl: '15px',
        pb: '15px',
        backgroundColor: theme.palette.tableColor.tableExpandBg
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const [problem, setProblem] = useState('Diabetes Mellitus');

    const problems = [
        { label: 'Diabetes Mellitus', id: 1 },
        { label: 'Diabetes insipidus', id: 2 },
        { label: 'Necrobiosis lipoidica diabeticorum', id: 3 }
    ];

    const handleChange = (e) => {
        setProblem(e.target.value);
    };

    let status = <></>;
    if (row.status === '1') {
        status = 'success.main';
    } else if (row.status === '2') {
        status = 'error.main';
    } else if (row.status === '3') {
        status = 'error.main';
    } else if (row.status === '4') {
        status = theme.palette.grey[500];
    }

    let cellStyle = <></>;
    if (row.status === '1') {
        cellStyle = theme.palette.success.main;
    } else if (row.status === '3') {
        cellStyle = theme.palette.success.main;
    } else if (row.status === '4') {
        cellStyle = theme.palette.grey[500];
    }

    const [endDate, setEndDate] = useState('05/10/2021');

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FiberManualRecordIcon sx={{ color: status }} />
                        <b>{row.plan}</b>
                    </Stack>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.onTrack}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.offTrack}</b>
                </StyledTableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const ErrorBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        background: theme.palette.error.dark,
        color: theme.palette.primary.light,
        width: '80px',
        hieght: '100px',
        right: -45,
        top: -1,
        border: `1px solid ${theme.palette.error.dark}`,
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '0px',
        paddingRight: '0px'
    }
}));

const SuccessBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        background: theme.palette.success.dark,
        color: theme.palette.primary.light,
        width: '80px',
        hieght: '100px',
        right: -45,
        top: -1,
        border: `1px solid ${theme.palette.success.dark}`,
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '0px',
        paddingRight: '0px'
    }
}));

const patients = {
    fontSize: '12px',
    color: 'rgb(85 6 0)',
    borderRadius: '100px',
    fontWeight: 'bold',
    background: 'rgb(243 200 200)',
    padding: '10px',
    border: '2px solid rgb(244, 67, 54)',
    marginBottom: '10px',
    display: 'inline-block'
};
// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function DreamsSummaryTable() {
    const theme = useTheme();
    const rows = [
        createData(
            '1',
            'Diet',
            <>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h5">
                        <b>Meal Plan</b>
                    </Typography>
                    <Typography sx={{ color: theme.palette.success.dark }} variant="h5">
                        <b>Good</b>
                    </Typography>
                </Stack>
                {/* <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
                    <Typography variant="h5">
                        <b>Calorie Plan</b>
                    </Typography>
                    <Typography sx={{ color: theme.palette.success.dark }} variant="h5">
                        <b>Good</b>
                    </Typography>
                </Stack> */}
            </>,
            <>
                <Typography variant="h5">
                    <b>Weight</b>
                </Typography>
                <Typography variant="body1" sx={{ pb: 1 }}>
                    Goal: 180 lbs, Achieved: 185 lbs
                </Typography>
                <Typography variant="h5">
                    <b>Daily Calorie</b>
                </Typography>
                <Typography variant="body1">Goal: 2500 cal, Achieved: 2200 cal</Typography>
            </>
        ),
        createData(
            '1',
            'Rest',
            <>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h5">
                        <b>Daily Sleep Pattern</b>
                    </Typography>
                    <Typography sx={{ color: theme.palette.success.dark }} variant="h5">
                        <b>Good</b>
                    </Typography>
                </Stack>
            </>,
            <>Regular Sleep and Wake</>
        ),
        createData(
            '2',
            'Exercise',
            <>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h5">
                        <b>Moderate Cardio</b>
                    </Typography>
                    <Typography sx={{ color: theme.palette.warning.dark }} variant="h5">
                        <b>Fair</b>
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
                    <Typography variant="h5">
                        <b>Moderate Walking</b>
                    </Typography>
                    <Typography sx={{ color: theme.palette.success.dark }} variant="h5">
                        <b>Good</b>
                    </Typography>
                </Stack>
            </>,
            <>
                <Typography variant="h5">
                    <b>Weight</b>
                </Typography>
                <Typography variant="body1">Goal: 180 lbs, Achieved: 185 lbs</Typography>
            </>
        ),
        createData(
            '4',
            'Attitude',
            <>
                <Typography sx={{ color: theme.palette.grey[500] }} variant="body1">
                    No Goals and Activities planned
                </Typography>
            </>,
            ''
        ),
        createData(
            '4',
            'Medication',
            <>
                <Typography sx={{ color: theme.palette.grey[500] }} variant="body1">
                    No Goals and Activities planned
                </Typography>
            </>,
            ''
        ),
        createData(
            '2',
            'Specialist',
            <>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h5">
                        <b>Appointment with Dr. Frank Ellison</b>
                    </Typography>
                    <Typography sx={{ color: theme.palette.warning.dark }} variant="h5">
                        <b>Pending</b>
                    </Typography>
                </Stack>
            </>,
            ''
        )
    ];
    const gridStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const ErrorBadgeTitle = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.error.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.error.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    return (
        <Grid container spacing={1} alignItems="center" sx={{ mt: -3 }}>
            <Grid item xs={8} lg={8} md={4} sx={{ pb: 1 }}>
                <Stack direction="row" alignItems="center" spacing={6}>
                    <Typography variant="dashboardCardContent">
                        <b>DREAMS Summary</b>
                    </Typography>
                    <ErrorBadgeTitle badgeContent="Off Track" />
                </Stack>
            </Grid>
            <Grid item xs={4} lg={4} md={4} align="right">
                <Typography variant="dashboardCardContent">
                    <b style={patients}>ORG SCORE : 25%</b>
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sx={{ p: '8px !important' }}>
                <MainCard content={false}>
                    {/* table */}
                    <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Plan</StyledTableCell>
                                    <StyledTableCell width="40%">Activity Progress</StyledTableCell>
                                    <StyledTableCell>Goal Progress</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <Row key={index} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard>
            </Grid>
        </Grid>
    );
}
