import { useTheme } from '@mui/material';
import BloodPressureAreaChart from './BloodPressureChart';
import WeightChart from './WeightChart';

const ChartContainer = ({ monitoredValue }) => {
    const theme = useTheme();
    const renderChart = () => {
        let obj = <></>;
        const val = monitoredValue.label;
        if (val === 'Blood Pressure') {
            obj = <BloodPressureAreaChart />;
        } else if (val === 'Weight') {
            obj = <WeightChart />;
        }
        return <>{obj}</>;
    };
    return <>{renderChart()}</>;
};

export default ChartContainer;
