import { useTheme, Grid, Typography } from '@mui/material';
import TestResultsTable from '../../../../healthrecords/testresults/TestResultsTable';

const TestResultsTab = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }}>
                    <Typography variant="dashboardCardContent">
                        <b>Test Results</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <TestResultsTable />
                </Grid>
            </Grid>
        </>
    );
};

export default TestResultsTab;
