import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
    Divider,
    Stack,
    Link,
    IconButton
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PatientDetailsTable from './PatientDetailsTable';
import { positions } from '@mui/system';

const validationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    dob: yup.date().required('Date of Birth is required'),
    gender: yup.string().required('Gender is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    mobile: yup.string().required('Mobile is required')
});

const SearchPatientsCard = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [dob, setDOB] = useState(null);
    const [showdetails, setShowDetails] = useState(false);
    const [addPatients, setAddPatients] = useState(false);
    const [open, setOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            dob: '',
            gender: '',
            email: '',
            mobile: ''
        },
        validationSchema,
        onSubmit: (values) => {
            // Handle form submission
            console.log(values);
        }
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        // Handle confirmation logic here
        setOpen(false);
    };

    const handleAddPatientsClick = () => {
        setAddPatients(!addPatients);
    };

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    return (
        <Grid container alignItems="center" spacing={1}>
            {!addPatients && (
                <>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h3">
                                <b>Search Patients</b>
                            </Typography>
                            <Button variant="contained" size="small" onClick={handleAddPatientsClick}>
                                Add Patients
                            </Button>
                        </Stack>
                    </Grid>
                </>
            )}
            {addPatients && (
                <>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={handleAddPatientsClick}>
                                <NavigateBeforeIcon />
                            </IconButton>
                            <Typography variant="h3">
                                <b>Add Patients</b>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <form onSubmit={formik.handleSubmit}>
                            <SubCard sx={cardStyle}>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="dashboardCardContent">
                                            <b>Manual Entry</b>
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                id="firstName"
                                                name="firstName"
                                                label="First Name"
                                                size="small"
                                                fullWidth
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                                helperText={formik.touched.firstName && formik.errors.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                id="middleName"
                                                name="middleName"
                                                label="Middle Name"
                                                size="small"
                                                fullWidth
                                                value={formik.values.middleName}
                                                onChange={formik.handleChange}
                                                error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                                                helperText={formik.touched.middleName && formik.errors.middleName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                id="lastName"
                                                name="lastName"
                                                label="Last Name"
                                                size="small"
                                                fullWidth
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                                helperText={formik.touched.lastName && formik.errors.lastName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    renderInput={(props) => (
                                                        <TextField
                                                            size="small"
                                                            {...props}
                                                            fullWidth
                                                            helperText={formik.touched.dob && formik.errors.dob}
                                                            error={formik.touched.dob && Boolean(formik.errors.dob)}
                                                            sx={{
                                                                '& .MuiInputLabel-root': {
                                                                    top: '2px'
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                    label="Date Of Birth"
                                                    value={dob}
                                                    onChange={(newValue) => {
                                                        setDOB(newValue);
                                                        formik.setFieldValue('dob', newValue);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                id="gender"
                                                name="gender"
                                                select
                                                label="Gender"
                                                size="small"
                                                fullWidth
                                                value={formik.values.gender}
                                                onChange={formik.handleChange}
                                                error={formik.touched.gender && Boolean(formik.errors.gender)}
                                                helperText={formik.touched.gender && formik.errors.gender}
                                            >
                                                <MenuItem value="Male">Male</MenuItem>
                                                <MenuItem value="Female">Female</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                id="patientId"
                                                name="patientId"
                                                label="MRN"
                                                size="small"
                                                fullWidth
                                                value={formik.values.patientId}
                                                onChange={formik.handleChange}
                                                error={formik.touched.patientId && Boolean(formik.errors.patientId)}
                                                helperText={formik.touched.patientId && formik.errors.patientId}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} md={3}>
                                            <TextField
                                                id="medicareNumber"
                                                name="medicareNumber"
                                                label="Medicare Number"
                                                size="small"
                                                fullWidth
                                                value={formik.values.medicareNumber}
                                                onChange={formik.handleChange}
                                                error={formik.touched.medicareNumber && Boolean(formik.errors.medicareNumber)}
                                                helperText={formik.touched.medicareNumber && formik.errors.medicareNumber}
                                            />
                                        </Grid> */}
                                        {/* <Grid item xs={12} md={3}>
                                            <TextField
                                                id="ssn"
                                                name="ssn"
                                                label="SSN"
                                                size="small"
                                                fullWidth
                                                value={formik.values.ssn}
                                                onChange={formik.handleChange}
                                                error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                                                helperText={formik.touched.ssn && formik.errors.ssn}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                id="email"
                                                name="email"
                                                label="Email"
                                                size="small"
                                                fullWidth
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email && formik.errors.email}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                id="secondaryEmail"
                                                name="secondaryEmail"
                                                label="Secondary Email"
                                                size="small"
                                                fullWidth
                                                value={formik.values.secondaryEmail}
                                                onChange={formik.handleChange}
                                                error={formik.touched.secondaryEmail && Boolean(formik.errors.secondaryEmail)}
                                                HelperText={formik.touched.secondaryEmail && formik.errors.secondaryEmail}
                                            />
                                        </Grid>
                                        <Divider orientation="vertical" style={{ position: 'relative', bottom: '-20px', right: '-10px' }}>
                                            Or
                                        </Divider>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="mobile"
                                                name="mobile"
                                                label="Mobile"
                                                size="small"
                                                fullWidth
                                                value={formik.values.mobile}
                                                onChange={formik.handleChange}
                                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                                helperText={formik.touched.mobile && formik.errors.mobile}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ my: 2 }}>
                                    <Divider
                                        sx={{
                                            '&::before, &::after': {
                                                borderColor: theme.palette.grey[500]
                                            }
                                        }}
                                        orientation="horizontal"
                                    >
                                        <Typography variant="h4">
                                            <b>Or</b>
                                        </Typography>
                                    </Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Link to="/patients" style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                            Download Sample File
                                        </Link>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }} align="left">
                                    <Button type="submit" variant="contained" size="medium" sx={{ mr: 1 }}>
                                        Save
                                    </Button>
                                    <Button variant="outlined" size="medium" onClick={handleAddPatientsClick}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </SubCard>
                        </form>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                                <DialogContentText sx={{ color: '#000' }}>Are you sure you want to perform this action?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" variant="outlined">
                                    Cancel
                                </Button>
                                <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </>
            )}
            {!addPatients && (
                <Grid item xs={12}>
                    <SubCard sx={cardStyle}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="firstName" label="First Name" size="small" />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="lastName" label="Last Name" size="small" />
                                </Grid>
                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={({ ...props }) => <TextField size="small" fullWidth {...props} helperText="" />}
                                            label="Date Of Birth"
                                            value={dob}
                                            onChange={(newValue) => {
                                                setDOB(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-select-experience1"
                                        select
                                        label="Gender"
                                        defaultValue="Male"
                                        size="small"
                                        fullWidth
                                    >
                                        <MenuItem selected value="Male">
                                            Male
                                        </MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="email" label="Email" size="small" />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="mobile" label="Mobile" size="small" />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="outlined-select-experience1" select label="Physician" size="small" fullWidth>
                                        <MenuItem value="1">Dr. Roger Miles</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }} align="left">
                            <Button variant="contained" size="medium" sx={{ mr: 1 }} onClick={() => setShowDetails(!showdetails)}>
                                Search
                            </Button>
                            <Button variant="outlined" size="medium">
                                Clear
                            </Button>
                        </Grid>
                    </SubCard>
                </Grid>
            )}
            {!addPatients && showdetails && (
                <Grid item xs={12} sx={{ pt: 2 }}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Grid item xs={10} align="right" sx={{ p: 2 }}>
                            <Typography variant="body1">Showing All Patients</Typography>
                        </Grid>
                    </Grid>
                    <PatientDetailsTable />
                </Grid>
            )}
        </Grid>
    );
};

export default SearchPatientsCard;
