import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Typography,
    Grid,
    Chip,
    styled,
    tableCellClasses,
    Button
} from '@mui/material';
import Paper from '@mui/material/Paper';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { gridSpacing } from 'store/constant';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';

// table data
function createData(service, test, status, datetimeperiod, provider, specimen, expand, tests) {
    return {
        service,
        test,
        status,
        datetimeperiod,
        provider,
        specimen,
        expand,
        tests
    };
}

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const cardStyle = {
        pt: '15px',
        pl: '15px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.tableColor.tableExpandBg
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableHead = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const StyledSubTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.service}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.test}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.status}</b>
                </StyledTableCell>
                {/* <StyledTableCell>
                    <b>{row.subject}</b>
                </StyledTableCell> */}
                <StyledTableCell>
                    <b>{row.datetimeperiod}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.provider}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.specimen}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                        {/* {row.flag !== '0' && (
                            <IconButton aria-label="expand row" size="small">
                                <DeleteIcon color="error" />
                            </IconButton>
                        )} */}
                    </Stack>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>
                                        {row.expand?.map((items) => (
                                            <TableRow>
                                                <Grid container spacing={gridSpacing} sx={cardStyle}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Service</Typography>
                                                                {row.service}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Test</Typography>
                                                                {row.test}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Status</Typography>
                                                                {row.status}
                                                            </Grid>
                                                            {/*  <Grid item xs={3}>
                                                                <Typography variant="h5">Subject</Typography>
                                                                {row.subject}
                                                            </Grid> */}
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Date/Time Period</Typography>
                                                                {row.datetimeperiod}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Provider</Typography>
                                                                {row.provider}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Specimen</Typography>
                                                                {row.specimen}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h4">Results</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TableContainer>
                                                                    <Table
                                                                        size="small"
                                                                        sx={{
                                                                            border: '1px solid',
                                                                            borderColor: theme.palette.common.grey
                                                                        }}
                                                                    >
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <StyledTableHead>
                                                                                    <FormattedMessage id="Test_Name" />
                                                                                </StyledTableHead>
                                                                                <StyledTableHead>
                                                                                    <FormattedMessage id="Value" />
                                                                                </StyledTableHead>
                                                                                <StyledTableHead>
                                                                                    <FormattedMessage id="Reference_Range" />
                                                                                </StyledTableHead>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {row.test &&
                                                                                row.tests.map((row, index) => (
                                                                                    <TableRow hover key={index}>
                                                                                        <StyledSubTableCell>
                                                                                            <b>{row.testname}</b>
                                                                                        </StyledSubTableCell>
                                                                                        <StyledSubTableCell>
                                                                                            <b>{row.value}</b>
                                                                                        </StyledSubTableCell>
                                                                                        <StyledSubTableCell>
                                                                                            <b>{row.referencerange}</b>
                                                                                        </StyledSubTableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5">Imaging Study</Typography>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget malesuada metus.
                                                        Etiam in iaculis magna. Cras lobortis orci leo, sit amet vehicula eros tristique
                                                        ultrices. Integer cursus tristique leo quis commodo. Donec aliquam quis tortor
                                                        feugiat sollicitudin. Curabitur quis aliquam eros. Maecenas egestas purus quam, a
                                                        ultrices turpis accumsan et. Nam eu congue purus, eu tempus lectus. Ut elementum
                                                        arcu vitae suscipit faucibus.
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5">Conclusion</Typography>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget malesuada metus.
                                                        Etiam in iaculis magna. Cras lobortis orci leo, sit amet vehicula eros tristique
                                                        ultrices. Integer cursus tristique leo quis commodo. Donec aliquam quis tortor
                                                        feugiat sollicitudin. Curabitur quis aliquam eros. Maecenas egestas purus quam, a
                                                        ultrices turpis accumsan et. Nam eu congue purus, eu tempus lectus. Ut elementum
                                                        arcu vitae suscipit faucibus.
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="outlined" onClick={() => setOpen(!open)}>
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData(
        'Hematology',
        'COMPLETE BLOOD COUNT',
        'Final',

        '05/06/2022 7 30 AM [PST]',
        'Sutter Health',
        'Blood',
        ['Lorem Ipsum'],
        [
            { testname: 'HEMOGLOBIN ', value: '176 G/L', referencerange: '135 - 180 G/L' },
            { testname: 'RED CELL COUNT ', value: '5.9 10*12/L', referencerange: '4.2 - 6  10*12/L' },
            { testname: 'HAEMATOCRIT  ', value: '55%', referencerange: '38 - 52 %' }
        ]
    )
];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function TestResultsTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Service</StyledTableCell>
                            <StyledTableCell>Test</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            {/* <StyledTableCell>Subject</StyledTableCell> */}
                            <StyledTableCell>Date/Time period</StyledTableCell>
                            <StyledTableCell>Provider</StyledTableCell>
                            <StyledTableCell>Specimen </StyledTableCell>
                            <StyledTableCell />
                            {/* <StyledTableCell /> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
