import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Nogoalsimg from '../../../assets/images/dashboard/nodatas.svg';

const CareteamInvite = () => {
    const theme = useTheme();
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            border: '1px solid #ebebeb',
                            borderRadius: '8px',
                            background: 'linear-gradient(to right top, #f0f2f5, #f4f5f8, #f8f8fa, #fcfcfc, #ffffff)',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '20px',
                            [theme.breakpoints.down('sm')]: {
                                flexDirection: 'column'
                            }
                        }}
                    >
                        <Grid item xs={12} lg={6} md={6} sm={12}>
                            <img alt="nogoals" src={Nogoalsimg} style={{ width: '100%' }} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            md={6}
                            sm={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                pl: 5,
                                [theme.breakpoints.down('sm')]: {
                                    pl: 0,
                                    textAlign: 'center'
                                }
                            }}
                        >
                            <Typography variant="h2">Start setting up your Care Team</Typography>
                            {/* <Typography variant="p" sx={{ paddingTop: '10px', fontSize: '18px', fontWeight: 'normal', lineHeight: '24px' }}>
                                There are many variations of passages of Lorem Ipsum available, <br />
                                but the majority have suffered alteration in some form
                            </Typography> */}

                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    maxWidth: '300px',
                                    mt: 3,
                                    borderRadius: '8px',
                                    p: 1
                                    // [theme.breakpoints.down('sm')]: {
                                    //     margin: '20px auto 10px '
                                    // }
                                }}
                            >
                                Invite a New Care Team Member
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
export default CareteamInvite;
