import {
    Alert,
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Stack,
    Badge,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    CardContent
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';

import { gridSpacing } from 'store/constant';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import MainCard from 'ui-component/cards/MainCard';

import Chip from 'ui-component/extended/Chip';
import Timeline from '../../../../../provider/patients';

function createData(planned, completed, dayspending) {
    return { planned, completed, dayspending };
}

const rows = [createData(14, 3, 18)];

const SummaryTab = () => {
    const [mode, setMode] = useState('pending');
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const handleChange = (e) => {
        setMode(e.target.value);
    };
    const dropData = {
        title: 'By Month',
        options: [
            {
                value: 1,
                label: 'Weekly'
            }
        ]
    };

    let dropHtml;
    if (dropData) {
        const handleClick = (event) => {
            setAnchorEl(event?.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        dropHtml = (
            <>
                <Button
                    variant="text"
                    disableElevation
                    size="medium"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    endIcon={<ExpandCircleDownOutlinedIcon />}
                >
                    {dropData.title}
                </Button>
                <Menu
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {dropData?.options.map((option, index) => (
                        <MenuItem key={index} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }
    const ErrorBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.error.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.error.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));
    const cardStyle = {
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        borderRadius: 2,
        border: `1px solid ${theme.palette.common.grey}`
    };
    const timelineCardStyle = {
        // backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        pl: '6px',
        mt: '10px',
        px: '5px !important',
        pt: '0px !important',
        pb: '16px'
    };
    return (
        <Grid container spacing={1}>
            <Grid item container xs={12} lg={12} md={12} alignItems="center">
                {/* <Grid item xs={2} lg={2} md={2}>
                        <Typography variant="dashboardCardContent">
                            <b>TCM Progress</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={5} lg={5} md={5}>
                        <Stack direction="row" alignItems="center" spacing={6}>
                            <ErrorBadge badgeContent="Off Track" />

                            <Button
                                variant="text"
                                onClick={() => {
                                    tabChange(0, 2);
                                }}
                            >
                                View Details
                            </Button>
                        </Stack>
                    </Grid> */}
                <Grid item xs={8} lg={8} md={8} sx={{ p: 1, pl: 0 }}>
                    {/* <Typography variant="dashboardCardContent">
                        <b>TCM Summary</b>
                    </Typography> */}
                    <Stack direction="row" alignItems="center" spacing={6}>
                        <Typography variant="dashboardCardContent">
                            <b>TCM Progress</b>
                        </Typography>
                        <ErrorBadge badgeContent="Off Track" />
                        {/* </Grid>
                    <Grid item xs={3} lg={3} md={3} align="left"> */}
                        {/* <Button variant="text">View Details</Button> */}
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={timelineCardStyle}>
                    <Timeline />
                </Grid>
            </Grid>
            <Grid container item xs={12} lg={12} md={12} alignItems="center">
                <Grid item xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* <Typography variant="dashboardCardContent">
                        <b>TCM Summary</b>
                    </Typography> */}

                    <Typography variant="dashboardCardContent">
                        <b>TCM Summary</b>
                    </Typography>
                    <ToggleButtonGroup
                        aria-label="text alignment"
                        sx={{ outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px' }}
                        color="primary"
                        size="small"
                        value={mode}
                        exclusive
                        onChange={handleChange}
                    >
                        <ToggleButton value="pending" aria-label="left aligned">
                            Pending
                        </ToggleButton>
                        <ToggleButton value="upcoming" aria-label="right aligned">
                            Upcoming
                        </ToggleButton>
                        <ToggleButton value="completed" aria-label="left aligned">
                            Completed
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={4} lg={4} md={8} sm={8} align="right">
                    {/* <ToggleButtonGroup
                        aria-label="text alignment"
                        sx={{ outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px' }}
                        color="primary"
                        size="small"
                        value={mode}
                        exclusive
                        onChange={handleChange}
                    >
                        <ToggleButton value="pending" aria-label="left aligned">
                            Pending
                        </ToggleButton>
                        <ToggleButton value="upcoming" aria-label="right aligned">
                            Upcoming
                        </ToggleButton>
                        <ToggleButton value="completed" aria-label="left aligned">
                            Completed
                        </ToggleButton>
                    </ToggleButtonGroup> */}
                </Grid>
            </Grid>
            {/* <Grid item xs={12} lg={12} md={12}>
                <Alert variant="filled" severity="error">
                    12th day of TCM, Face to Face not scheduled yet.
                </Alert>
            </Grid> */}

            <Grid item xs={12} lg={12} md={12}>
                {mode === 'pending' && (
                    <Grid item xs={12} lg={12} md={12}>
                        <Card sx={cardStyle}>
                            <CardContent>
                                <Grid container alignItems="center">
                                    <Grid item container xs={12} lg={12} md={12} spacing={2}>
                                        {/* <Grid item xs={12} lg={12} md={12}>
                                        <Typography variant="h4" sx={{ color: theme.palette.success.dark }}>
                                            Most of the activities on track.
                                        </Typography>
                                    </Grid> */}
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h4">Pending</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h5">Medication Refill</Typography>
                                        </Grid>
                                        {/* <Grid item xs={12} lg={12} md={12}>
                                        <Typography variant="h4" sx={{ color: theme.palette.success.dark }}>
                                            Billing is on track.
                                        </Typography>
                                    </Grid> */}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {mode === 'upcoming' && (
                    <Grid item xs={12} lg={12} md={12}>
                        <Card sx={cardStyle}>
                            <CardContent>
                                <Grid container alignItems="center">
                                    <Grid item container xs={12} lg={12} md={12} spacing={2}>
                                        {/* <Grid item xs={12} lg={12} md={12}>
                                        <Typography variant="h4" sx={{ color: theme.palette.success.dark }}>
                                            Most of the activities on track.
                                        </Typography>
                                    </Grid> */}
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h4">Upcoming</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h5">Face to face appointment</Typography>
                                        </Grid>
                                        {/* <Grid item xs={12} lg={12} md={12}>
                                        <Typography variant="h4" sx={{ color: theme.palette.success.dark }}>
                                            Billing is on track.
                                        </Typography>
                                    </Grid> */}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {mode === 'completed' && (
                    <Grid item xs={12} lg={12} md={12}>
                        <Card sx={cardStyle}>
                            <CardContent>
                                <Grid container alignItems="center">
                                    <Grid item container xs={12} lg={12} md={12} spacing={2}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h4">Completed</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h5">
                                                Assistance in arranging Community and Health resources - Education Program
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h5">
                                                Interactive Contact with Patient or Proxy within 2 days of discharge
                                            </Typography>
                                        </Grid>
                                        {/* <Grid item xs={12} lg={12} md={12}>
                                        <Typography variant="h4" sx={{ color: theme.palette.success.dark }}>
                                            Billing is on track.
                                        </Typography>
                                    </Grid> */}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {/* <MainCard
                    content={false}
                    title={
                        <Typography variant="h5" sx={{ color: theme.palette.error.dark }}>
                            TCM Off Track
                        </Typography>
                    }
                >
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Activities Planned</TableCell>
                                    <TableCell>Activities Completed</TableCell>
                                    <TableCell>Days Remaining</TableCell>
                                    <TableCell>Major Event Pending</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow hover key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.planned}
                                        </TableCell>
                                        <TableCell>{row.completed}</TableCell>
                                        <TableCell>{row.dayspending}</TableCell>
                                        <TableCell>
                                            <Chip sx={{ px: 0.08 }} chipcolor="error" size="small" label="Face to Face meeting" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard> */}
                {/* <TableContainer>
                    <Table
                        size="small"
                        sx={{
                            border: '1px solid',
                            borderColor: theme.palette.common.grey
                        }}
                    >
                        <TableBody>
                            {data.contents?.map((item, index) => (
                                <TableRow hover key={index}>
                                    <StyledSubTableCell>
                                        <b>{item[0]}</b>
                                    </StyledSubTableCell>
                                    <StyledSubTableCell>{item[1]}</StyledSubTableCell>

                                    <StyledSubTableCell align="center">
                                        {item[2] && <Chip sx={{ px: 0.08 }} chipcolor={item[2]} label={item[3]} />}
                                    </StyledSubTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> */}
            </Grid>
        </Grid>
    );
};

export default SummaryTab;
