import { Button, Dialog, IconButton, Divider, useTheme, DialogContent, Typography, Stack, DialogActions } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import PreviewAndPublishContents from './PreviewAndPublishContents';

const PreviewAndPublish = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
    return (
        <>
            <Button size="small" variant="contained" onClick={handleOpen}>
                Preview & Publish
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                // mWidth={mWidth}
                open={open}
                sx={{
                    '& .MuiDialog-paper': {
                        maxWidth: 'none',
                        width: '50%'
                        // height: 'calc(100% - 64px)'
                    },
                    '& .MuDialogContent-root': {
                        padding: theme.spacing(2)
                    },
                    '& .MuDialogActions-root': {
                        padding: theme.spacing(1)
                    }
                }}
            >
                <BootstrapDialogTitle onClose={handleClose} id="customized-dialog-title">
                    <Typography variant="h3">Care Program Summary</Typography>
                </BootstrapDialogTitle>
                <Divider />
                <DialogContent>
                    {/* <Grid container>
                        <Grid item xs={12} lg={12} md={12}>
                            <Typography variant="h3">Diabetic Care Program for Robert Hailey</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 1, pl: 4 }}>
                            <List component="div" disablePadding>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                                                Action Item 1
                                            </Typography>
                                        </>
                                    }
                                />
                                <List component="div" disablePadding sx={{ pl: 4 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h4">
                                                <b>Maintain healthy weight</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important' }}>
                                            <Typography variant="body1">Plan diet to achieve weight as 110 lbs</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important' }}>
                                            <Typography variant="body1">Plan exercise to achieve weight as 110 lbs</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important' }}>
                                            <Typography variant="body1">Achieve by 07/15/2022</Typography>
                                        </Grid>
                                    </Grid>
                                </List>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 2, pl: 4 }}>
                            <List component="div" disablePadding>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                                                Action Item 2
                                            </Typography>
                                        </>
                                    }
                                />
                                <List component="div" disablePadding sx={{ pl: 4 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h4">
                                                <b>Reduce AHI below 12</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </List>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 2, pl: 4 }}>
                            <List component="div" disablePadding>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                                                Action Item 3
                                            </Typography>
                                        </>
                                    }
                                />
                                <List component="div" disablePadding sx={{ pl: 4 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h4">
                                                <b>De-addiction and Recovery</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important' }}>
                                            <Typography variant="body1">Smoking</Typography>
                                        </Grid>
                                    </Grid>
                                </List>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 2, pl: 4 }}>
                            <List component="div" disablePadding>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                                                Action Item 4: Monitoring
                                            </Typography>
                                        </>
                                    }
                                />
                                <List component="div" disablePadding sx={{ pl: 4 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h4">
                                                <b>Monitor Weight monthly</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="h4">
                                                <b>Monitor Blood Pressure every 3 days</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Typography variant="body1" sx={{ pl: '30px !important' }}>
                                                Alert if the value of Systolic is 130 and Diastolic is 80 for 3 readings
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </List>
                            </List>
                        </Grid>
                    </Grid> */}
                    <PreviewAndPublishContents />
                </DialogContent>
                <Divider />
                <DialogActions align="left" sx={{ justifyContent: 'space-between', pl: 3, mt: 1 }}>
                    <Stack direction="row" spacing={1}>
                        <Button size="small" variant="contained" onClick={handleClose}>
                            Publish
                        </Button>
                        <Button size="small" variant="outlined" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PreviewAndPublish;
