import { Grid, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import Smoking from './smoking/Smoking';
import Alcohol from './alcohol/Alcohol';
import Depression from './depression/Depression';
import Stress from './stress/Stress';
import PhysicalActivity from './physicalactivity/PhysicalActivity';
import Education from './education/Education';
import SocialConnectionAndIsolation from './socialconnectionandisolation/SocialConnectionAndIsolation';
import ExposureToViolence from './exposuretoviolence/ExposureToViolence';
import FinancialResourceStrain from './financialresourcestrain/FinancialResourceStrain';

const BehaviouralHealthAndSocialProfile = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container spacing={gridSpacing}>
                {/* <Grid item xs={12} md={12} lg={12}>
                    <HealthRecordsBreadCrumbs
                        navigation={menuItems}
                        home="/healthrecords"
                        homeTitle="Health Records"
                        separator={NavigateNextIcon}
                        sx={{
                            mb: '0px !important',
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                        }}
                    />
                </Grid> */}
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                    <Typography variant="dashboardCardContent">
                        <b>
                            <FormattedMessage id="Behavioural_Health_And_Social_Profile" />
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Smoking />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Alcohol />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Depression />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Stress />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <PhysicalActivity />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Education />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <SocialConnectionAndIsolation />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <ExposureToViolence />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FinancialResourceStrain />
                </Grid>
            </Grid>
        </>
    );
};

export default BehaviouralHealthAndSocialProfile;
