import { useTheme, Grid, Typography, Checkbox, FormControlLabel, TextField, Button, MenuItem, Stack, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const AddMonitoringTab = () => {
    const theme = useTheme();
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mb: '10px !important'
    };
    return (
        <>
            <Grid container alignItems="center" spacing={1}>
                <Grid item container xs={12} lg={12} md={12}>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12} sx={{ pb: 2 }}>
                            <Typography variant="h5">Monitor Values</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container spacing={2}>
                                <Grid container item xs={12} lg={12} md={12} spacing={2} alignItems="center">
                                    <Grid item xs={4} lg={4} md={4}>
                                        <TextField
                                            disabled
                                            id="outlined-select-experience1"
                                            select
                                            defaultValue="weight"
                                            fullWidth
                                            label="Choose Vitals"
                                            size="small"
                                        >
                                            <MenuItem value="bp">Blood Pressure</MenuItem>
                                            <MenuItem value="sugar">Sugar</MenuItem>
                                            <MenuItem value="weight">Weight</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={4} lg={4} md={4}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <TextField
                                                id="outlined-select-experience1"
                                                select
                                                defaultValue="monthly"
                                                fullWidth
                                                label="Choose Frequency"
                                                size="small"
                                            >
                                                <MenuItem value="daily">Daily</MenuItem>
                                                <MenuItem value="weekly">Weekly</MenuItem>
                                                <MenuItem value="periodic">Periodic</MenuItem>
                                                <MenuItem value="monthly">Monthly</MenuItem>
                                            </TextField>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} align="right">
                                        <IconButton aria-label="expand row">
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12} sx={{ pb: 2 }}>
                            <Typography variant="h5">Monitor Values</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container spacing={2}>
                                <Grid container item xs={12} lg={12} md={12} spacing={2}>
                                    <Grid item xs={4} lg={4} md={4}>
                                        <TextField
                                            id="outlined-select-experience1"
                                            select
                                            defaultValue="bp"
                                            fullWidth
                                            label="Choose Vitals"
                                            size="small"
                                        >
                                            <MenuItem value="bp">Blood Pressure</MenuItem>
                                            <MenuItem value="sugar">Sugar</MenuItem>
                                            <MenuItem value="weight">Weight</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={4} lg={4} md={4}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <TextField
                                                id="outlined-select-experience1"
                                                select
                                                defaultValue="periodic"
                                                fullWidth
                                                label="Choose Frequency"
                                                size="small"
                                            >
                                                <MenuItem value="daily">Daily</MenuItem>
                                                <MenuItem value="weekly">Weekly</MenuItem>
                                                <MenuItem value="periodic">Periodic</MenuItem>
                                                <MenuItem value="monthly">Monthly</MenuItem>
                                            </TextField>
                                            <Typography variant="subTitle1">every</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} lg={4} md={4}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <TextField id="outlined-select-experience1" select fullWidth label="Choose Days" size="small">
                                                <MenuItem value="1">1</MenuItem>
                                                <MenuItem value="2">2</MenuItem>
                                                <MenuItem value="3">3</MenuItem>
                                            </TextField>
                                            <Typography variant="subTitle1">days</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} lg={12} md={12} spacing={1} alignItems="center">
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked name="alertvalue" />}
                                            label="Alert if the value is above"
                                        />
                                    </Grid>
                                    <Grid item xs={3.5} lg={3.5} md={3.5}>
                                        <TextField id="systolic" defaultValue="130" label="Enter Systolic Reading" size="small" />
                                    </Grid>
                                    <Grid item xs={3.5} lg={3.5} md={3.5}>
                                        <TextField id="diastolic" defaultValue="80" label="Enter Diastolic Reading" size="small" />
                                    </Grid>
                                    <Grid item xs={0.5} lg={0.5} md={0.5}>
                                        <Typography variant="subTitle1">for</Typography>
                                    </Grid>
                                    <Grid item xs={2.5} lg={2.5} md={2.5}>
                                        <TextField id="readings" label="Enter Readings" size="small" />
                                    </Grid>
                                    <Grid item xs={1} lg={1} md={1}>
                                        <Typography variant="subTitle1">readings</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} lg={12} md={12} spacing={1} alignItems="center">
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Button size="small" variant="outlined" startIcon={<AddCircleIcon />}>
                                            Add New
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item container xs={12} lg={12} md={12} sx={{ pt: '15px !important' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Stack direction="row" spacing={1}>
                                <Button size="small" variant="contained">
                                    Preview & Publish
                                </Button>
                                <Button size="small" variant="outlined">
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </>
    );
};

export default AddMonitoringTab;
