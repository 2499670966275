import PropTypes from 'prop-types';
import { memo, useMemo, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Stack, useMediaQuery, Typography, Avatar } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
// import MenuCard from './MenuCard';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import Chip from 'ui-component/extended/Chip';
import MenuListCollapsed from './MenuListCollapsed';
import { IconMenu2 } from '@tabler/icons';
import CollapsedLogoSection from '../LogoSection/CollapsedLogoSection';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const today = new Date();
    const year = today.getFullYear();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { menuWidth } = useSelector((state) => state.menu);

    const logo = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box sx={{ display: 'inline-flex', p: 2, mx: 'auto' }}>
                        <LogoSection />
                    </Box>
                    <Box sx={{ display: 'inline-flex', p: 2, mx: 'auto' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.largeAvatar,
                                overflow: 'hidden',
                                transition: 'all .2s ease-in-out',
                                // border: '1px solid',
                                // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.tertiary.tertiary4
                                // '&:hover': {
                                //     background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                //     color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                                // }
                            }}
                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                            color="inherit"
                        >
                            <KeyboardDoubleArrowLeftIcon />
                        </Avatar>
                    </Box>
                </Stack>
            </Box>
        ),
        [drawerOpen]
    );

    const logoCollapsed = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Stack direction="row" alignItems="center">
                    <Box sx={{ display: 'inline-flex', mx: 'auto', pl: 2, pt: 2 }}>
                        <CollapsedLogoSection />
                    </Box>
                    <Box component="span" sx={{ pt: 2, display: { xs: 'none', md: 'block' } }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.largeAvatar,
                                overflow: 'hidden',
                                transition: 'all .2s ease-in-out',
                                background: 'transparent',
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.tertiary.tertiary4
                            }}
                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                            color="inherit"
                        >
                            <KeyboardDoubleArrowRightIcon />
                        </Avatar>
                    </Box>
                </Stack>
            </Box>
        ),
        [drawerOpen]
    );

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '0px',
                    paddingRight: '0px'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <div>
                        <MenuList />
                    </div>
                    <Stack direction="row" justifyContent="center" sx={{ mb: 2, borderTop: '1px solid #eeee', pt: 2 }}>
                        <Typography variant="body1" sx={{ color: theme.palette.grey[500] }} align="center">
                            © {year} Vital eCare {process.env.REACT_APP_VERSION}
                        </Typography>
                    </Stack>
                </div>
            </PerfectScrollbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd]
    );

    const drawerCollapsed = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '0px',
                    paddingRight: '0px'
                }}
            >
                <MenuListCollapsed />

                <Stack direction="column" justifyContent="center" sx={{ mb: 2, px: 1 }} align="center">
                    <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
                        © {year}
                    </Typography>
                    <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
                        Vital eCare {process.env.REACT_APP_VERSION}
                    </Typography>
                </Stack>
            </PerfectScrollbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd]
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={matchUpMd ? { flexShrink: { md: 0 }, width: menuWidth } : {}} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                // variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: menuWidth,
                        background: theme.palette.primary.light,
                        color: theme.palette.text.paper,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '0px'
                        }
                    },
                    '& .MuiListItemIcon-root': {
                        color: theme.palette.secondary.main,
                        minWidth: '46px',
                        paddingRight: 0
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && logo}
                {!drawerOpen && logoCollapsed}
                {drawerOpen && drawer}
                {!drawerOpen && drawerCollapsed}
                {/* {drawerOpen && logo}
                {drawerOpen && drawer} */}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    window: PropTypes.object
};

export default memo(Sidebar);
