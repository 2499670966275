import { styled, useTheme, Paper, Grid, ToggleButtonGroup, ToggleButton, Typography, Stack, Button, TextField } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import VitalsCardView from './VitalsCardView';
import { useState } from 'react';
import HealthRecordsBreadCrumbs from '../HealthRecordsBreadCrumbs';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import BloodPressureTable from './bloodpressure/BloodPressureTable';
import VitalsGraphView from './VitalsGraphView';
import Filter from './Filter';

const VitalsListView = () => {
    const theme = useTheme();
    const [date, setDate] = useState(null);
    const [addBP, setAddBP] = useState(false);

    const filterCardStyleList = {
        pt: '15px',
        mt: '10px',
        ml: '25px',
        mr: '0px',
        pb: '15px',
        pr: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.white,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };
    const addDiv = {
        pt: '15px',
        mt: '10px',
        ml: '25px',
        mr: '0px',
        pb: '15px',
        pr: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const noDataDiv = {
        mt: '20px',
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
        color: theme.palette.common.dark
    };

    return (
        <>
            <Grid container spacing={3}>
                {/* <Filter cardStyle={filterCardStyleList} /> */}
                <Grid item xs={12} md={12} lg={12} spacing={gridSpacing}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h5" sx={{ pt: 1 }}>
                            Blood Pressure
                        </Typography>
                        {/* <Button size="small" variant="contained" onClick={() => setAddBP((prev) => !prev)}>
                            Add Blood Pressure
                        </Button> */}
                    </Stack>
                </Grid>
                {addBP && (
                    <Grid item xs={12} md={12} lg={12} sx={addDiv}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Add Blood Pressure</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                                fullWidth
                                                label="Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField fullWidth size="small" label="Systolic Blood Pressure" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField fullWidth size="small" label="Diastolic Blood Pressure" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" onClick={() => setAddBP(!addBP)}>
                                        Save
                                    </Button>

                                    <Button variant="outlined" onClick={() => setAddBP(!addBP)}>
                                        Close
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12} md={12} lg={12}>
                    <BloodPressureTable showAll={false} condensedCount={4} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" sx={{ pt: 1 }}>
                                Blood Oxygen
                            </Typography>
                            {/* <Button size="small" variant="contained">
                                Add Blood Oxygen
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubCard sx={noDataDiv}>No data available</SubCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" sx={{ pt: 1 }}>
                                ECG
                            </Typography>
                            {/* <Button size="small" variant="contained">
                                Add ECG
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubCard sx={noDataDiv}>No data available</SubCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" sx={{ pt: 1 }}>
                                A1C
                            </Typography>
                            {/* <Button size="small" variant="contained">
                                Add A1C
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubCard sx={noDataDiv}>No data available</SubCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" sx={{ pt: 1 }}>
                                Heart Rate & HRV
                            </Typography>
                            {/* <Button size="small" variant="contained">
                                Add Heart Rate & HRV
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubCard sx={noDataDiv}>No data available</SubCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" sx={{ pt: 1 }}>
                                Energy Level
                            </Typography>
                            {/* <Button size="small" variant="contained">
                                Add Energy Level
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubCard sx={noDataDiv}>No data available</SubCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" sx={{ pt: 1 }}>
                                Weight/Height
                            </Typography>
                            {/* <Button size="small" variant="contained">
                                Add Weight/Height
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubCard sx={noDataDiv}>No data available</SubCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" sx={{ pt: 1 }}>
                                Body Temperature
                            </Typography>
                            {/* <Button size="small" variant="contained">
                                Add Body Temperature
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubCard sx={noDataDiv}>No data available</SubCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" sx={{ pt: 1 }}>
                                Mood
                            </Typography>
                            {/* <Button size="small" variant="contained">
                                Add Mood
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <SubCard sx={noDataDiv}>No data available</SubCard>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default VitalsListView;
