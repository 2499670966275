import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions, Typography, Stack, Grid } from '@mui/material';
import fire from '../../../assets/images/goals/fire-minimalistic-svgrepo-com.png';
import date from '../../../assets/images/goals/weight-svgrepo-com.png';
import calander from '../../../assets/images/goals/calander.png';
import { useTheme, styled } from '@mui/material/styles';
import { weightgoalicon, dateiconbkg, startweighticonbkg } from '../../../assets/customstyle/Goalstyle';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));
export default function Currentstatus() {
    const theme = useTheme();
    return (
        <>
            <Card>
                <Grid
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '10px 20px 0 10px',
                        borderBottom: '1px solid #d7d3d3',
                        paddingBottom: '10px'
                    }}
                >
                    <Grid
                        sx={{
                            border: '1px solid #1D5DDF',
                            borderRadius: '100px',
                            padding: '5px 10px',
                            background: theme.palette.primary.light
                        }}
                    >
                        {' '}
                        <Typography sx={{ color: '#1D5DDF', textAlign: 'center' }}>In progress</Typography>
                    </Grid>
                </Grid>

                <Stack
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                        padding: '15px',
                        flexDirection: 'row',
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        }
                    }}
                >
                    <Item
                        sx={{
                            display: 'flex',
                            background: theme.palette.goalsummary.startweightbkg,
                            bordercolor: theme.palette.goalsummary.weightgoalcardborder,
                            borderRadius: '10px',
                            padding: '0 10px',
                            justifyContent: 'flex-start',
                            cursor: 'unset',
                            alignItems: 'center'
                        }}
                    >
                        <Typography variant="div" sx={startweighticonbkg}>
                            <img src={date} alt="logo" />
                        </Typography>
                        <div style={{ padding: '10px', textAlign: 'left' }}>
                            <Typography gutterBottom variant="h4" component="div" sx={{ marginBottom: '0' }}>
                                220.5 lbs
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Start Weight
                            </Typography>
                        </div>
                    </Item>
                    <Item
                        sx={{
                            display: 'flex',
                            background: theme.palette.goalsummary.targetweight,
                            bordercolor: theme.palette.goalsummary.weightgoalcardborder,
                            borderRadius: '10px',
                            padding: '0 10px',
                            justifyContent: 'flex-start',
                            cursor: 'unset',
                            alignItems: 'center'
                        }}
                    >
                        <Typography variant="div" sx={dateiconbkg}>
                            <img src={fire} alt="logo" />
                        </Typography>
                        <div style={{ padding: '10px', textAlign: 'left' }}>
                            <Typography gutterBottom variant="h4" component="div" sx={{ marginBottom: '0' }}>
                                207.2 lbs
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Target Weight
                            </Typography>
                        </div>
                    </Item>
                    <Item
                        sx={{
                            display: 'flex',
                            background: theme.palette.goalsummary.startweightbkg,
                            bordercolor: theme.palette.goalsummary.weightgoalcardborder,
                            borderRadius: '10px',
                            padding: '10px',
                            justifyContent: 'flex-start',
                            cursor: 'unset',
                            alignItems: 'center'
                        }}
                    >
                        <Typography variant="div" sx={startweighticonbkg}>
                            <img src={calander} alt="logo" />
                        </Typography>
                        <div style={{ padding: '10px', textAlign: 'left' }}>
                            <Typography gutterBottom variant="h4" component="div" sx={{ marginBottom: '0' }}>
                                02-24-2023
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Start Date
                            </Typography>
                        </div>
                    </Item>
                    <Item
                        sx={{
                            display: 'flex',
                            background: theme.palette.goalsummary.targetweight,
                            bordercolor: theme.palette.goalsummary.weightgoalcardborder,
                            borderRadius: '10px',
                            padding: '0 10px',
                            justifyContent: 'flex-start',
                            cursor: 'unset',
                            alignItems: 'center'
                        }}
                    >
                        <Typography variant="div" sx={dateiconbkg}>
                            <img src={calander} alt="logo" />
                        </Typography>
                        <div style={{ padding: '10px', textAlign: 'left' }}>
                            <Typography gutterBottom variant="h4" component="div" sx={{ marginBottom: '0' }}>
                                08-14-2023
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Ending Date
                            </Typography>
                        </div>
                    </Item>
                </Stack>
            </Card>
        </>
    );
}
