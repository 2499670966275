import { Grid, useTheme, FormControlLabel, Checkbox, Button, TextField } from '@mui/material';

const CycleActivity = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12} md={12}>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Completed 30 minutes review" />
                </Grid>
                <Grid item xs={6} lg={6} md={6}>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Interactive minutes spent with the patient" />
                </Grid>
                <Grid item xs={4} lg={4} md={4}>
                    <TextField size="small" label="Enter Minutes" />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Button variant="contained" size="small">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default CycleActivity;
