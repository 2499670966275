import { useTheme, Grid, Typography, Button, Stack } from '@mui/material';
import DevicesTable from './table/DevicesTable';

const DevicesTab = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container spacing={2}>
                <Grid container item xs={12} lg={12} md={12} alignItems="center" sx={{ mt: 1 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="dashboardCardContent">
                            <b>Devices</b>
                        </Typography>
                        <Button size="small" variant="contained">
                            Add Device
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={12} md={12} alignItems="center">
                    <DevicesTable />
                </Grid>
            </Grid>
        </>
    );
};

export default DevicesTab;
