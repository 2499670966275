import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Box,
    IconButton,
    Grid,
    Tab,
    Tabs,
    Typography,
    Stack,
    MenuItem,
    Select,
    FormControl,
    Button,
    InputLabel,
    Link,
    useMediaQuery
} from '@mui/material';
import RobertHailey from '../../../assets/images/users/robert_hailey_patient.png';
import Avatar from 'ui-component/extended/Avatar';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { gridSpacing } from 'store/constant';
import HealthrecordsTab from '../patients/tabs/HealthrecordsTab';
import TCMTabs from '../patients/tabs/TCMTabs';
import DreamsTabs from '../patients/tabs/DreamsTabs';
import RPMTabs from '../patients/tabs/RPMTabs';
import CareTeamTab from '../patients/tabs/tabcontents/careteam/CareTeamTab';
import SummaryTab from '../patients/tabs/tabcontents/summary/SummaryTab';
import ActivityTabs from '../patients/tabs/ActivityTabs';
import AppointmentsTab from '../patients/tabs/tabcontents/appointments/AppointmentsTab';
import Tabmessages from '../patients/tabs/tabcontents/tabmessages';
import CarePlansTab from '../patients/tabs/tabcontents/careplans/CarePlansTab';
import SurveysTab from '../patients/tabs/tabcontents/surveys/SurveysTab';
import MuiTypography from '@mui/material/Typography';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `patientdetailstab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const tabsOption = [
    { label: <Typography variant="tabText">Snapshots</Typography>, value: 0 },
    { label: <Typography variant="tabText">DREAMS</Typography>, value: 1 },
    { label: <Typography variant="tabText">TCM</Typography>, value: 2 },
    { label: <Typography variant="tabText">RPM</Typography>, value: 3 },
    { label: <Typography variant="tabText">Health Records</Typography>, value: 4 },
    { label: <Typography variant="tabText">Activity</Typography>, value: 5 },
    { label: <Typography variant="tabText">Care Team</Typography>, value: 6 }
];

const selectOption = [
    { label: 'Appointments', value: 7 },
    { label: 'Messages', value: 8 },
    { label: 'Care Plans', value: 9 },
    { label: 'Surveys', value: 10 }
];

const MainContentTabs = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const isSmallScreen = useMediaQuery('(max-width:1280px)');

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDropdownChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} lg={12} md={12}>
                <Card>
                    <CardContent
                        sx={{
                            p: '10px !important',
                            backgroundColor: '#f0f7fc'
                        }}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={1} lg={1} md={1} sx={{ pt: 1 }}>
                                <IconButton onClick={() => navigate('/proxyfamily')}>
                                    <NavigateBeforeIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={1} lg={1} md={1}>
                                <Avatar alt="Roger Miles" src={RobertHailey} sx={{ width: '70px', height: '70px' }} />
                            </Grid>
                            <Grid item xs={8} lg={8} md={8} sx={{ pl: 3 }}>
                                <Stack spacing={0.1} direction="column">
                                    <Typography variant="subtitle1">Robert Hailey | 02/22/1990 | Male | #4634821456</Typography>
                                    <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap', display: 'flex' }}>
                                        <MuiTypography
                                            variant="body2"
                                            color="primary"
                                            component={Link}
                                            href="tel:5555551234"
                                            display="block"
                                            underline="hover"
                                            gutterBottom
                                        >
                                            + (555) 555-1234
                                        </MuiTypography>
                                        <Typography variant="body2"> | </Typography>
                                        <MuiTypography
                                            variant="body2"
                                            color="primary"
                                            component={Link}
                                            href="mailto:roberthailey@gmail.com"
                                            target="_blank"
                                            display="block"
                                            underline="hover"
                                            gutterBottom
                                        >
                                            roberthailey@gmail.com
                                        </MuiTypography>
                                        <Typography variant="body2"> | </Typography>
                                        <Typography variant="body1">123 Street, Las Lane, NM-2345678</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} lg={2} md={2}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {/* <Grid item xs={12} lg={12} md={12} align="right">
                                                                    <Tooltip title="Secure Messaging">
                                                                        <IconButton color="primary">
                                                                            <EmailIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid> */}
                                    {/* <Grid item xs={6} lg={6} md={6}>
                                                                    <Tooltip title="Virtual Visit">
                                                                        <IconButton color="primary">
                                                                            <VideocamIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pt: '8px !important' }}>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        padding: isSmallScreen ? '20px 50px 10px 0' : '20px 0px 10px 0'
                    }}
                >
                    <Tabs
                        value={value < 7 ? value : false}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={{
                            mb: 1,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 0,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& .MuiTabs-indicator': {
                                bottom: 2
                            },
                            '& a > svg': {
                                marginBottom: '0px !important',
                                mr: 1.25
                            }
                        }}
                    >
                        {tabsOption.map((tab) => (
                            <Tab key={tab.value} icon={tab.icon} label={tab.label} {...a11yProps(tab.value)} />
                        ))}
                    </Tabs>
                    <FormControl variant="standard">
                        {/* <InputLabel id="dropdown-label" sx={{ position: 'absolute', top: '-4px' }}>
                            Select Tab
                        </InputLabel> */}
                        <Select
                            labelId="dropdown-label"
                            id="dropdown"
                            value={value >= 7 ? value : ''}
                            onChange={handleDropdownChange}
                            sx={{
                                width: 'auto',
                                alignItems: 'center',
                                display: 'flex',
                                position: 'relative',
                                bottom: '-10px',
                                '&:before, &:after': {
                                    borderBottom: 'none !important'
                                }
                            }}
                        >
                            {selectOption.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <TabPanel value={value} index={0}>
                    <SummaryTab />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <DreamsTabs />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TCMTabs />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <RPMTabs />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <HealthrecordsTab />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <ActivityTabs />
                </TabPanel>
                <TabPanel value={value} index={6}>
                    <CareTeamTab />
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <AppointmentsTab />
                </TabPanel>
                <TabPanel value={value} index={8}>
                    <Tabmessages />
                </TabPanel>
                <TabPanel value={value} index={9}>
                    <CarePlansTab />
                </TabPanel>
                <TabPanel value={value} index={10}>
                    <SurveysTab />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default MainContentTabs;
