// material-ui

import { gridSpacing } from 'store/constant';
import React, { useState, useRef } from 'react';
import ProblemsTable from './components/ProblemsTable';
import AllergiesAdverseReactionTable from './components/AllergiesAndAdverseReactions';
import HSMedicationsTable from './components/HSMedicationsTable';
import ImmunizationTable from './components/ImmunizationTable';
import LabResultsTable from './components/LabResultsTable';
import VitalSignsTable from './components/VitalSignsTable';
import HSFamilyHistoryTable from './components/HSFamilyHistoryTable';
import PayersTable from './components/PayersTable';
import SocialHistoryTable from './components/SocialHistoryTable';
import MedicalEquipmentTable from './components/MedicalEquipmentTable';
import ProceduresAndSurgeriesTable from './components/ProceduresAndSurgeriesTable';
import HealthSummaryInitialTable from './components/HealthSummaryInitialTable';
import MainCard from 'ui-component/cards/MainCard';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material/styles';

import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Stack,
    Typography,
    Grid,
    Chip,
    MenuItem,
    TextField,
    styled,
    tableCellClasses,
    Modal,
    CardContent,
    CardActions,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';

// project imports

// ==============================|| PROFILE ||============================== //
const TableOfContents = [
    { id: 1, title: 'Problems' },
    { id: 2, title: 'Allergies and Adverse Reactions' },
    { id: 3, title: 'Medications' },
    { id: 4, title: 'Immunizations' },
    { id: 5, title: 'Procedures and Surgeries' },
    { id: 6, title: 'Lab Results' },
    { id: 7, title: 'Vital Signs' },
    { id: 8, title: 'Family History' },
    { id: 9, title: 'Payers' },
    { id: 10, title: 'Social History' },
    { id: 11, title: 'Medical Equipment' }
];

const ViewHealthSummary = () => {
    const theme = useTheme();
    const refs = TableOfContents.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
    }, {});
    // const refs = useRef([]);
    const handleClick = (id) =>
        refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                <Typography variant="dashboardCardContent">
                    <b>Summary</b>
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <MainCard
                    /*  sx={{
                        width: 1100,
                        zIndex: 1
                    }} */
                    // title={
                    //     <>
                    //         <center>Consolidated health records received for the appointment </center>
                    //     </>
                    // }
                    content={false}
                    align="left"
                >
                    <HealthSummaryInitialTable />
                    <Divider />
                    <br />
                    <Grid item sx={{ pl: 3 }}>
                        <Typography variant="h4" color={theme.palette.text.dark} gutterBottom>
                            Table Of Contents
                        </Typography>
                    </Grid>
                    {TableOfContents.map((item) => (
                        <>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ fontSize: '0.5rem' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} onClick={() => handleClick(item.id)} />
                                </ListItemButton>
                            </List>
                        </>
                    ))}
                    <Divider />
                    <div ref={refs[1]}>
                        <ProblemsTable />
                    </div>
                    <div ref={refs[2]}>
                        <AllergiesAdverseReactionTable />
                    </div>
                    <div ref={refs[3]}>
                        <HSMedicationsTable />
                    </div>
                    <div ref={refs[4]}>
                        <ImmunizationTable />
                    </div>
                    <div ref={refs[5]}>
                        <ProceduresAndSurgeriesTable />
                    </div>
                    <div ref={refs[6]}>
                        <LabResultsTable />
                    </div>
                    <div ref={refs[7]}>
                        <VitalSignsTable />
                    </div>
                    <div ref={refs[8]}>
                        <HSFamilyHistoryTable />
                    </div>
                    <div ref={refs[9]}>
                        <PayersTable />
                    </div>
                    <div ref={refs[10]}>
                        <SocialHistoryTable />
                    </div>
                    <div ref={refs[11]}>
                        <MedicalEquipmentTable />
                    </div>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default ViewHealthSummary;
