import { Grid, Typography, CardActions, CardContent, Button, Link, Card } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Avatar from 'ui-component/extended/Avatar';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';

// assets
import ClockIcon from '@mui/icons-material/AccessTime';

const Timeline = () => {
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const paper = {
        p: 2,
        boxShadow: 'none',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px dashed',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.dark
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12}>
                    <Card sx={cardStyle}>
                        <CardContent sx={{ p: '16px !important' }}>
                            <div style={{ padding: 35 }}>
                                <Grid
                                    container
                                    spacing={gridSpacing}
                                    alignItems="center"
                                    sx={{
                                        position: 'relative',
                                        '&>*': {
                                            position: 'relative',
                                            zIndex: '5'
                                        },
                                        '&:after': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '0',
                                            left: 130,
                                            width: 2,
                                            height: '100%',
                                            background: '#ebebeb',
                                            zIndex: '1'
                                        }
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography align="left" variant="body1">
                                                            05/25/2022
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Avatar color="info">
                                                            <ClockIcon />
                                                        </Avatar>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            component="div"
                                                            align="left"
                                                            variant="dashboardCardContent"
                                                            sx={{ color: theme.palette.black.black }}
                                                        >
                                                            Received first data reading
                                                        </Typography>
                                                        <Typography component="div" align="left" variant="body1">
                                                            11:01 AM
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography align="left" variant="body1">
                                                            05/25/2022
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Avatar color="info">
                                                            <ClockIcon />
                                                        </Avatar>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            component="div"
                                                            align="left"
                                                            variant="dashboardCardContent"
                                                            sx={{ color: theme.palette.black.black }}
                                                        >
                                                            Non face to face arranged
                                                        </Typography>
                                                        <Typography component="div" align="left" variant="body1">
                                                            6:26 AM
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography align="left" variant="body1">
                                                            05/24/2022
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Avatar color="info">
                                                            <ClockIcon />
                                                        </Avatar>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            component="div"
                                                            align="left"
                                                            variant="dashboardCardContent"
                                                            sx={{ color: theme.palette.black.black }}
                                                        >
                                                            Education materials sent
                                                        </Typography>
                                                        <Typography component="div" align="left" variant="body1">
                                                            {/* Download the{' '}
                                            <Link component={RouterLink} to="#" underline="hover">
                                                latest backup
                                            </Link>
                                            . */}
                                                            11:01 AM
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography align="left" variant="body1">
                                                            05/24/2022
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Avatar color="info">
                                                            <ClockIcon />
                                                        </Avatar>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            component="div"
                                                            align="left"
                                                            variant="dashboardCardContent"
                                                            sx={{ color: theme.palette.black.black }}
                                                        >
                                                            Fit bit and oximeter devices sent
                                                        </Typography>
                                                        <Typography component="div" align="left" variant="body1">
                                                            6:26 AM
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography align="left" variant="body1">
                                                            05/23/2022
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Avatar color="info">
                                                            <ClockIcon />
                                                        </Avatar>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            component="div"
                                                            align="left"
                                                            variant="dashboardCardContent"
                                                            sx={{ color: theme.palette.black.black }}
                                                        >
                                                            Initiated to RPM
                                                        </Typography>
                                                        <Typography component="div" align="left" variant="body1">
                                                            11:01 AM
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} lg={12} md={12} sx={{ mt: 1 }}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Button variant="text" size="small">
                                            View more
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                        {/* <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <Button variant="text" size="small">
                                View more
                            </Button>
                        </CardActions> */}
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default Timeline;
