// material-ui
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import Masonry from '@mui/lab/Masonry';
import { Box, Button, Card, CardContent, Dialog, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import SubCard from 'ui-component/cards/SubCard';
import BloodPressureAreaChart from './bloodpressure/BloodPressureAreaChart';
import Filter from './Filter';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const VitalsGraphView = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const cards = [
        {
            title: 'Blood Pressure',
            subcards: [{ title: '05/05/2022 7:30 AM [PST]', subTitle: 'Systolic/Diastolic Blood Pressure (141/76)' }],
            viewAll: true,
            navigate: '/healthrecords/vitals/bloodpressure',
            graphView: true
        },
        {
            title: 'Blood Oxygen',
            subcards: [{ subTitle: 'No data available' }],
            graphView: false
        },
        {
            title: 'ECG',
            subcards: [{ subTitle: 'No data available' }],
            graphView: false
        },
        {
            title: 'A1C',
            subcards: [{ subTitle: 'No data available' }],
            graphView: false
        },
        {
            title: 'Heart Rate & HRV',
            subcards: [{ subTitle: 'No data available' }],
            graphView: false
        },
        {
            title: 'Energy Level',
            subcards: [{ subTitle: 'No data available' }],
            graphView: false
        },
        {
            title: 'Weight/Height',
            subcards: [{ subTitle: 'No data available' }],
            graphView: false
        },
        {
            title: 'Body Temperature',
            subcards: [{ subTitle: 'No data available' }],
            graphView: false
        },
        {
            title: 'Mood',
            subcards: [{ subTitle: 'No data available' }],
            graphView: false
        }
    ];

    const theme = useTheme();
    const navigate = useNavigate();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const handleClick = (card) => {
        if (card.navigate !== undefined) {
            navigate(card.navigate);
        }
    };
    const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
    const filterCardStyleGraph = {
        pt: '15px',
        mt: '10px',
        ml: '25px',
        mr: '25px',
        pb: '15px',
        pr: '15px',
        pl: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.white,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };
    return (
        <>
            <Grid container spacing={gridSpacing} pt="12px">
                <Filter cardStyle={filterCardStyleGraph} />
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Showing Results for{' '}
                        <>
                            {' '}
                            <b>This Week</b>
                        </>
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Box sx={{ minHeight: 393 }}>
                        <Masonry columns={3} spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
                            {cards.map((card, index) => (
                                <SubCard key={index} style={{ border: '0.1px solid #D3D3D3' }} sx={cardStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10} md={10} lg={10}>
                                            <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                                                {card.title}
                                            </MuiTypography>
                                        </Grid>
                                        {card.title === 'Blood Pressure' ? (
                                            <Grid item xs={2} md={2} lg={2}>
                                                <IconButton onClick={handleOpen}>
                                                    <OpenInFullIcon />
                                                </IconButton>
                                            </Grid>
                                        ) : (
                                            ''
                                        )}
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Card style={{ border: '1px solid #e9e8e8' }}>
                                                <CardContent sx={{ pt: 2, pb: 1 }}>
                                                    {card.subcards.map((content, index) => (
                                                        <>
                                                            {index > 0 && <Divider />}
                                                            {card.graphView === false ? (
                                                                <Grid container>
                                                                    <Grid item xs={12} sx={{ pt: 1, pb: 0 }}>
                                                                        <Typography variant="subtitle1">{content.title}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{ pt: 0, pb: 1 }}>
                                                                        <Typography variant="body1">{content.subTitle}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ) : (
                                                                // <BloodPressureLineChart />
                                                                <Grid container>
                                                                    <Grid item xs={12} onClick={handleOpen}>
                                                                        <BloodPressureAreaChart height={300} />
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        </>
                                                    ))}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={8} lg={8} md={8} />
                                        {card.viewAll && (
                                            <Grid item xs={4} lg={4} md={4} align="right">
                                                <Button variant="text" size="small" onClick={() => handleClick(card)}>
                                                    <FormattedMessage id="View_Details" />
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </SubCard>
                            ))}
                        </Masonry>
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                mWidth="md"
                open={open}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        maxWidth: 'none',
                        width: 'md',
                        height: 'calc(100% - 64px)'
                    },
                    '& .MuDialogContent-root': {
                        padding: theme.spacing(2)
                    },
                    '& .MuDialogActions-root': {
                        padding: theme.spacing(1)
                    }
                }}
            >
                <BootstrapDialogTitle onClose={handleClose} id="customized-dialog-title">
                    Blood Pressure
                </BootstrapDialogTitle>
                <Divider />
                <Grid container sx={{ pt: 3, p: 2 }}>
                    <Grid item xs={12}>
                        <BloodPressureAreaChart height={500} />
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export default VitalsGraphView;
