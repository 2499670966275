import HealthRecordsBreadCrumbs from '../HealthRecordsBreadCrumbs';
import {
    Grid,
    useTheme,
    Card,
    TextField,
    CardContent,
    MenuItem,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Typography,
    Select,
    OutlinedInput,
    Checkbox,
    ListItemText,
    InputLabel
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { gridSpacing } from 'store/constant';
import { useIntl, FormattedMessage } from 'react-intl';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const Demographics = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const intl = useIntl();
    const [dob, setDOB] = useState('01/01/1990');
    const [genderIdentity, setGenderIdentity] = useState(null);
    const [selfDescribe, setSelfDescribe] = useState(false);
    const [sexualOrientation, setSexualOrientation] = useState(null);
    useEffect(() => {
        if (sexualOrientation === '4') {
            setSelfDescribe(true);
        } else {
            setSelfDescribe(false);
        }
    }, [sexualOrientation]);
    const [selfIdentifyDiv, setSelfIdentifyDiv] = useState(false);
    useEffect(() => {
        if (genderIdentity === '6') {
            setSelfIdentifyDiv(true);
        } else {
            setSelfIdentifyDiv(false);
        }
    }, [genderIdentity]);
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };
    const menuItems = {
        items: [
            {
                id: 'sample-docs-roadmap',
                type: 'group',
                children: [
                    {
                        id: 'demographics',
                        title: 'Demographics',
                        type: 'item',
                        url: '/healthrecords/demographics',
                        breadcrumbs: true
                    }
                ]
            }
        ]
    };
    // const [race, setRace] = useState('1');
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const races = [
        `${intl.formatMessage({ id: 'American_Indian_or_Alaska_Native' })}`,
        `${intl.formatMessage({ id: 'Asian' })}`,
        `${intl.formatMessage({ id: 'Black_or_African_American' })}`,
        `${intl.formatMessage({ id: 'Native_Hawaiian_or_Other_Pacific_Islander' })}`,
        `${intl.formatMessage({ id: 'White' })}`,
        `${intl.formatMessage({ id: 'Declined_to_state' })}`
    ];
    const [race, setRace] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setRace(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };
    return (
        <>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} md={12} lg={12}>
                    <HealthRecordsBreadCrumbs
                        home="/healthrecords"
                        homeTitle="Health Records"
                        navigation={menuItems}
                        separator={NavigateNextIcon}
                        sx={{
                            mb: '0px !important',
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                        }}
                    />
                </Grid> */}
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                    <Typography variant="dashboardCardContent">
                        <b>
                            <FormattedMessage id="Demographics" />
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Card sx={cardStyle}>
                        <CardContent sx={{ p: '16px !important' }}>
                            <Grid container spacing={3} sx={{ pb: 3 }}>
                                <Grid item sx={{ mt: 1, mb: 1 }} xs={12} lg={12} md={12}>
                                    <Typography variant="h5">
                                        <FormattedMessage id="Basic_Information" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="firstname"
                                        label={intl.formatMessage({ id: 'First_Name' })}
                                        type="text"
                                        fullWidth
                                        defaultValue="Robert"
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        size="small"
                                        disabled
                                        id="middle"
                                        label={intl.formatMessage({ id: 'Middle_Name' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        size="small"
                                        disabled
                                        id="lastname"
                                        label={intl.formatMessage({ id: 'Last_Name' })}
                                        type="text"
                                        defaultValue="Hailey"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => <TextField fullWidth size="small" {...props} helperText="" />}
                                            label={intl.formatMessage({ id: 'Date_of_Birth' })}
                                            value={dob}
                                            disabled
                                            onChange={(newValue) => {
                                                setDOB(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <TextField id="gender" select fullWidth label={intl.formatMessage({ id: 'Gender' })} size="small">
                                        <MenuItem value="male">
                                            <FormattedMessage id="Male" />
                                        </MenuItem>
                                        <MenuItem value="female">
                                            <FormattedMessage id="Female" />
                                        </MenuItem>
                                        <MenuItem value="other">
                                            <FormattedMessage id="Other" />
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ pb: 3 }}>
                                <Grid item sx={{ mt: 1, mb: 1 }} xs={12} lg={12} md={12}>
                                    <Typography variant="h5">
                                        <FormattedMessage id="Additional_Information" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        size="small"
                                        disabled
                                        id="nickname"
                                        label={intl.formatMessage({ id: 'Nick_Name' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <TextField id="title" size="small" select fullWidth label={intl.formatMessage({ id: 'Title' })}>
                                        <MenuItem value="1">
                                            <FormattedMessage id="Dr." />
                                        </MenuItem>
                                        <MenuItem value="2">
                                            <FormattedMessage id="Mr." />
                                        </MenuItem>
                                        <MenuItem value="3">
                                            <FormattedMessage id="Ms." />
                                        </MenuItem>
                                        <MenuItem value="4">
                                            <FormattedMessage id="Mrs." />
                                        </MenuItem>
                                        <MenuItem value="5">
                                            <FormattedMessage id="Prof." />
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        id="orientation"
                                        disabled
                                        size="small"
                                        select
                                        fullWidth
                                        label={intl.formatMessage({ id: 'Ethnicity' })}
                                    >
                                        <MenuItem value="1">
                                            <FormattedMessage id="Hispanic_or_Latino" />
                                        </MenuItem>
                                        <MenuItem value="2">
                                            <FormattedMessage id="Not_Hispanic_or_Latino" />
                                        </MenuItem>
                                        <MenuItem value="3">
                                            <FormattedMessage id="Declined_to_state" />
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="suffix"
                                        label={intl.formatMessage({ id: 'Suffix' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <TextField
                                        disabled
                                        id="orientation"
                                        size="small"
                                        value={sexualOrientation}
                                        onChange={(e) => {
                                            setSexualOrientation(e.target.value);
                                        }}
                                        select
                                        fullWidth
                                        label={intl.formatMessage({ id: 'Sexual_Orientation' })}
                                    >
                                        <MenuItem value="1">
                                            <FormattedMessage id="Straight_or_heterosexual" />
                                        </MenuItem>
                                        <MenuItem value="2">
                                            <FormattedMessage id="Lesbian_gay_or_homosexual" />
                                        </MenuItem>
                                        <MenuItem value="3">
                                            <FormattedMessage id="Bisexual" />
                                        </MenuItem>
                                        {/* <MenuItem value="4">
                                            <FormattedMessage id="Something_else_please_describe" />
                                        </MenuItem> */}
                                        <MenuItem value="5">
                                            <FormattedMessage id="Dont_know" />
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                {/* {selfDescribe && (
                                    <>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <TextField
                                                size="small"
                                                id="selfIdentity"
                                                label={intl.formatMessage({ id: 'Enter_Your_Gender_Identity' })}
                                                type="text"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} />
                                    </>
                                )}
                                {!selfDescribe && <Grid item xs={8} md={8} lg={8} />} */}
                                <Grid item xs={4} md={4} lg={4}>
                                    <TextField
                                        disabled
                                        id="gender"
                                        select
                                        value={genderIdentity}
                                        onChange={(e) => {
                                            setGenderIdentity(e.target.value);
                                        }}
                                        fullWidth
                                        label={intl.formatMessage({ id: 'Gender_Identity' })}
                                        size="small"
                                    >
                                        <MenuItem value="1">
                                            <FormattedMessage id="Male" />
                                        </MenuItem>
                                        <MenuItem value="2">
                                            <FormattedMessage id="Female" />
                                        </MenuItem>
                                        <MenuItem value="3">
                                            <FormattedMessage id="Transgender_male_Trans_male_Female_to_male" />
                                        </MenuItem>
                                        <MenuItem value="4">
                                            <FormattedMessage id="Transgender_female_Trans_woman_Male_to_female" />
                                        </MenuItem>
                                        <MenuItem value="5">
                                            <FormattedMessage id="Genderqueer_neither_exclusively_male_or_female" />
                                        </MenuItem>
                                        <MenuItem value="6">
                                            <FormattedMessage id="Self_Identify" />
                                        </MenuItem>
                                        <MenuItem value="7">
                                            <FormattedMessage id="Decline_to_answer" />
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                {selfIdentifyDiv && (
                                    <Grid item xs={4} md={4} lg={4}>
                                        <TextField
                                            disabled
                                            size="small"
                                            id="selfIdentity"
                                            label={intl.formatMessage({ id: 'Enter_Your_Gender_Identity' })}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControl
                                        sx={{
                                            width: {
                                                xs: 100, // theme.breakpoints.up('xs')
                                                sm: 200, // theme.breakpoints.up('sm')
                                                md: 300, // theme.breakpoints.up('md')
                                                lg: 400, // theme.breakpoints.up('lg')
                                                xl: 350 // theme.breakpoints.up('xl')
                                            }
                                        }}
                                        size="small"
                                    >
                                        <InputLabel id="race-label">Race</InputLabel>
                                        <Select
                                            labelId="race-label"
                                            id="race"
                                            disabled
                                            multiple
                                            value={race}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {races.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <Checkbox checked={race.indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {/* <FormControl>
                                        <RadioGroup
                                            row
                                            aria-label="gender"
                                            value={race}
                                            onChange={e => setRace(e.target.value)}
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label={intl.formatMessage({ id: 'American_Indian_or_Alaska_Native' })}
                                            />
                                            <FormControlLabel value="2" control={<Radio />} label={intl.formatMessage({ id: 'Asian' })} />
                                            <FormControlLabel
                                                value="4"
                                                control={<Radio />}
                                                label={intl.formatMessage({ id: 'Black_or_African_American' })}
                                            />
                                            <FormControlLabel
                                                value="5"
                                                control={<Radio />}
                                                label={intl.formatMessage({ id: 'Declined_to_state' })}
                                            />
                                            <FormControlLabel
                                                value="6"
                                                control={<Radio />}
                                                label={intl.formatMessage({ id: 'Native_Hawaiian_or_Other_Pacific_Islander' })}
                                            />
                                            <FormControlLabel value="3" control={<Radio />} label={intl.formatMessage({ id: 'White' })} />
                                        </RadioGroup>
                                    </FormControl> */}
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ pb: 3 }}>
                                <Grid item sx={{ mt: 1, mb: 1 }} xs={12} lg={12} md={12}>
                                    <Typography variant="h5">
                                        <FormattedMessage id="Address_Information" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="street1"
                                        label={intl.formatMessage({ id: 'Street_1' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="street2"
                                        label={intl.formatMessage({ id: 'Street_2' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="city"
                                        label={intl.formatMessage({ id: 'City' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        select
                                        disabled
                                        size="small"
                                        id="state"
                                        label={intl.formatMessage({ id: 'State' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="zipcode"
                                        label={intl.formatMessage({ id: 'Zipcode' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="phone"
                                        label={intl.formatMessage({ id: 'Phone' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="fax"
                                        label={intl.formatMessage({ id: 'Fax' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                {/* <Grid item xs={4} lg={4} md={4} /> */}
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        size="small"
                                        disabled
                                        id="mobile"
                                        label={intl.formatMessage({ id: 'Mobile' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                {/* <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        size="small"
                                        id="emergencyphone"
                                        label={intl.formatMessage({ id: 'Emergency_Phone' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid> */}
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="preferredphone"
                                        label={intl.formatMessage({ id: 'Preferred_Phone' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        size="small"
                                        disabled
                                        id="alternativephone"
                                        label={intl.formatMessage({ id: 'Alternative_Phone' })}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                {/* <Grid item xs={3} lg={3} md={3} /> */}
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="email"
                                        label={intl.formatMessage({ id: 'Email' })}
                                        type="email"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item>
                    <Button size="small" variant="contained">
                        <FormattedMessage id="Save" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button size="small" variant="outlined">
                        <FormattedMessage id="Cancel" />
                    </Button>
                </Grid>
            </Grid> */}
        </>
    );
};

export default Demographics;
