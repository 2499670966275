// assets
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import CareProgram from './tabcontent/careprogram/CareProgram';
import AddActivityTab from './tabcontent/addactivity/AddActivityTab';
import CustomCareProgram from './tabcontent/customcareprogram/CustomCareProgram';
import AddMonitoringTab from './tabcontent/addmonitoring/AddMonitoringTab';

// tabs panel
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: (
            <>
                <Typography variant="tabText">Care Program</Typography>
            </>
        )
        // icon: <DescriptionTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: (
            <>
                <Typography variant="tabText">Add Goal</Typography>
            </>
        )
        // icon: <DescriptionTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: (
            <>
                <Typography variant="tabText">Add Activity</Typography>
            </>
        )
        // icon: <LibraryBooksTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: (
            <>
                <Typography variant="tabText">Add Monitoring</Typography>
            </>
        )
        // icon: <LibraryBooksTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    }
];

const PlannerTabs = () => {
    const theme1 = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                <Typography variant="dashboardCardContent">
                    <b>DREAMS Planner</b>
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: '8px !important' }}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    sx={{
                        mb: 1,
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            mr: 0,
                            color: theme1.palette.grey[600],
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& a.Mui-selected': {
                            color: theme1.palette.primary.main
                        },
                        '& .MuiTabs-indicator': {
                            bottom: 2
                        },
                        '& a > svg': {
                            marginBottom: '0px !important',
                            mr: 1.25
                        }
                    }}
                >
                    {tabsOption.map((tab, index) => (
                        <Tab key={index} component={Link} to="#" icon={tab.icon} label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <CareProgram />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CustomCareProgram />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <AddActivityTab />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <AddMonitoringTab />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default PlannerTabs;
