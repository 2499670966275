import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Typography,
    Grid,
    Chip,
    styled,
    tableCellClasses,
    Button
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { gridSpacing } from 'store/constant';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { useState } from 'react';

// table data
function createData(name, type, activity, respondedOn, response) {
    return {
        name,
        type,
        activity,
        respondedOn,
        response
    };
}

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const cardStyle = {
        pt: '15px',
        pl: '15px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.tableColor.tableExpandBg
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.name}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.type}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.activity}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.respondedOn}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <Chip
                        label={row.response}
                        size="small"
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.success.dark,
                            color: theme.palette.primary.light
                        }}
                    />
                </StyledTableCell>
                {/* <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                    </Stack>
                </StyledTableCell> */}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>
                                        {row.expand?.map((items) => (
                                            <TableRow>
                                                <Grid container spacing={gridSpacing} sx={cardStyle}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Type</Typography>
                                                                {row.type}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Category</Typography>
                                                                {row.category}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Physician</Typography>
                                                                {row.physician}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Addresses</Typography>
                                                                {row.addresses}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Start date</Typography>
                                                                {row.startdate}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">End date</Typography>
                                                                {row.enddate}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Provider</Typography>
                                                                {row.provider}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Status</Typography>
                                                                Active
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Description</Typography>
                                                                Manage obesity and weight loss
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Recorded date</Typography>
                                                                02/02/2021
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Contributor</Typography>
                                                                Susan Richards
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Activity</Typography>
                                                                Progressive weight loss
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="h5">Outcome Reference</Typography>
                                                                Body Weight
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Typography variant="h5">Note</Typography>
                                                                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur,
                                                                adipisci velit
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">Care Team</Typography>
                                                                {row.careTeam?.map((members) => (
                                                                    <>
                                                                        {members}
                                                                        <br />
                                                                    </>
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="outlined" onClick={() => setOpen(!open)}>
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [createData('Rate your sleep quality', 'DREAMS > Rest', ' Sleep duration', '11/07/2022', 'Good')];

export default function SurveyTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Activity</StyledTableCell>
                            <StyledTableCell>Responded On</StyledTableCell>
                            <StyledTableCell>Response</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
