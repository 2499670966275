import {
    useTheme,
    TextField,
    Autocomplete,
    Grid,
    FormControlLabel,
    Checkbox,
    Button,
    Stack,
    Typography,
    Card,
    Link,
    MenuItem,
    IconButton
} from '@mui/material';
import { useState } from 'react';
import PreviewAndPublish from '../../common/previewandpublish/PreviewAndPublish';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AddMonitoringTab from '../addmonitoring/AddMonitoringTab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MonitoredValues from '../addmonitoring/MonitoredValues';
import ReferenceMaterial from '../../../plans/goals/referencematerial/ReferenceMaterial';
import DeleteIcon from '@mui/icons-material/Delete';
import AddNewActivity from '../../common/addnewactivity/AddNewActivity';

const CareProgram = () => {
    const theme = useTheme();

    const providers = [{ label: 'Diabetic Care', id: 1 }];

    const [provider, setProvider] = useState(providers[0]);

    const [dietopen, setDietOpen] = useState(true);
    const [nexerciseopen, setNExerciseOpen] = useState(false);
    const [restopen, setRestOpen] = useState(false);
    const [attitudeopen, setAttitudeOpen] = useState(false);
    const [medicationopen, setMedicationOpen] = useState(false);
    const [addMonitoring, setAddMonitoring] = useState(true);
    const [date, setDate] = useState(null);
    const collapseAll = () => {
        // setDietOpen(false);
        setNExerciseOpen(false);
        setRestOpen(false);
        setAttitudeOpen(false);
        setMedicationOpen(false);
        setAddMonitoring(false);
    };

    const handleChange = (e) => {
        setProvider(e.target.value);
    };
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important'
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8} lg={8} md={8}>
                    <Autocomplete
                        disableClearable
                        options={providers}
                        defaultValue={provider}
                        onChange={handleChange}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Choose the Care Program" />}
                    />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid item xs={12} lg={12} md={12} sx={{ pl: '7px !important', pt: '8px !important' }}>
                        <Grid container alignItems="center" spacing={1} sx={cardStyle}>
                            <Grid item xs={10} lg={10} md={10}>
                                <Typography variant="dashboardCardContent">Maintain Healthy Weight</Typography>
                            </Grid>
                            <Grid item xs={2} lg={2} md={2} align="right">
                                <IconButton>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Grid>
                            <Grid item container xs={12} lg={12} md={12} spacing={2} alignItems="center">
                                <Grid item xs={4} lg={4} md={4}>
                                    <Typography variant="subTitle1">
                                        <b>Current Weight: </b> 190 lbs
                                    </Typography>
                                </Grid>

                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField id="outlined-select-experience1" fullWidth label="Set Weight Goal" size="small" />
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                            label="Acheive By"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            {dietopen && (
                                <Grid item xs={12} lg={12} md={12}>
                                    <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                        <Grid item container xs={12} lg={12} md={12}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h5">Activities</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                                                <Typography variant="h5">
                                                    <b>Diet & Nutrition - </b>Meal Planner, Monitor Weight
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            )}
                            {addMonitoring && (
                                <Grid item xs={12} lg={12} md={12}>
                                    <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                        <Grid item container xs={12} lg={12} md={12}>
                                            <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                                <Typography variant="h5">Monitored Values</Typography>
                                            </Grid>
                                        </Grid>
                                        <MonitoredValues />
                                    </Card>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={12} md={12}>
                                <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                    <Grid item container xs={12} lg={12} md={12}>
                                        <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                            <Typography variant="h5">Reference Materials</Typography>
                                        </Grid>
                                    </Grid>
                                    <ReferenceMaterial mode={2} />
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid item xs={12} lg={12} md={12} sx={{ pl: '7px !important' }}>
                        <Grid container alignItems="center" spacing={1} sx={cardStyle}>
                            <Grid item xs={10} lg={10} md={10}>
                                <Typography variant="dashboardCardContent">Take Medication Regular as prescribed on time</Typography>
                            </Grid>
                            <Grid item xs={2} lg={2} md={2} align="right">
                                <IconButton>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Grid>
                            {dietopen && (
                                <Grid item xs={12} lg={12} md={12}>
                                    <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                        <Grid item container xs={12} lg={12} md={12}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h5">Activities</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                                                <Typography variant="h5">
                                                    <b>Schedule Medication</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid item xs={12} lg={12} md={12} sx={{ pl: '7px !important' }}>
                        <Grid container alignItems="center" spacing={1} sx={cardStyle}>
                            <Grid item xs={10} lg={10} md={10}>
                                <Typography variant="dashboardCardContent">De-Addiction and Recovery</Typography>
                            </Grid>
                            <Grid item xs={2} lg={2} md={2} align="right">
                                <IconButton>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Grid>
                            <Grid item container xs={12} lg={12} md={12} spacing={2} alignItems="center">
                                <Grid item xs={5} lg={5} md={5}>
                                    <Typography variant="subTitle1">
                                        <b>Physical Addiction - </b> Smoking
                                    </Typography>
                                </Grid>
                            </Grid>
                            {dietopen && (
                                <Grid item xs={12} lg={12} md={12}>
                                    <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                        <Grid item container xs={12} lg={12} md={12}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h5">Activities</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                                                <Typography variant="h5">
                                                    <b>Join a Rehab Center</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={12} md={12}>
                                <AddNewActivity />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} sx={{ pt: '15px !important' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Stack direction="row" spacing={1}>
                                <Button size="small" variant="contained">
                                    Save
                                </Button>
                                <PreviewAndPublish />
                                <Button size="small" variant="outlined">
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CareProgram;
