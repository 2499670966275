import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

// project imports
import useConfig from 'hooks/useConfig';
import value from 'assets/scss/_themes-vars.module.scss';

// ==============================|| AREA CHART ||============================== //

const BloodPressureAreaChart = ({ height }) => {
    const theme = useTheme();
    const { navType } = useConfig();

    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey700 = theme.palette.grey[700];

    const [series] = useState([
        {
            name: 'Systolic Blood Pressure',
            data: [125, 120, 122, 126, 119, 128, 130]
        },
        {
            name: 'Diastolic Blood Pressure',
            data: [80, 95, 90, 85, 90, 95, 90]
        }
    ]);
    // chart options
    const areaChartOptions = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: {
                show: false
            }
        },
        colors: [value.secondaryMain, value.primaryMain],
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'category',
            categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        },

        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        legend: {
            show: true,
            fontFamily: `'Roboto', sans-serif`,
            position: 'bottom',
            offsetX: 10,
            offsetY: 10,
            labels: {
                useSeriesColors: false
            },
            markers: {
                width: 16,
                height: 16,
                radius: 5
            },
            itemMargin: {
                horizontal: 15,
                vertical: 8
            }
        },
        annotations: {
            yaxis: [
                {
                    y: 125,
                    borderColor: grey700,
                    label: {
                        style: {
                            color: '#ffffff',
                            background: grey700
                        },
                        text: 'Sys Ideal'
                    }
                },
                {
                    y: 110,
                    borderColor: grey700,
                    label: {
                        borderColor: grey700,
                        style: {
                            color: '#ffffff',
                            background: grey700
                        },
                        text: 'Dia Ideal'
                    }
                }
            ]
        }
    };

    const [options, setOptions] = useState(areaChartOptions);
    React.useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            colors: [theme.palette.secondary.main, theme.palette.primary.main],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary, primary, primary, primary, primary, primary, primary]
                    }
                }
            },
            yaxis: {
                // labels: {
                //     style: {
                //         colors: [primary]
                //     }
                // }
                show: true
            },
            grid: {
                borderColor: navType === 'dark' ? darkLight + 20 : grey200
            },
            tooltip: {
                theme: navType === 'dark' ? 'dark' : 'light'
            },
            legend: {
                labels: {
                    colors: 'grey.500'
                }
            }
        }));
    }, [navType, primary, darkLight, grey200, theme]);

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="area" height={height} />
        </div>
    );
};

export default BloodPressureAreaChart;
