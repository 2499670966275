import { useTheme, Grid, Typography, Switch, FormControlLabel, Button, CardContent, IconButton, Card } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import BillingCard from './BillingCard';

const BillingContents = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    const data = [
        {
            status: { type: 'success', content: 'Billed' },
            title: 'CPT Code: 99495',
            contents: [
                ['Discharged On', '04/30/2022'],
                ['Interactive Visit on', '05/07/2022'],
                ['Face to Face On', '05/11/2022'],
                ['TCM Completed On', '05/30/2022'],
                ['Billed On', '06/01/2022']
            ]
        }
    ];

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };

    const subCardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
        px: '10px !important',
        pt: '15px !important',
        pb: '10px !important',
        mb: '10px !important',
        ml: '20px !important'
    };
    return (
        <>
            <Card sx={cardStyle}>
                <CardContent sx={{ p: '10px !important' }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={11} md={11} lg={11}>
                            <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                                    <b>TCM Initiated on 05/01/2022</b>
                                </Typography>
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} align="right">
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                <ExpandCircleDownOutlinedIcon color="primary" />
                            </IconButton>
                        </Grid>
                        {open &&
                            data.map((item, index) => (
                                <Grid container item xs={12} lg={12} md={12} sx={subCardStyle} alignItems="center">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <BillingCard data={item} />
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default BillingContents;
