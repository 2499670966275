import { Grid, useTheme, FormControlLabel, Checkbox, Typography, TextField, MenuItem, Button } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useState } from 'react';
import Chip from 'ui-component/extended/Chip';

const CreateNewRPM = () => {
    const theme = useTheme();
    const [date, setDate] = useState(null);
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important',
        ml: '15px !important'
    };
    return (
        <>
            <Grid container item xs={12} lg={12} md={12} sx={cardStyle} spacing={1}>
                <Grid item xs={12} lg={12} md={12} sx={{ mb: 1 }}>
                    <Typography variant="h5">Values for Monitoring</Typography>
                </Grid>
                <Grid container item xs={12} lg={12} md={12}>
                    <Grid item xs={3} lg={3} md={3}>
                        <FormControlLabel control={<Checkbox defaultChecked name="bloodpressure" />} label="Blood Pressure" />
                    </Grid>
                    <Grid item xs={2} lg={2} md={2}>
                        <TextField id="bpdropdown" disabled select fullWidth defaultValue="monthly" size="small">
                            <MenuItem selected value="monthly">
                                Monthly
                            </MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="daily">Daily</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={12} md={12}>
                    <Grid item xs={3} lg={3} md={3}>
                        <FormControlLabel control={<Checkbox defaultChecked name="hemoglobin" />} label="Hemoglobin" />
                    </Grid>
                    <Grid item xs={2} lg={2} md={2}>
                        <TextField id="hemoglobindropdown" disabled select fullWidth defaultValue="monthly" size="small">
                            <MenuItem selected value="monthly">
                                Monthly
                            </MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="daily">Daily</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={12} md={12}>
                    <Grid item xs={3} lg={3} md={3}>
                        <FormControlLabel control={<Checkbox defaultChecked name="pulse" />} label="Pulse" />
                    </Grid>
                    <Grid item xs={2} lg={2} md={2}>
                        <TextField id="pulsedropdown" disabled select fullWidth defaultValue="monthly" size="small">
                            <MenuItem selected value="monthly">
                                Monthly
                            </MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="daily">Daily</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={12} md={12}>
                    <Grid item xs={3} lg={3} md={3}>
                        <FormControlLabel control={<Checkbox defaultChecked name="bloodsugar" />} label="Blood Sugar" />
                    </Grid>
                    <Grid item xs={2} lg={2} md={2}>
                        <TextField id="bloodsugardropdown" disabled select fullWidth defaultValue="monthly" size="small">
                            <MenuItem selected value="monthly">
                                Monthly
                            </MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="daily">Daily</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} lg={12} md={12} sx={cardStyle} spacing={1}>
                <Grid item xs={10} lg={10} md={10} sx={{ mb: 1 }}>
                    <Typography variant="h5">
                        RPM Onboarding Activity
                        <Chip sx={{ mb: 0.1, ml: 0.3 }} size="small" label="Onboarding" chipcolor="success" />
                    </Typography>
                </Grid>
                <Grid item xs={2} lg={2} md={2} align="right">
                    <Button variant="outlined" size="small">
                        Print
                    </Button>
                </Grid>
                <Grid container item xs={12} lg={12} md={12}>
                    <Grid item xs={12} lg={12} md={12}>
                        <FormControlLabel control={<Checkbox defaultChecked name="contactpatient" />} label="Contact Patient" />
                    </Grid>
                    <Grid container item xs={12} lg={12} md={12} sx={{ pl: 3 }} spacing={2}>
                        <Grid item xs={4} lg={4} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField size="small" {...props} helperText="" fullWidth />}
                                    label="Schedule"
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4}>
                            <TextField id="assignto" select label="Assign To" fullWidth size="small">
                                <MenuItem selected value="monthly" />
                            </TextField>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField size="small" {...props} helperText="" fullWidth />}
                                    label="Completed Date"
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <FormControlLabel control={<Checkbox name="senddevices" />} label="Send the devices to patient" />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox name="recieveddevices" />}
                            label="Confirm that patient has received the devices"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox name="nonfacemeeting" />}
                            label="Schedule non-face to face meeting with patient to walk through"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox name="nonfacemeetingwalkthrough" />}
                            label="Conduct the non-face to face meeting with patient to walk through"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox name="monitoringsuccessfully" />}
                            label="Patient has started monitoring successfully"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <Button variant="contained" size="small">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CreateNewRPM;
