import { Grid, useTheme, Typography, Button, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import CreateNewRPM from './CreateNewRPM';
import RPMCyclesContent from './RPMCyclesContent';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const RPMCyclesTab = () => {
    const theme = useTheme();
    const [showCreateNewRPM, setShowCreateNewRPM] = useState(false);
    const handleCreateNewRPMClick = () => {
        setShowCreateNewRPM(!showCreateNewRPM);
    };
    return (
        <>
            <Grid container spacing={2}>
                {!showCreateNewRPM && (
                    <>
                        <Grid container item xs={12} lg={12} md={12} sx={{ mb: 1 }} spacing={2} alignItems="center">
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography variant="dashboardCardContent">
                                    <b>RPM Cycles</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} align="right">
                                <Button variant="contained" size="small" onClick={handleCreateNewRPMClick}>
                                    Create New RPM
                                </Button>
                            </Grid>
                        </Grid>
                        <RPMCyclesContent />
                    </>
                )}
                {showCreateNewRPM && (
                    <>
                        <Grid container item xs={12} lg={12} md={12} spacing={2} alignItems="center">
                            <Grid item xs={12} md={12} lg={12}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <IconButton onClick={handleCreateNewRPMClick}>
                                        <NavigateBeforeIcon />
                                    </IconButton>
                                    <Typography variant="dashboardCardContent">
                                        <b>Create New RPM</b>
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <CreateNewRPM />
                    </>
                )}
            </Grid>
        </>
    );
};

export default RPMCyclesTab;
