import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ReportsSearch = () => {
    const theme = useTheme();
    const [dob, setDOB] = useState(null);
    const [mode, setMode] = useState('1');
    const [showdetails, setShowDetails] = useState(false);

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <SubCard sx={cardStyle}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <TextField id="outlined-select-experience1" select defaultValue="bp" size="small" fullWidth>
                                    <MenuItem selected value="bp">
                                        Blood Pressure
                                    </MenuItem>
                                    <MenuItem value="bs">Blood Sugar</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="outlined-select-experience1" select defaultValue="custom" size="small" fullWidth>
                                    <MenuItem selected value="custom">
                                        Custom
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="From Date"
                                        value={dob}
                                        onChange={(newValue) => {
                                            setDOB(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="To Date"
                                        value={dob}
                                        onChange={(newValue) => {
                                            setDOB(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }} align="left">
                        <Button variant="contained" size="small" sx={{ mr: 1 }}>
                            <Typography>View Report</Typography>
                        </Button>
                        <Button variant="contained" size="small">
                            <Typography>Download Report</Typography>
                        </Button>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default ReportsSearch;
