import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Typography,
    Grid,
    Chip,
    styled,
    tableCellClasses,
    Button,
    Link
} from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { gridSpacing } from 'store/constant';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// table data
function createData(goalname, startingdate, endingdate, status, expand, tests) {
    return {
        goalname,
        startingdate,
        endingdate,
        status,
        expand,
        tests
    };
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const cardStyle = {
        pt: '15px',
        pl: '15px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.tableColor.tableExpandBg
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.goaldreamsbkg.headingwrapper
        }
    }));

    function Active() {
        const goalsname = row.goalname;
        if (goalsname === 'Nutrition Goal') {
            setOpen(open);
        } else {
            setOpen(!open);
        }
    }

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.goalname}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.startingdate}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.endingdate}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <Chip
                        label={row.status}
                        size="small"
                        sx={{
                            border: '1px solid #1D5DDF',
                            color: '#1D5DDF',
                            background: '#fafafa'
                        }}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(Active)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                    </Stack>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>
                                        {row.expand?.map((items) => (
                                            <TableRow>
                                                <Grid container spacing={gridSpacing} sx={cardStyle} gap={2}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            sx={{
                                                                borderBottom: '1px solid #EEEEEE',
                                                                mb: 2,
                                                                pb: 2,
                                                                color: theme.palette.goaldreamsbkg.headingwrapper
                                                            }}
                                                        >
                                                            Description
                                                        </Typography>
                                                        <Grid
                                                            container
                                                            style={{
                                                                background: '#FAFBFD',
                                                                borderRadius: '10px',
                                                                paddingRight: '0',
                                                                marginTop: '10px',
                                                                padding: '20px'
                                                            }}
                                                        >
                                                            <Grid item xs={3} sx={{ pb: 2 }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    Goal Name
                                                                </Typography>
                                                                {row.goalname}
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ pb: 2 }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    Start Date
                                                                </Typography>
                                                                {row.startingdate}
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ pb: 2 }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    Ending Date
                                                                </Typography>
                                                                {row.endingdate}
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ pb: 2 }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    Associated DREAMS
                                                                </Typography>
                                                                Diet, Rest
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ pb: 2 }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    Start Weight
                                                                </Typography>
                                                                220.5 lbs
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ pb: 2 }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    Target Weight
                                                                </Typography>
                                                                207.2 lbs
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ pb: 2 }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    Goal Status
                                                                </Typography>
                                                                {row.status}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid
                                                            container
                                                            style={{
                                                                background: '#FAFBFD',
                                                                borderRadius: '10px',
                                                                paddingRight: '0',
                                                                marginTop: '10px',
                                                                padding: '20px',
                                                                display: 'flex',
                                                                justifyContent: 'space-around'
                                                            }}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={5}
                                                                lg={5}
                                                                sx={{
                                                                    background: theme.palette.primary.light,
                                                                    p: 2,
                                                                    borderRadius: '10px',
                                                                    border: '1px solid #EEEEEE',
                                                                    padding: 0
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        borderBottom: '1px solid #eeeeee',
                                                                        mb: 2,
                                                                        p: 1,
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper
                                                                    }}
                                                                >
                                                                    <b>Diet</b>
                                                                </Typography>
                                                                <Stack spacing={2} direction="row">
                                                                    <Item sx={{ textAlign: 'left', padding: '0 10px 10px' }}>
                                                                        <Typography
                                                                            sx={{
                                                                                color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                                pb: 1
                                                                            }}
                                                                        >
                                                                            Activities
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                color: '#707070',
                                                                                fontWeight: 'normal',
                                                                                paddingBottom: '10px'
                                                                            }}
                                                                        >
                                                                            Meal Plan
                                                                        </Typography>
                                                                        <Typography sx={{ color: '#707070', fontWeight: 'normal' }}>
                                                                            Water Intake Plan
                                                                        </Typography>
                                                                    </Item>
                                                                    <Item sx={{ textAlign: 'left', padding: '0 10px 10px' }}>
                                                                        <Typography
                                                                            sx={{
                                                                                color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                                pb: 1
                                                                            }}
                                                                        >
                                                                            Description
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                color: '#707070',
                                                                                fontWeight: 'normal',
                                                                                paddingBottom: '10px'
                                                                            }}
                                                                        >
                                                                            Your Meal plan is going good
                                                                        </Typography>
                                                                        <Typography sx={{ color: '#707070', fontWeight: 'normal' }}>
                                                                            Your Water intake goal is below the time planned
                                                                        </Typography>
                                                                    </Item>
                                                                    <Item sx={{ textAlign: 'left', padding: '0 10px 10px' }}>
                                                                        <Typography
                                                                            sx={{
                                                                                pb: 1,
                                                                                color: theme.palette.goaldreamsbkg.headingwrapper
                                                                            }}
                                                                        >
                                                                            Status
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                color: theme.palette.success.dark,
                                                                                paddingBottom: '10px'
                                                                            }}
                                                                        >
                                                                            Good
                                                                        </Typography>
                                                                        <Typography sx={{ color: theme.palette.error.dark }}>
                                                                            Poor
                                                                        </Typography>
                                                                    </Item>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={5}
                                                                lg={5}
                                                                sx={{
                                                                    background: theme.palette.primary.light,
                                                                    p: 2,
                                                                    borderRadius: '10px',
                                                                    border: '1px solid #EEEEEE',
                                                                    padding: 0
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{
                                                                        borderBottom: '1px solid #eeeeee',
                                                                        mb: 2,
                                                                        p: 1,
                                                                        color: theme.palette.goaldreamsbkg.headingwrapper
                                                                    }}
                                                                >
                                                                    <b>Rest</b>
                                                                </Typography>
                                                                <Stack spacing={2} direction="row">
                                                                    <Item sx={{ textAlign: 'left', padding: '0 10px 10px' }}>
                                                                        <Typography
                                                                            sx={{
                                                                                color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                                pb: 1
                                                                            }}
                                                                        >
                                                                            Activities
                                                                        </Typography>
                                                                        <Typography sx={{ color: '#707070', fontWeight: 'normal' }}>
                                                                            Sleeping Plan
                                                                        </Typography>
                                                                    </Item>
                                                                    <Item sx={{ textAlign: 'left', padding: '0 10px 10px' }}>
                                                                        <Typography
                                                                            sx={{
                                                                                color: theme.palette.goaldreamsbkg.headingwrapper,
                                                                                pb: 1
                                                                            }}
                                                                        >
                                                                            Description
                                                                        </Typography>
                                                                        <Typography sx={{ color: '#707070', fontWeight: 'normal' }}>
                                                                            Your Sleeping Plan is going good
                                                                        </Typography>
                                                                    </Item>
                                                                    <Item sx={{ textAlign: 'left', padding: '0 10px 10px' }}>
                                                                        <Typography
                                                                            sx={{
                                                                                pb: 1,
                                                                                color: theme.palette.goaldreamsbkg.headingwrapper
                                                                            }}
                                                                        >
                                                                            Status
                                                                        </Typography>
                                                                        <Typography sx={{ color: theme.palette.success.dark }}>
                                                                            Good
                                                                        </Typography>
                                                                    </Item>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="outlined" onClick={() => setOpen(!open)}>
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData(
        'Weight Goal',
        '02/24/2023',
        '08/14/2023',
        'In progress',
        ['Lorem Ipsum'],
        [
            { testname: 'HEMOGLOBIN ', value: '176 G/L', referencerange: '135 - 180 G/L' },
            { testname: 'RED CELL COUNT ', value: '5.9 10*12/L', referencerange: '4.2 - 6  10*12/L' },
            { testname: 'HAEMATOCRIT  ', value: '55%', referencerange: '38 - 52 %' }
        ],
        true
    ),
    createData(
        'Nutrition Goal',
        '01/02/2023',
        '12/02/2024',
        'In progress',
        ['Lorem Ipsum'],
        [
            { testname: 'HEMOGLOBIN ', value: '176 G/L', referencerange: '135 - 180 G/L' },
            { testname: 'RED CELL COUNT ', value: '5.9 10*12/L', referencerange: '4.2 - 6  10*12/L' },
            { testname: 'HAEMATOCRIT  ', value: '55%', referencerange: '38 - 52 %' }
        ],
        false
    )
];
// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function GoalhistoryTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16
        }
    }));

    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ color: '#202263' }}>Goal Name</StyledTableCell>
                            <StyledTableCell style={{ color: '#202263' }}>Start Date</StyledTableCell>
                            <StyledTableCell style={{ color: '#202263' }}>Ending Date</StyledTableCell>
                            <StyledTableCell style={{ color: '#202263' }}>Status</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
