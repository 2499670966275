import { useTheme, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import SurveyTable from './table/SurveyTable';

const SurveyTab = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid container item xs={12} lg={12} md={12} spacing={gridSpacing}>
                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }}>
                        <Typography variant="dashboardCardContent">
                            <b>Survey</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <SurveyTable />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SurveyTab;
