import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, CardActions, CardContent, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import useConfig from 'hooks/useConfig';
import TCMActivityContent from './tabcontents/tcm/TCMActivityContent';
import TCMHistory from './tabcontents/tcm/TCMHistory';
import TCMReports from './tabcontents/tcm/reports/TCMReports';
import SummaryTab from './tabcontents/tcm/summary/SummaryTab';
import BillingTab from './tabcontents/tcm/billing/BillingTab';

// tabs
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: 'Summary'
    },
    {
        label: 'TCM Activity'
    },
    {
        label: 'TCM Reports'
    },
    {
        label: 'Billing'
    }
];

// ==============================|| PROFILE 2 ||============================== //

const TCMTabs = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={3} sx={{ p: '10px !important' }}>
                        <CardContent sx={{ p: '8px !important' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                orientation="vertical"
                                variant="scrollable"
                                sx={{
                                    '& .MuiTabs-flexContainer': {
                                        borderBottom: 'none'
                                    },
                                    '& button': {
                                        color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                                        minHeight: 'auto',
                                        minWidth: '100%',
                                        py: 1.5,
                                        px: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        textAlign: 'left',
                                        justifyContent: 'flex-start',
                                        borderRadius: `${borderRadius}px`
                                    },
                                    '& button.Mui-selected': {
                                        color: theme.palette.primary.main,
                                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
                                    },
                                    '& button > svg': {
                                        marginBottom: '0px !important',
                                        marginRight: 1.25,
                                        marginTop: 1.25,
                                        height: 20,
                                        width: 20
                                    },
                                    '& button > div > span': {
                                        display: 'block'
                                    },
                                    '& > div > span': {
                                        display: 'none'
                                    }
                                }}
                            >
                                {tabsOption.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        icon={tab.icon}
                                        label={
                                            <Grid container direction="column">
                                                <Typography variant="subtitle1" color="inherit">
                                                    {tab.label}
                                                </Typography>
                                                <Typography component="div" variant="caption" sx={{ textTransform: 'capitalize' }}>
                                                    {tab.caption}
                                                </Typography>
                                            </Grid>
                                        }
                                        {...a11yProps(index)}
                                    />
                                ))}
                            </Tabs>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12} lg={9} sx={{ p: '0px !important' }}>
                        <CardContent
                            sx={{
                                borderLeft: '1px solid',
                                p: '10px !important',
                                pr: '0px !important',
                                mt: '12px',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
                                height: '100%'
                            }}
                        >
                            <TabPanel value={value} index={0}>
                                <SummaryTab />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <TCMActivityContent />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <TCMReports />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <BillingTab />
                            </TabPanel>
                        </CardContent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TCMTabs;
