import { useTheme, Grid, Typography, styled, Badge, Checkbox, FormControlLabel, TextField, MenuItem, Button, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useState, useEffect } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { flexbox } from '@mui/system';
import Tcmactivityview from './TCMActivityView';

const Createnewtcm = () => {
    const theme = useTheme();
    const [date, setDate] = useState(null);
    const [cdate, setCDate] = useState(null);
    const [Tcmvalue, setTcmvalue] = useState([]);
    const cardStyle = {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: 2,
        backgroundColor: theme.palette.cardColor.mainCardContentsBg,
        p: '8px !important',
        mt: '8px !important'
    };
    const SuccessBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.success.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.success.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    useEffect(() => {
        console.log(Tcmvalue);
    }, [Tcmvalue]);
    // take checkbox value
    const Onchange = (event) => {
        let Storearrayvalue = [...Tcmvalue];
        if (event.target.checked) {
            Storearrayvalue = [...Tcmvalue, event.target.value];
            setTcmvalue(Storearrayvalue);
            console.log(Storearrayvalue);
        } else {
            Storearrayvalue.splice(Tcmvalue.indexOf(event.target.value), 1);
            setTcmvalue(Storearrayvalue);
            console.log(Storearrayvalue);
        }
    };
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12} md={12} sx={{ pl: '40px !important' }}>
                    <Grid container alignItems="center" spacing={2} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        name="reconcilemedicationlist"
                                        value="Interactive Contact with Patient or Proxy within 2 days of discharge"
                                        onChange={Onchange}
                                    />
                                }
                                label="Interactive Contact with Patient or Proxy within 2 days of discharge"
                            />
                        </Grid>

                        <Grid item xs={12} lg={12} md={12}>
                            Contact History
                        </Grid>

                        <Grid item xs={3} lg={3} md={3}>
                            <TextField id="outlined-select-experience1" select fullWidth label="Assigned To" size="small">
                                <MenuItem value="Isaac White">Isaac White</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                    label="Contacted On"
                                    value={cdate}
                                    onChange={(newValue) => {
                                        setCDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3} lg={3} md={3}>
                            <TextField id="outlined-select-experience1" select fullWidth label="Contact Status" size="small">
                                <MenuItem value="Yes">Responded</MenuItem>
                                <MenuItem value="No">Not Responded</MenuItem>
                            </TextField>
                        </Grid>
                        {/* <Grid item xs={2} lg={2} md={2}>
                            <Button size="small" variant="contained">
                                <AddCircleIcon />
                                &nbsp; Add
                            </Button>
                        </Grid> */}

                        {/* <Grid item xs={12} lg={12} md={12} sx={{ pb: 2 }}>
                            <Grid container alignItems="center" spacing={1}> */}
                        {/* <Grid item xs={3} lg={3} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                            label="Contacted On"
                                            value={cdate}
                                            onChange={(newValue) => {
                                                setCDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid> */}

                        {/* <Grid item xs={3} lg={3} md={3}>
                                    Success
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <FormControlLabel control={<Checkbox defaultChecked name="interactiveyes" />} label="Yes" />
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <FormControlLabel control={<Checkbox defaultChecked name="interactiveno" />} label="No" />
                                </Grid> */}
                        {/* <Grid item xs={3} lg={3} md={3}>
                                    <Button size="small" variant="contained">
                                        <AddCircleIcon />
                                        &nbsp; Add
                                    </Button>
                                </Grid> */}
                        {/* </Grid>
                        </Grid> */}
                        {/* <Grid item xs={3} lg={3} md={3}>
                            <FormControlLabel control={<Checkbox name="reconcilemedicationlistdone" />} label="Mark as Done" />
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={{ pl: '31px !important' }}>
                    <Grid container alignItems="center" spacing={1} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked name="facetoface" value="facetoface" onChange={Onchange} />}
                                        label="Face to Face"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        id="outlined-select-experience1"
                                        select
                                        fullWidth
                                        label="Choose Frequency"
                                        size="small"
                                        defaultValue="Within 7 Days"
                                    >
                                        <MenuItem selected value="Within 7 Days">
                                            Within 7 Days
                                        </MenuItem>
                                        <MenuItem value="Within 14 Days">Within 14 Days</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => <TextField size="small" {...props} helperText="" fullWidth />}
                                            label="Schedule"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField id="outlined-select-experience1" select fullWidth label="Assigned To" size="small">
                                        <MenuItem value="Isaac White">Isaac White</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={3} lg={3} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox name="reconcilemedicationlistdone" value="reconcilemedicationlistdone" onChange={Onchange} />
                                }
                                label="Mark as Done"
                            />
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={{ pl: '30px !important' }}>
                    <Grid container alignItems="center" spacing={1} sx={cardStyle}>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox defaultChecked name="medicationrefil" value="medicationrefil" onChange={Onchange} />
                                        }
                                        label="Medication Refill"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container alignItems="center" spacing={gridSpacing}>
                                <Grid item xs={4} lg={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => <TextField size="small" {...props} helperText="" fullWidth />}
                                            label="Schedule"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField id="outlined-select-experience1" select fullWidth label="Assigned To" size="small">
                                        <MenuItem value="Isaac White">Isaac White</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={5} lg={5} md={5} /> */}
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={4} lg={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => <TextField size="small" {...props} helperText="" fullWidth />}
                                            label="Completed Date"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel
                                control={<Checkbox name="prescriptionreqd" value="Prescription Required" onChange={Onchange} />}
                                label="Prescription Required"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel
                                control={
                                    <Checkbox name="assistance1" value="Assistance in arranging follow up visits" onChange={Onchange} />
                                }
                                label="Assistance in arranging follow up visits"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel
                                control={<Checkbox name="assistance2" />}
                                label="Assistance in arranging testing and therapy visits"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel control={<Checkbox name="assistance3" />} label="Assistance in arranging inhome needs" />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <Grid item xs={12} lg={12} md={12}>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked name="assistance4" />}
                                    label="Assistance in arranging Community and Health resources"
                                />
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                                <Grid container sx={{ pl: 5 }}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="assistance4sub1" value="Home Health Agency" onChange={Onchange} />}
                                            label="Home Health Agency"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="assistance4sub2" />} label="Assisted Living" />
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="assistance4sub3" />} label="Hospice" />
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel control={<Checkbox name="assistance4sub4" />} label="Support Group" />
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked name="assistance4sub5" />}
                                            label="Education Program"
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={4} md={4}>
                                        <Grid container alignItems="center" spacing={gridSpacing}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        renderInput={(props) => (
                                                            <TextField size="small" {...props} helperText="" fullWidth />
                                                        )}
                                                        label="Schedule"
                                                        value={date}
                                                        onChange={(newValue) => {
                                                            setDate(newValue);
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} lg={4} md={4}>
                                        <Grid container alignItems="center" spacing={1} sx={{ pl: 3 }}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <TextField
                                                    label="Assigned To"
                                                    id="outlined-select-experience1"
                                                    select
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <MenuItem value="dropdown">Isaac White</MenuItem>
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={3} lg={3} md={3} sx={{ pl: 1 }}>
                                        <FormControlLabel
                                            control={<Checkbox name="reconcilemedicationlistdone" />}
                                            label="Mark as Donesss"
                                        />
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel control={<Checkbox name="assistance5" />} label="Require Durable Medical Equipment (DME)" />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel
                                control={<Checkbox name="assistance6" />}
                                label="Support for treatment regimen including Medication Use, injection etc"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel control={<Checkbox name="assistance7" />} label="Need referrals" />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel control={<Checkbox name="assistance8" />} label="Education planned" />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                            <FormControlLabel
                                control={<Checkbox name="assistance8" />}
                                label="Care and Services needed by the Patient and/or Family"
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 1 }}>
                            <TextField size="small" fullWidth label="Note" multiline />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 3 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} lg={12} md={12}>
                                <Typography variant="h4">
                                    Services provided by the physician or other qualified health care provider
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                                <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                                    <FormControlLabel
                                        control={<Checkbox name="services1" />}
                                        label="Review needed for or follow-up on pending diagnostic tests and treatments"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                                    <FormControlLabel
                                        control={<Checkbox name="service2" />}
                                        label="Interaction with other qualified health care professionals who will assume or reassume care of the patient's system-specific porblems"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                                    <FormControlLabel
                                        control={<Checkbox name="service3" />}
                                        label="Education of patient, family, guardian, and/or caregiver"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                                    <FormControlLabel
                                        control={<Checkbox name="service4" />}
                                        label="Establishment or reestablishment of referrals and arranging of needed community resources"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sx={cardStyle}>
                                    <FormControlLabel
                                        control={<Checkbox name="service5" />}
                                        label="Assistance in scheduling any required follow-up with community providers and services"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ pt: 1 }}>
                                    <TextField size="small" fullWidth label="Note" multiline />
                                </Grid>
                                <Grid item xs={12} sx={{ pt: 1 }}>
                                    <Button size="small" variant="contained">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Createnewtcm;
