import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ManIcon from '@mui/icons-material/Man';
import RobertHailey from '../../../assets/images/patient/robert_hailey_patient.png';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Stack,
    Typography,
    Grid,
    Chip,
    MenuItem,
    TextField,
    styled,
    tableCellClasses,
    FormControl,
    InputLabel,
    Select,
    Checkbox,
    Badge
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets

import { gridSpacing } from 'store/constant';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import Avatar from 'ui-component/extended/Avatar';

// table data
function createData(name, dob, mrn, recentactivitypatient, recentactivityprovider, dreamsStatus, tcmStatus, rpmStatus) {
    return {
        name,
        dob,
        mrn,
        recentactivitypatient,
        recentactivityprovider,
        dreamsStatus,
        tcmStatus,
        rpmStatus
    };
}

const allowCheckBox = (value, tag) => {
    let obj = <></>;
    if (value > 0) {
        obj = tag;
    } else {
        obj = <></>;
    }
    return <>{obj}</>;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const reviewState = [
    {
        value: '1',
        label: 'Pending'
    },
    {
        value: '2',
        label: 'Completed'
    }
];

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [select, setSelect] = useState();
    const navigate = useNavigate();
    const handleRowClick = () => {
        navigate('/proxypatient');
    };

    const handleClose = () => {
        setOpen(!open);
    };
    const handleSelectChange = (event) => {
        setSelect(event.target.value);
    };

    const cardStyle = {
        pt: '15px',
        pl: '15px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.tableColor.tableExpandBg
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const ErrorBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.error.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: -1,
            border: `1px solid ${theme.palette.error.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    return (
        <TableRow hover sx={{ cursor: 'pointer', '& > *': { borderBottom: 'unset' } }} onClick={handleRowClick}>
            <TableCell padding="checkbox">
                {/* <Checkbox
                    color="primary"
                    defaultChecked
                    // indeterminate={selected.length > 0 && selected.length < data.length}
                    // checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectChange}
                    inputProps={{
                        'aria-label': 'select all'
                    }}
                /> */}
            </TableCell>
            <TableCell component="th" scope="row">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={2} md={2} lg={2}>
                        <Avatar alt="Roger Miles" src={RobertHailey} sx={{ width: '25px', height: '25px' }} />
                    </Grid>
                    <Grid item xs={10} md={10} lg={10}>
                        <b>{row.name}</b>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <b>{row.dob}</b>
            </TableCell>
            <TableCell>
                <b>
                    <ManIcon />
                </b>
            </TableCell>
            {/* <TableCell>
                <b>{row.mrn}</b>
            </TableCell> */}
            <TableCell>
                <b>{row.recentactivityprovider}</b>
            </TableCell>
            <TableCell>
                <b>Patient</b>
            </TableCell>
            <TableCell>
                <Chip size="small" label="Off Track" color="error" />
            </TableCell>
            <TableCell>
                <Chip size="small" label="Off Track" color="error" />
            </TableCell>
            <TableCell>
                <Chip size="small" label="Off Track" color="error" />
            </TableCell>
            {/* <TableCell>
                <Chip
                    label="R"
                    size="large"
                    sx={{
                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'success.dark',
                        color: '#fff',
                        borderRadius: '8px',
                        fontWeight: 600
                    }}
                />
            </TableCell> */}
            {/* <TableCell>
                <Button variant="text" size="small" onClick={() => navigate('/patients/patientdetails')}>
                    View Details
                </Button>
            </TableCell> */}
        </TableRow>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [createData('Robert Hailey', '02/02/1990', 'Moved to TCM', 'Patient', 'Off Track', 'Moved to TCM', 'Off Track')];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function PatientDetailsTable() {
    const theme = useTheme();
    const [selected, setSelected] = React.useState([]);

    const handleSelectAllClick = (event) => {
        setSelected(event.target.value);
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
            // fontSize: 16
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                {/* <Table aria-label="collapsible table"> */}
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead sx={{ background: '#eaecf3' }}>
                        <TableRow>
                            <TableCell padding="checkbox">
                                {/* <Checkbox
                                    color="primary"
                                    // indeterminate={selected.length > 0 && selected.length < data.length}
                                    // checked={data.length > 0 && selected.length === data.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all'
                                    }}
                                /> */}
                            </TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>DOB</TableCell>
                            <TableCell />
                            <TableCell>Recent Activity</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>DREAMS</TableCell>
                            <TableCell>TCM</TableCell>
                            <TableCell>RPM</TableCell>
                            {/* <TableCell /> */}
                            {/* <StyledTableCell /> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
