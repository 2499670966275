import { useTheme, Grid, Typography } from '@mui/material';
import Documents from './components/documents/Documents';

const DocumentsTab = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12}>
                    <Documents />
                </Grid>
            </Grid>
        </>
    );
};

export default DocumentsTab;
