import { useTheme, Grid, Typography, Switch, FormControlLabel, Button, CardContent, IconButton, Card } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import TCMReportsTable from './table/TCMReportsTable';

const TCMReportsContents = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };

    const subCardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
        px: '10px !important',
        pt: '15px !important',
        pb: '10px !important',
        mb: '10px !important',
        ml: '20px !important'
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12}>
                    <Card sx={cardStyle}>
                        <CardContent sx={{ p: '10px !important' }}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={11} md={11} lg={11}>
                                    <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                                            <b>TCM Initiated on 05/15/2022</b>
                                        </Typography>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1} align="right">
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        <ExpandCircleDownOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                                {open && (
                                    <Grid container item xs={12} lg={12} md={12} sx={subCardStyle} alignItems="center">
                                        <Grid item xs={12} md={12} lg={12}>
                                            <TCMReportsTable />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Card sx={cardStyle}>
                        <CardContent sx={{ p: '10px !important' }}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={11} md={11} lg={11}>
                                    <IconButton sx={{ pl: 0 }}>
                                        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                                            <b>TCM Initiated on 04/15/2022</b>
                                        </Typography>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1} align="right">
                                    <IconButton aria-label="expand row" size="small">
                                        <ExpandCircleDownOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default TCMReportsContents;
