import {
    Grid,
    styled,
    useTheme,
    FormControlLabel,
    Checkbox,
    Typography,
    CardContent,
    TextField,
    MenuItem,
    Button,
    Link,
    Stack,
    Badge,
    IconButton,
    Box,
    Card,
    tableCellClasses,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableBody
} from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useState } from 'react';
import Chip from 'ui-component/extended/Chip';
import AnimateButton from 'ui-component/extended/AnimateButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { gridSpacing } from 'store/constant';
import { display } from '@mui/system';

const Viewtcm = () => {
    const theme = useTheme();
    const [cdate, setCDate] = useState(null);
    const StyledSubTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    return (
        <>
            <TableContainer>
                <Table
                    size="small"
                    sx={{
                        border: '1px solid',
                        borderColor: theme.palette.common.grey
                    }}
                >
                    <TableBody>
                        <TableRow>
                            <StyledSubTableCell style={{ display: 'flex', flexDirection: 'column' }}>
                                <td>
                                    <p>Contct History</p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Grid item xs={3} lg={3} md={3}>
                                            <TextField id="outlined-select-experience1" select fullWidth label="Assigned To" size="small">
                                                <MenuItem value="Isaac White">Isaac White</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3} lg={3} md={3}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                                    label="Contacted On"
                                                    value={cdate}
                                                    onChange={(newValue) => {
                                                        setCDate(newValue);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={3} lg={3} md={3}>
                                            <TextField
                                                id="outlined-select-experience1"
                                                select
                                                fullWidth
                                                label="Contact Status"
                                                size="small"
                                            >
                                                <MenuItem value="Yes">Responded</MenuItem>
                                                <MenuItem value="No">Not Responded</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="reconcilemedicationlistdone"
                                                    value="Assistance in arranging follow up visits"
                                                />
                                            }
                                            label="Mark as Done"
                                        />
                                    </div>
                                </td>
                            </StyledSubTableCell>
                            <StyledSubTableCell style={{ display: 'flex', flexDirection: 'column' }}>
                                <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>Assistance in arranging follow up visits</p>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="reconcilemedicationlistdone" value="Assistance in arranging follow up visits" />
                                        }
                                        label="Mark as Done"
                                    />
                                </td>
                            </StyledSubTableCell>

                            <StyledSubTableCell style={{ display: 'flex', flexDirection: 'column' }}>
                                <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>Need referralsAssistance in arranging inhome needs</p>
                                    <FormControlLabel
                                        control={<Checkbox name="reconcilemedicationlistdone" value="reconcilemedicationlistdone" />}
                                        label="Mark as Done"
                                    />
                                </td>
                            </StyledSubTableCell>
                            <StyledSubTableCell style={{ display: 'flex', flexDirection: 'column' }}>
                                <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>Assistance in arranging follow up visits</p>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="reconcilemedicationlistdone" value="Assistance in arranging follow up visits" />
                                        }
                                        label="Mark as Done"
                                    />
                                </td>
                            </StyledSubTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Viewtcm;
