// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Chip,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    Button
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';

// project imports
import Avatar from 'ui-component/extended/Avatar';

// assets
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import CallIcon from '@mui/icons-material/Call';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';

const avatarImage = require.context('assets/images/careteam', true);

// ==============================|| USER LIST 1 ||============================== //

const CareTeamTab = ({ members, members1 }) => {
    const [view, setView] = useState('clinical');
    const [alignment, setAlignment] = useState('clinical');

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const ClinicalClick = () => {
        if (view !== 'clinical') {
            setView('clinical');
        }
    };

    const PersonalClick = () => {
        if (view !== 'personal') {
            setView('personal');
        }
    };
    const specialists = [
        {
            id: '#1Card_Kelli',
            avatar: 'dr_roger_miles_primary_physician.png',
            name: 'Dr.Roger Miles',
            role: 'Primary Physician',
            status: 'Full Access',
            provider: 'Sutter Health',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        {
            id: '#2Card_Laurence',
            avatar: 'susan_richards_care_navigator.png',
            name: 'Susan Richards',
            role: 'Care Navigator',
            status: 'Full Access',
            provider: 'Sutter Health',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        {
            id: '#3Card_Melyssa',
            avatar: 'issac_white_nurse.png',
            name: 'Isaac White',
            role: 'Nurse',
            status: 'Full Access',
            provider: 'Sutter Health',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        {
            id: '#4Card_Montana',
            avatar: 'dr_frank_ellison_specialist.png',
            name: 'Dr.Frank Ellison',
            role: 'Specialist -Endo',
            status: 'Limited Access',
            provider: 'Mercy Health',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        }
    ];
    const personalMembers = [
        {
            id: '#6Card_Wilfrid',
            avatar: 'gloria_hailey_proxy_wife.png',
            profile: 'profile-back-6.png',
            name: 'Gloria Hailey',
            role: 'Proxy',
            status: 'Full Access',
            relation: 'Wife',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        // {
        //     id: '#7Card_Kennedy',
        //     avatar: 'casey_hailey_son.png',
        //     profile: 'profile-back-7.png',
        //     name: 'Casey Hailey',
        //     role: 'Family Care Team',
        //     status: 'Invited',
        //     relation: 'Son',
        //     email: 'example@gmail.com',
        //     mobile: '+1 (141) 521 - 2254'
        // },
        {
            id: '#8Card_Ida',
            avatar: 'alexander_hailey_son.png',
            profile: 'profile-back-8.png',
            name: 'Alexander Hailey',
            role: 'Family Care Team',
            status: 'Limited Access',
            relation: 'Son',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        {
            id: '#9Card_Madyson',
            avatar: 'sara_hailey_daughter.png',
            profile: 'profile-back-9.png',
            name: 'Sara Hailey',
            role: 'Family Care Team',
            status: 'Full Access',
            relation: 'Daughter',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        }
    ];
    members = specialists;
    members1 = personalMembers;
    const theme = useTheme();
    const navigate = useNavigate();
    /* const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.grey,
            color: theme.palette.common.dark
        },tableRowBg
        tableHeaderBg
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    })); */
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            backgroundColor: theme.palette.tableColor.tableRowBg,
            color: theme.palette.black.black
        }
    }));
    /*   const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
 */
    const [data, setData] = useState(members);
    const [data1, setData1] = useState(members1);

    const renderRole = (data) => {
        let obj = <></>;
        if (data.provider !== undefined) {
            obj = (
                <>
                    <b>,</b>
                    <br />
                    {data.provider}
                </>
            );
        } else if (data.relation !== undefined) {
            obj = (
                <>
                    <b>,</b>
                    <br />
                    {data.relation}
                </>
            );
        }
        return <>{obj}</>;
    };

    const renderAccessBadge = (status) => {
        let obj = <></>;
        if (status === 'Full Access') {
            obj = (
                <Chip
                    label="Full Access"
                    size="small"
                    sx={{
                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'success.light',
                        color: 'success.dark'
                    }}
                />
            );
        } else if (status === 'Limited Access') {
            obj = (
                <Chip
                    label="Limited Access"
                    size="small"
                    sx={{
                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'warning.light',
                        color: 'warning.dark'
                    }}
                />
            );
        } else if (status === 'Invited') {
            obj = (
                <Chip
                    label="Invited"
                    size="small"
                    sx={{
                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'primary.paper',
                        color: 'primary.dark'
                    }}
                />
            );
        }
        return <>{obj}</>;
    };

    const renderStatusAction = (status) => {
        let obj = <></>;
        if (status === 'Invited') {
            obj = <></>;
        } else {
            obj = (
                <>
                    <IconButton size="small" color="primary">
                        <EmailIcon />
                    </IconButton>
                    &nbsp;
                    {/* <IconButton size="small" color="primary">
                        <CallIcon />
                    </IconButton>
                    &nbsp; */}
                    {/* <IconButton size="small" color="primary">
                        <VideocamIcon />
                    </IconButton> */}
                </>
            );
        }
        return <>{obj}</>;
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={12} md={12}>
                <Grid container alignItems="center">
                    <Grid item xs={5} lg={5} md={5}>
                        {/* <Typography variant="dashboardCardContent">
                            <b>Care Team</b>
                        </Typography> */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="dashboardCardContent">Care Team</Typography>
                            <Button size="small" variant="contained">
                                Add a Care Team Member
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={7} lg={7} md={7} align="right">
                        <ToggleButtonGroup
                            aria-label="text alignment"
                            sx={{
                                outlineColor: theme.palette.primary.main,
                                outlineWidth: '1px',
                                outlineStyle: 'solid',
                                margin: '2px'
                            }}
                            color="primary"
                            size="small"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                        >
                            <ToggleButton value="clinical" aria-label="left aligned" onClick={ClinicalClick}>
                                <FormattedMessage id="Clinical" />
                            </ToggleButton>
                            <ToggleButton value="personal" aria-label="right aligned" onClick={PersonalClick}>
                                <FormattedMessage id="Personal" />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={12} md={12}>
                <MainCard content={false}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        <FormattedMessage id="Name" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormattedMessage id="Role" />
                                    </StyledTableCell>
                                    <StyledTableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {view === 'clinical' &&
                                    data &&
                                    data.map((row, index) => (
                                        <TableRow key={index}>
                                            <StyledTableCell>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Avatar alt="User 1" src={avatarImage(`./${row.avatar}`).default} />
                                                    </Grid>
                                                    <Grid item>
                                                        <b>{row.name}</b>
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <b>{row.role}</b>
                                                {renderRole(row)}
                                            </StyledTableCell>
                                            <StyledTableCell width="4">
                                                <Stack direction="row" justifyContent="left" alignItems="center">
                                                    <Tooltip placement="top" title="Email">
                                                        <IconButton color="primary" aria-label="email" size="large">
                                                            <EmailIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {row.provider === 'Sutter Health' && (
                                                        <>
                                                            <Tooltip placement="top" title="Edit">
                                                                <IconButton color="primary" aria-label="edit" size="large">
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip placement="top" title="Delete">
                                                                <IconButton color="error" aria-label="delete" size="large">
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                    {/* <Tooltip placement="top" title="Call">
                                                        <IconButton size="large" color="primary">
                                                            <CallIcon />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                    {/* <Tooltip placement="top" title="Call">
                                                        <IconButton size="large" color="primary">
                                                            <VideocamIcon />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </Stack>
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                {view === 'personal' &&
                                    data1 &&
                                    data1.map((row, index) => (
                                        <TableRow key={index}>
                                            <StyledTableCell>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Avatar alt="User 1" src={avatarImage(`./${row.avatar}`).default} />
                                                    </Grid>
                                                    <Grid item>
                                                        <b>{row.name}</b>
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <b>{row.role}</b>
                                                {renderRole(row)}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" sx={{ pr: 3 }}>
                                                <Stack direction="row" justifyContent="center" alignItems="center">
                                                    <Tooltip placement="top" title="Email">
                                                        <IconButton color="primary" aria-label="email" size="large">
                                                            <EmailIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {/* <Tooltip placement="top" title="Call">
                                                        <IconButton size="large" color="primary">
                                                            <CallIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Call">
                                                        <IconButton size="large" color="primary">
                                                            <VideocamIcon />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </Stack>
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default CareTeamTab;
