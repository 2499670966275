// material-ui

import { gridSpacing } from 'store/constant';
import React, { useState, useRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material/styles';

import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Stack,
    Typography,
    Grid,
    Chip,
    MenuItem,
    TextField,
    styled,
    tableCellClasses,
    Modal,
    CardContent,
    CardActions,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery
} from '@mui/material';
import DischargeHistory from './contents/DischargeHistory';
import TCMBasicDetails from './contents/TCMBasicDetails';
import MedicationList from './contents/MedicationList';
import InteractiveContact from './contents/InteractiveContact';
import FaceToFace from './contents/FaceToFace';
import MedicationRefill from './contents/MedicationRefill';
import TCMNotes from './contents/TCMNotes';

// project imports

const TCMReportsTable = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:1280px)');
    // const refs = useRef([]);
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={12} lg={12} align="right" sx={{ paddingRight: isSmallScreen ? '10px' : 0 }}>
                <Button variant="contained" size="small">
                    Send Report
                </Button>
            </Grid>
            <Grid item xs={12} md={12}>
                <MainCard
                    /*  sx={{
                        width: 1100,
                        zIndex: 1
                    }} */
                    // title={
                    //     <>
                    //         <center>Consolidated health records received for the appointment </center>
                    //     </>
                    // }
                    content={false}
                    align="left"
                >
                    <TCMBasicDetails />
                    <br />
                    <Grid item sx={{ pl: 3 }}>
                        <Typography variant="h5" color={theme.palette.text.dark}>
                            Discharge History
                        </Typography>
                    </Grid>
                    <DischargeHistory />
                    <br />
                    <Grid item sx={{ pl: 3 }}>
                        <Typography variant="h5" color={theme.palette.text.dark} gutterBottom>
                            Reconcile Medication List
                        </Typography>
                    </Grid>
                    <MedicationList />
                    <br />
                    <Grid item sx={{ pl: 3 }}>
                        <Typography variant="h5" color={theme.palette.text.dark} gutterBottom>
                            Interactive Contact with Patient or Proxy within 2 days of discharge
                        </Typography>
                    </Grid>
                    <InteractiveContact />
                    <br />
                    <Grid item sx={{ pl: 3 }}>
                        <Typography variant="h5" color={theme.palette.text.dark} gutterBottom>
                            Face to Face
                        </Typography>
                    </Grid>
                    <FaceToFace />
                    <br />
                    <Grid item sx={{ pl: 3 }}>
                        <Typography variant="h5" color={theme.palette.text.dark} gutterBottom>
                            Medication Refill
                        </Typography>
                    </Grid>
                    <MedicationRefill />
                    <br />
                    <Grid item sx={{ pl: 3 }}>
                        <Typography variant="h5" color={theme.palette.text.dark} gutterBottom>
                            TCM Notes
                        </Typography>
                    </Grid>
                    <TCMNotes />
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default TCMReportsTable;
