// material-ui

import { gridSpacing } from 'store/constant';
import React, { useState, useRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material/styles';

import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Stack,
    Typography,
    Grid,
    Chip,
    MenuItem,
    TextField,
    styled,
    tableCellClasses,
    Modal,
    CardContent,
    CardActions,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Link
} from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import SubCard from 'ui-component/cards/SubCard';

// project imports

// ==============================|| PROFILE ||============================== //

function createData(diagnosis, age) {
    return { diagnosis, age };
}

const rows = [createData('Abdominal aortic aneurysm', 55)];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        width: '20%'
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

const StyledTableCellGrey = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.black.black
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

const cardStyle = {
    p: '10px !important'
};

const PreviewAndPublishContents = () => {
    const theme = useTheme();
    // const refs = useRef([]);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={12}>
                <MainCard
                    /*  sx={{
                        width: 1100,
                        zIndex: 1
                    }} */
                    // title={
                    //     <>
                    //         <center>Consolidated health records received for the appointment </center>
                    //     </>
                    // }
                    content={false}
                    align="left"
                >
                    {/* INITIAL */}
                    <SubCard contentSX={cardStyle}>
                        <TableContainer sx={{ minWidth: 500, border: '1px solid', borderColor: 'Black' }}>
                            <Table size="small" aria-label="a dense table">
                                <TableRow>
                                    <StyledTableCell align="left" variant="head">
                                        Patient
                                    </StyledTableCell>
                                    <TableCell align="left">Robert Hailey</TableCell>
                                    <StyledTableCell align="left" variant="head">
                                        MRN
                                    </StyledTableCell>
                                    <TableCell align="left">4634821456</TableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="left" variant="head">
                                        Date Of Birth
                                    </StyledTableCell>
                                    <TableCell align="left">April 13, 1963</TableCell>
                                    <StyledTableCell align="left" variant="head">
                                        Sex
                                    </StyledTableCell>
                                    <TableCell align="left">Male</TableCell>
                                </TableRow>
                            </Table>
                            <Table size="small" aria-label="a dense table">
                                <TableRow>
                                    <StyledTableCell align="left" variant="head">
                                        Problems
                                    </StyledTableCell>
                                    <TableCell align="left">High Blood Pressure, Diabetic</TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                    </SubCard>
                    {/* INITIAL  */}
                    <br />
                    <SubCard contentSX={cardStyle}>
                        <Grid item>
                            <MuiTypography variant="h3" color={theme.palette.text.dark} gutterBottom>
                                Diabetic Care Program for Robert Hailey
                            </MuiTypography>
                        </Grid>
                    </SubCard>
                    <SubCard contentSX={cardStyle}>
                        {/* <Grid item>
                            <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                                Action Item 1
                            </MuiTypography>
                        </Grid> */}
                        <TableContainer sx={{ minWidth: 500, border: '1px solid', borderColor: 'Black' }}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left" colSpan={2}>
                                            <Typography variant="h4" color="white">
                                                Maintain Healthy Weight
                                            </Typography>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left">
                                            <Typography sx={{ color: theme.palette.black.black }}>
                                                <b>Weight Goal - </b> 150 lbs
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography sx={{ color: theme.palette.black.black }}>
                                                <b>Achieve by -</b> 07/15/2022
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCellGrey align="left" colSpan={2}>
                                            <b>Activities</b>
                                        </StyledTableCellGrey>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} align="left">
                                            <b>Diet & Nutrition -</b> Meal Planner, Monitor Weight
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCellGrey align="left" colSpan={2}>
                                            <b>Monitored Values</b>
                                        </StyledTableCellGrey>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            <b>Weight - </b>Monthly
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCellGrey align="left" colSpan={2}>
                                            <b>Reference Materials</b>
                                        </StyledTableCellGrey>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" colSpan={2}>
                                            <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                DietNutrtitionGuidelines.pdf&nbsp;&nbsp;
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" colSpan={2}>
                                            <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                https://www.youtube.com/watch?v=MOyTc05g4oU
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </SubCard>
                    <SubCard contentSX={cardStyle}>
                        <TableContainer sx={{ minWidth: 500, border: '1px solid', borderColor: 'Black' }}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={2} align="left">
                                            <Typography variant="h4" color="white">
                                                Take Medication regular as prescribed on time
                                            </Typography>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCellGrey align="left" colSpan={2}>
                                            <b>Activities</b>
                                        </StyledTableCellGrey>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} align="left">
                                            Schedule Medication
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </SubCard>
                    <SubCard contentSX={cardStyle}>
                        <TableContainer sx={{ minWidth: 500, border: '1px solid', borderColor: 'Black' }}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={2} align="left">
                                            <Typography variant="h4" color="white">
                                                De-Addiction and Recovery
                                            </Typography>
                                            <Typography variant="subtitle2" color="white">
                                                Physical Addiction - Smoking
                                            </Typography>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCellGrey align="left" colSpan={2}>
                                            <b>Activities</b>
                                        </StyledTableCellGrey>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} align="left">
                                            Join a Rehab Center
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </SubCard>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default PreviewAndPublishContents;
