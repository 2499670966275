import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Messages from 'views/messages';
import PatientInitialDashboard from 'views/patientonboarding/patientinitialdashboard';
import AccountSettings from 'views/accountsettings';
import ProblemList from 'views/healthrecords/problemlist/ProblemList';
import Allergies from 'views/healthrecords/allergies/Allergies';
import Medication from 'views/healthrecords/medication/Medication';
import Documents from 'views/healthrecords/documents/Documents';
import ExpansionCard from 'views/healthrecords/appointments/appointmentdetails/ExpansionCard';
import Goalhistory from 'views/healthrecords/goalhistory/Goalhistory';
import Nogoals from 'views/dashboard/nogoals/Nogoals';
import CareteamInvite from 'views/dashboard/careteaminvite/CareteamInvite';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const CareTeam = Loadable(lazy(() => import('views/careteam')));
const ConnectProvider = Loadable(lazy(() => import('views/downloadhealthrecords')));
const CareTeamDetails = Loadable(lazy(() => import('views/careteam/viewdetails/CareTeamDetails')));
const Demographics = Loadable(lazy(() => import('views/healthrecords/demographics/Demographics')));
const CarePlans = Loadable(lazy(() => import('views/healthrecords/careplans/CarePlans')));
const FamilyHealthHistory = Loadable(lazy(() => import('views/healthrecords/familyhealthhistory/FamilyHealthHistory')));
const BehaviouralHealthAndSocialProfile = Loadable(
    lazy(() => import('views/healthrecords/bheaviouralhealthandsocialprofile/BehaviouralHealthAndSocialProfile'))
);
const Appointments = Loadable(lazy(() => import('views/healthrecords/appointments/Appointments')));
const HealthRecords = Loadable(lazy(() => import('views/healthrecords')));
const TestResults = Loadable(lazy(() => import('views/healthrecords/testresults/TestResults')));
const Insurance = Loadable(lazy(() => import('views/healthrecords/insurance/Insurance')));
const Vitals = Loadable(lazy(() => import('views/healthrecords/vitals/Vitals')));
const BloodPressure = Loadable(lazy(() => import('views/healthrecords/vitals/bloodpressure/BloodPressure')));
const HowItWorks = Loadable(lazy(() => import('views/howitworks')));

// DREAMS
const Dreams = Loadable(lazy(() => import('views/dreams')));
const MyConnectedDevices = Loadable(lazy(() => import('views/dreams/myconnecteddevices')));
const MealPlan = Loadable(lazy(() => import('views/dreams/mealplan')));
const MyNeedPlanner = Loadable(lazy(() => import('views/dreams/myneedplanner')));
const MyDreamsPlanner = Loadable(lazy(() => import('views/dreams/mydreamsplanner')));
const ExerciseActivities = Loadable(lazy(() => import('views/dreams/mydreamsplanner/exercise/ExerciseActivities')));
const NutritionExerciseActivities = Loadable(lazy(() => import('views/dreams/mydreamsplanner/exercise/NutritionExerciseActivity')));
const Dreamsgoals = Loadable(lazy(() => import('views/dreams/diet/myweightgoals/index')));

const DietActivities = Loadable(lazy(() => import('views/dreams/mydreamsplanner/diet/DietActivities')));
const RestActivities = Loadable(lazy(() => import('views/dreams/mydreamsplanner/rest')));
const AttitudeActivities = Loadable(lazy(() => import('views/dreams/mydreamsplanner/attitude')));
const MedicationActivities = Loadable(lazy(() => import('views/dreams/mydreamsplanner/medication')));
const Mealplanner = Loadable(lazy(() => import('views/dreamsactivityinner/Mealplanner')));
const Restactivity = Loadable(lazy(() => import('views/dreamsactivityinner/Restactivity')));

// Tasks
const Tasks = Loadable(lazy(() => import('views/tasks')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/initialdashboard',

            element: <PatientInitialDashboard />
        },
        {
            path: '/careteaminvite',

            element: <CareteamInvite />
        },
        {
            path: 'dreams/mygoals',

            element: <Dreamsgoals />
        },
        {
            path: '/patientinitialdashboard',
            element: <PatientInitialDashboard />
        },
        {
            path: '/nogoals',
            element: <Nogoals />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/healthrecords',
            element: <HealthRecords />
        },
        {
            path: '/healthrecords/demographics',
            element: <Demographics />
        },
        {
            path: '/healthrecords/careplans',
            element: <CarePlans />
        },
        {
            path: '/healthrecords/testresults',
            element: <TestResults />
        },
        {
            path: '/healthrecords/insurance',
            element: <Insurance />
        },
        {
            path: '/healthrecords/familyhealthhistory',
            element: <FamilyHealthHistory />
        },
        {
            path: '/healthrecords/Goalhistory',
            element: <Goalhistory />
        },
        {
            path: '/healthrecords/problemlist',
            element: <ProblemList />
        },
        {
            path: '/healthrecords/allergies',
            element: <Allergies />
        },
        {
            path: '/healthrecords/medication',
            element: <Medication />
        },
        {
            path: '/healthrecords/documents',
            element: <Documents />
        },
        {
            path: '/healthrecords/behaviouralandsocial',
            element: <BehaviouralHealthAndSocialProfile />
        },
        {
            path: '/healthrecords/appointments',
            element: <Appointments />
        },
        {
            path: '/healthrecords/vitals',
            element: <Vitals />
        },
        {
            path: '/healthrecords/vitals/bloodpressure',
            element: <BloodPressure />
        },
        {
            path: '/dreams',
            element: <Dreams />
        },
        {
            path: '/tasks',
            element: <Tasks />
        },
        {
            path: '/dreams/myconnecteddevices',
            element: <MyConnectedDevices />
        },
        {
            path: '/dreams/mealplan',
            element: <MealPlan />
        },
        {
            path: '/dreams/mydreamsplanner',
            element: <MyDreamsPlanner />
        },
        {
            path: '/dreams/mydreamsplanner/exercise',
            element: <ExerciseActivities />
        },
        {
            path: '/dreams/Dreamsoverview/exercise',
            element: <ExerciseActivities />
        },
        {
            path: '/dreams/mydreamsplanner/exercises',
            element: <NutritionExerciseActivities />
        },
        {
            path: '/dreams/mydreamsplanner/diet',
            element: <DietActivities />
        },
        {
            path: '/dreams/mydreamsplanner/rest',
            element: <RestActivities />
        },
        {
            path: '/dreams/Dreamsoverview/rest',
            element: <RestActivities />
        },
        {
            path: '/dreams/mydreamsplanner/attitude',
            element: <AttitudeActivities />
        },
        {
            path: '/dreams/Dreamsoverview/attitude',
            element: <AttitudeActivities />
        },
        {
            path: '/dreams/mydreamsplanner/medication',
            element: <MedicationActivities />
        },
        {
            path: '/dreams/Dreamsoverview/medication',
            element: <MedicationActivities />
        },
        {
            path: '/Dreamsoverview/diet',
            element: <Mealplanner />
        },
        {
            path: '/MydreamsPlanner/diet',
            element: <Mealplanner />
        },
        {
            path: '/dreamsactivityinner/Restactivity',
            element: <Restactivity />
        },
        {
            path: '/dreams/mydreamsplanner',
            element: <MyDreamsPlanner />
        },
        {
            path: '/dreams/myneedplanner',
            element: <MyNeedPlanner />
        },
        {
            path: '/messages',
            element: <Messages />
        },
        {
            path: '/careteam',
            element: <CareTeam />
        },
        {
            path: '/careteam/viewdetails',
            element: <CareTeamDetails />
        },
        {
            path: '/connectprovider',
            element: <ConnectProvider />
        },

        {
            path: '/accountSettings',
            element: <AccountSettings />
        },

        {
            path: '/howitworks',
            element: <HowItWorks />
        },
        {
            path: '/healthrecords/appointments/appointmentdetails',
            element: <ExpansionCard />
        },
        {
            path: '/dreams/mydreamsplanner/exercise/exerciseactivities',
            element: <ExerciseActivities />
        }
    ]
};

export default MainRoutes;
