import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { gridSpacing } from 'store/constant';

const ActivitySearch = ({ title }) => {
    const theme = useTheme();
    const [dob, setDOB] = useState(null);
    const [dob1, setDOB1] = useState(null);
    const [mode, setMode] = useState('1');
    const [showdetails, setShowDetails] = useState(false);

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <SubCard sx={cardStyle}>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        {/* <Typography variant="h4">Search Activities</Typography> */}
                        <Typography variant="h5">
                            <b>{title}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={gridSpacing}>
                            <Grid item xs={5}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="From Date"
                                        value={dob}
                                        onChange={(newValue) => {
                                            setDOB(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={5}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="To Date"
                                        value={dob1}
                                        onChange={(newValue) => {
                                            setDOB1(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" size="small" fullWidth>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default ActivitySearch;
