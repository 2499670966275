import * as React from 'react';
import Box from '@mui/material/Box';
import Nogoalsimg from '../../../assets/images/dashboard/nogoals.png';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

export default function Nogoals() {
    const theme = useTheme();
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            border: '1px solid #d1d1d1',
                            borderRadius: '8px',
                            background: 'linear-gradient(to right top, #f0f2f5, #f4f5f8, #f8f8fa, #fcfcfc, #ffffff)',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            [theme.breakpoints.down('sm')]: {
                                flexDirection: 'column'
                            }
                        }}
                    >
                        <Grid item xs={12} lg={4} md={4} sm={12}>
                            <img alt="nogoals" src={Nogoalsimg} style={{ width: '100%' }} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            md={6}
                            sm={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                pl: 5,
                                [theme.breakpoints.down('sm')]: {
                                    pl: 0,
                                    textAlign: 'center'
                                }
                            }}
                        >
                            <Typography variant="h2" sx={{ color: theme.palette.goaldreamsbkg.headingwrapper }}>
                                Welcome Robert Haiely
                            </Typography>
                            <Typography
                                variant="p"
                                sx={{
                                    pb: 2,
                                    pt: 1,
                                    fontWeight: 'normal',
                                    fontSize: '16px',
                                    color: theme.palette.goaldreamsbkg.headingwrapper
                                }}
                            >
                                Dont forgot to download your health records
                            </Typography>
                            <Typography
                                variant="p"
                                sx={{ fontWeight: 'normal', fontSize: '16px', color: theme.palette.goaldreamsbkg.headingwrapper }}
                            >
                                Have a nice day !
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    mt: 3,
                                    borderRadius: '8px',
                                    p: 1,
                                    [theme.breakpoints.down('sm')]: {
                                        margin: '20px auto 10px '
                                    }
                                }}
                            >
                                Download Health Records
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
