import { useTheme, Grid } from '@mui/material';
import BehaviouralHealthAndSocialProfile from './components/bheaviouralhealthandsocialprofile/BehaviouralHealthAndSocialProfile';

const BehavioralTab = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12}>
                    <BehaviouralHealthAndSocialProfile />
                </Grid>
            </Grid>
        </>
    );
};

export default BehavioralTab;
